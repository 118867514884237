<template>
	<div id="cart-page" class="d-flex account-page">
		
		<div class="container main-box">

			<div class="col-12 px-2">
				<h1 class="mb-3 page-title">Mi carrito</h1>
			</div>

			<b-row>

				<b-col md="3" class="col-menu">
					<div class="white-box">
						<div class="box-menu py-2">
							<p class="item">
								<router-link class="btn-menu" to="/cart">
									<i class="fas fa-shopping-cart"></i> Mi
									carrito
								</router-link>
							</p>
							<p class="item">
								<router-link class="btn-menu" to="/mis-favoritos" >
									<i class="fas fa-heart"></i> 
									Mis favoritos
								</router-link>
							</p>
						</div>
					</div>
				</b-col>

				<b-col md="9">
					<!-- Paso 1 (Lista de productos agregados a carrito) -->
					<b-form class="white-box" @submit.prevent="">

						<div class="col-12 pt-3 product-list-table" v-if=" $route.params.step !== 'envio' && $route.params.step !== 'pago'">

							<div class="col-12 px-0">

								<h5>Productos agregados al carrito</h5>

								<h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!cartGet.length">
									<i class="fas fa-shopping-basket"></i>
									Ningún producto ha sido agregado al carrito
								</h6>

								<inventory-item v-for="(product, $index) in cartGet" 
									:key="product.productos_id"
									:product-id="product.productos_id" 
									:cart-data="product" 
									:cart-index="$index" />

								<lock-button @click="showMoal" v-if="cartGet.length>0">
									Continuar
								</lock-button>

							</div>

						</div>


					</b-form>
				</b-col>
				
			</b-row>

		</div>

		<ui-modal v-model="finishingModal" :footer="displayCartResume">
			<div slot="header">
				<span v-if="displayCartResume">
					Resumen de compra
				</span>
				<span v-else>
					Detalles del pedido
				</span>
			</div>
			<cart-result v-if="displayCartResume"/>
			<cart-summary v-else @purchaseComplete="cartCompleted" />
		</ui-modal>
	</div>
</template>

<script>

	import { mapGetters } from 'vuex';
	import inventoryItem from '@@/inventory-item.vue';
	import LockButton from '@@/shared/LockButton.vue';
	import uiModal from '@@/ui-modal.vue';
	import cartSummary from '@/js/components/page/usuario-cuenta/asides/CartSummary.vue'
	import CartResult from '@/js/components/page/usuario-cuenta/asides/CartResult.vue'


	export default {
		
		components: { inventoryItem, LockButton, uiModal, cartSummary, CartResult },

		data() {
			return {
				finishingModal: false,
				displayCartResume: false,
				form: {
					products: []
				}
			}
		},

		computed: {
			...mapGetters([ 'cartGet',  'cartSummary',  'cartGetPaymentMethod',  'catalogOrderCfg' ])
		},

		methods: {
			showMoal(){
				this.finishingModal = true
			},
			cartCompleted(params){
				console.log("cartCompleted.params", params)
				this.displayCartResume = true
			}
		}

	};
</script>

<style lang="less">
#cart-page {

	.col-cart-data {
		max-width: 610px;
	}

}
</style>
