// IMPORTS
import _ from 'lodash'
import axios from 'axios'

const state = {
	sellers: [],
	cats: [],
	products: [],
	inventory: [],
	main_cats: [],
	plain_cats: [],
    contactCfg: {
        avatar: '',
        name: '',
        phone: null,
        whats: null
    },
	orderCfg: {
        daysLimit         : null,
        deliveryCost      : null,
        fixedPrice        : null, // Ganancia del repartidor, se procesa en cart.js
        commisionPercent  : null, // Porcentaje de REFAAPP®, se procesa en cart.js
    },
}

const getters = {
    catalogCats: (state) => {return state.cats},
    catalogOrderCfg: (state) => {return state.orderCfg},
    catalogContactCfg: (state) => {return state.contactCfg},
    catalogCats4Home: (state) => {return _.filter(state.cats, {in_home: '1'})},
    catalogParentCats: (state) => {return state.main_cats},
    catalogPlainCats: (state) => {return state.plain_cats},
    catalogSellers: (state) => {return state.sellers},
    catalogProducts: (state) => {return _.map(state.products, prod => {
        prod.id = prod.productos_id
        return prod;
        console.log('prod', prod)
    })},
    catalogGetBrands: (state) => {
        let brandItem = {
            id: null,
            name: null,
            cantidadEnMarca: 1
        }
        let brands = [];
        _.forEach(state.products, prod => {
            let indexOfProd = _.findIndex(brands, {id: prod.marca})
            if(indexOfProd != -1){
                brands[indexOfProd].cantidadEnMarca ++;
            } else {
                let prodItem = _.clone(brandItem)
                prodItem.id = prod.marca
                prodItem.name = prod.marca.toUpperCase()
                if(!!prodItem.name.length) brands.push(prodItem)
            }
        })
        return _.sortBy(brands, ['name'])
    }
}

const mutations = {
	catalogWrite: ( state, payload ) => {
        state.sellers       = payload.sellers
        state.products      = payload.products
        state.cats          = payload.cats
        state.main_cats     = payload.main_cats
        state.plain_cats    = payload.plain_cats
        state.orderCfg      = payload.orderCfg
        state.contactCfg    = payload.contactCfg
    }
}

const actions = {
    
    catalogGetProductByID: ({getters}, payload) => {
        return new Promise((success) => {
            success(_.find(getters.catalogProducts, {productos_id: payload}))
        })
    },

    catalogGetCatByID: ({getters}, payload) => {
        return new Promise((success) => {
            let catClassic = _.find(getters.catalogCats, {categorias_id: payload}),
                catPlain = _.find(getters.catalogPlainCats, {id: payload}),
                catFull = _.merge(catClassic, catPlain)
            if(!catFull.products){
                catFull.products = []
            }
            success(catFull)
        })
    },
    
    catalogGetSellerByID: ({getters}, payload) => {
        return new Promise((success) => {
            success(_.find(getters.catalogSellers, {vendedores_id: payload}))
        })
    },

	catalogUpdate: async ({commit}) => {
        try {
            let response = await axios.get('catalogo')
            commit('catalogWrite', response.data)
        } catch(e){
            console.error(e)
        }
    },

    cataIntemsGetBySellerID: ({getters}, payload) => {
        return new Promise((success) => {
            success(_.filter(getters.catalogProducts, {vendedores_id: payload}))
        })
    }

}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
