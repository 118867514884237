/**
 * Concept Archetype
 * 
 * @Version 1.0
 * @Author Daniel Lepe
 */
 
import _ from 'lodash'
import {notify, toastMSG} from '@@/utils.js'
import axios from 'axios';

const baseController = 'business'

const baseIndex = baseController + '/index'

const archetype = [
    {
        key: 'id',
        type: 'hidden'
    },
    {
        key: 'imagenes_id_logo',
        label: 'Logo',
        type: 'imagen'
    },
    {
        label: '* Nombre del negocio',
        key: 'nombre',
        type: 'text'
    },
    {
        label: '* Email',
        key: 'email',
        type: 'text'
    },
    {
        label: '* Contraseña',
        key: 'password',
        type: 'password'
    },
    {
        label: '* Confirmación',
        key: 'password_confirmation',
        type: 'password'
    },
    {
        label: '* Teléfono / Whatsapp®',
        key: 'telefono_contacto',
        type: 'text'
    },
    {
        label: 'Página web',
        key: 'web',
        type: 'text'
    },
    {
        label: 'Facebook®',
        key: 'facebook',
        type: 'text'
    },
    {
        label: 'Twitter®',
        key: 'twitter',
        type: 'text'
    },
    {
        label: '* Descripción',
        key: 'descripcion',
        type: 'textarea'
    },
    {
        label: '* Domicilio',
        key: 'domicilio',
        type: 'textarea'
    },
];

const captions = [
    // {key: '_'},
]

let formFromArchetype = () => {
    let from = {}
    _.forEach(archetype, field => {
        from[field.key] = null
    })
    from.tos = false;
    return from
}

let automodalHandler = (conceptId) => {
    let str = baseController + '/' + 'define' + ((_.isNil(conceptId)) ? '' : ('/' + conceptId) );
    // console.log("isNil", _.isNil(conceptId))
    return str;
}

let signupAsBusiness = async (dataForm) => {
    try {
        let response = await axios.post(automodalHandler(dataForm.id), dataForm)
        if(!_.isObjectLike){
            throw 'Error en la respuesta'
        }
        toastMSG(response.msg, response.class)
        return response.status
    } catch(e){
        notify(e)
    }
}

export {
    archetype,
    captions,
    baseIndex,
    automodalHandler,
    signupAsBusiness,
    formFromArchetype
}