import mobiscroll from '@mobiscroll/javascript'
import swal from 'sweetalert'

const confirmFirst = async ({text, title, goLabel, cancelLabel}) => {
    if(!goLabel){
        goLabel = 'Continuar';
    }
    if(!cancelLabel){
        cancelLabel = 'Cancelar';
    }
    let response = await swal({
        icon: 'info',
        title,
        text,
        buttons: {
            cancel: cancelLabel,
            danger: goLabel
        }
    })
    return response
}

const notify = (e) => {
    console.error(e)
    mobiscroll.snackbar({
        display: 'top',
        theme: 'ios',
        message: e,
        color: 'warning',
        duration: 3000
    })
}

const toastMSG = (msg, classTXT) => {
    if (!!msg) {
        mobiscroll.snackbar({
            display: 'top',
            theme: 'ios',
            message: msg,
            color: (classTXT) ? classTXT : 'warning',
            duration: 3000
        })
    }
}

export {
    notify, toastMSG, confirmFirst
}