<template>
	<div id="arecovery">

		<div v-if="$route.name == 'ARecoveryRequire'" class="limit-width animated fadeInUp">
			<div class="padded">
				<p>
					Para recuperar tu contraseña especifica el correo electrónico por favor.
				</p>
			</div>
			<ui-input type="email" v-model="email" label="Correo:" placeholder="mail@server.com" @keyup.enter="triggerPinGeneration()"></ui-input>
			<lock-button label="Solicitar recuperación" :is-processing="isProcessing" :callback="triggerPinGeneration" />
		</div>

		<div v-if="$route.name == 'ARecoveryVerify'" class="limit-width animated fadeInUp">
			<div class="padded">
				<p>
					Revisa tu correo electrónico,
					<br> debes recibir un correo con 6 dígitos de REFAAPP®
				</p>
			</div>
			<ui-input label="PIN" v-model="pin" maxlength="6" minlength="6" @keyup.enter="triggerPinValidation()"></ui-input>
			<lock-button label="Solicitar recuperación" :is-processing="isProcessing" :callback="triggerPinValidation" />
		</div>

		<div v-if="$route.name == 'ARecoveryUpdate'" class="limit-width animated fadeInUp">
			<div class="padded">
				<api-img-pristine class="avatar" :comp="user.avatar_imagenes_id" />
				<h3>¡Hola {{user.nombres}}!</h3>
				<p>Escribe y confirma tu nueva :</p>
			</div>

			<ui-input label="Nueva contraseña" type="password" v-model="newPassword" @keyup.enter="triggerPassUpdate()" minlength="6" />
			<ui-input label="Confirmación" type="password" v-if="newPassword != '' && newPassword.length >= 6" v-model="newPasswordConfirmation" @keyup.enter="triggerPassUpdate()" minlength="6" />
			<lock-button label="Solicitar recuperación" :is-processing="isProcessing" :callback="triggerPassUpdate" />
		</div>

	</div>
</template>

<script>
	import mobiscroll from "@mobiscroll/javascript";
	import { mapActions, mapGetters } from "vuex";
	import axios from "axios";
	import uiInput from '@@/ui-input.vue'
	import lockButton from '@@/shared/LockButton.vue'
	import apiImgPristine from '@@/shared/api-img-pristine.vue'

	export default {
		name: "aRecovery",
		components: {uiInput, lockButton, apiImgPristine},
		methods: {
			...mapActions(["tryLogin"]),
			triggerPassUpdate() {
				if(this.newPassword.length < 6) {
					mobiscroll.snackbar({
						display: "top",
						theme: "ios",
						message: "Tu nueva contraseña debe ser al menos de 6 caracteres",
						color: "warning",
						duration: 7000,
					});
					return false;
				}
				else if (this.newPassword != this.newPasswordConfirmation) {
					mobiscroll.snackbar({
						display: "top",
						theme: "ios",
						message: "La contraseña y la confirmación no coinciden",
						color: "warning",
						duration: 7000,
					});
					return false;
				}
				let data2send = {
					recuperacionID: this.recuperacionID,
					pin: this.pin,
					newPassword: this.newPassword,
				};
				this.isProcessing = true;
				axios.post("cuenta/cambio", data2send).then((response) => {
					// ANALISA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
					if (response.msg) {
						mobiscroll.snackbar({
							display: "top",
							theme: "ios",
							message: response.msg,
							color: response.class,
							duration: 7000,
						});
					}
					if (response.status) {
						this.tryLogin({
							workingRole: null,
							email: this.email,
							password: this.newPassword,
						})
							.then(() => {
								this.$router.replace({
									name: 'MyData'
								});
							})
							.catch(() => {
								this.isProcessing = false;
							});
					} else {
						this.isProcessing = false;
					}
				});
			},
			triggerPinValidation() {
				this.isProcessing = true;
				let data2send = {
					recuperacionID: this.recuperacionID,
					pin: this.pin,
				};
				axios.post("cuenta/cambio", data2send).then((response) => {
					this.isProcessing = false;
					// ANALISA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
					if (response.msg) {
						mobiscroll.snackbar({
							display: "top",
							theme: "ios",
							message: response.msg,
							color: response.class,
							duration: 7000,
						});
					}
					if (response.status) {
						this.user = response.data;
						this.$router.push("actualiza");
					}
				});
			},
			validateView() {
				if ( this.$route.name != "ARecoveryRequire" && this.recuperacionID == null ) {
					this.$router.replace("solicita");
				}
			},
			async triggerPinGeneration() {
				this.isProcessing = true;
				try {
					let response = await axios.post("cuenta/recuperacion", {
						email: this.email,
					});

					// ANALISA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
					if (response.msg) {
						mobiscroll.snackbar({
							display: "top",
							theme: "ios",
							message: response.msg,
							color: response.class,
							duration: 7000,
						});
					}
					if (response.status) {
						this.recuperacionID = response.data.pinID;
						this.$router.push("consulta");
					}
				} catch (e) {
					console.error(e);
					mobiscroll.snackbar({
						display: "top",
						theme: "ios",
						message: "Ocurrió un error de conexión: " + e,
						color: "warning",
						duration: 7000,
					});
				}
				this.isProcessing = false;
			},
		},
		computed: {
			...mapGetters(["userRoles"]),
		},
		data() {
			return {
				isProcessing: false,
				email: "",
				pin: "",
				recuperacionID: null,
				user: {},
				newPassword: "",
				newPasswordConfirmation: null,
			};
		},
		updated() {
			this.validateView();
		},
		watch: {
			$route() {
				this.validateView();
			},
		}
	};
</script>

<style lang="less">
	#arecovery {
		@size: 7.5em;
		overflow-x: hidden;
		h3,
		p {
			text-align: center;
		}
		.avatar {
			width: @size;
			height: @size;
			object-fit: cover;
			border-radius: 50%;
			border: var(--ui-border);
			margin: 2em auto ;
			display: block;
		}
	}
</style>