// IMPORTS
import _ from 'lodash'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript'

// DEFINITIONS
const API = "faqs/readfaqs"

const state = {
    faqsIndex: [],
    isPreloaded: false
}

const getters = {
    faqsGetIndex: state => { return state.faqsIndex },
    isPreloaded: state => { return state.isPreloaded }
}

const mutations = {
	faqsSetIndex: (state, payload) => {state.faqsIndex = []; state.faqsIndex = payload, state.isPreloaded = true }
}

const actions = {
	faqsUpdate: ({commit}) => {
        axios.get( API ).then((response) => {
            // ANALISA SI HAY MENSAJES PARA EL USUARIO
            if (response.msg) {
                mobiscroll.snackbar({
                    display: 'top',
                    theme: 'ios',
                    message: response.msg,
                    color: response.class,
                    duration: 7000
                })
            }
            if(response.status){
                commit('faqsSetIndex', response.data)
            }
        }).catch((r) => {
            console.error(r)
            mobiscroll.snackbar({
                display: 'top',
                theme: 'ios',
                message: 'Hay un problema de comunicación con el servidor, por favor verifica tu conexión',
                color: 'danger',
                duration: 7000
            })
        })
    }
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};