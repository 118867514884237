<template>
	<div id="cart-summary-page">

		<div class="content" v-if="selectedStep == Address">

			<div class="cart-summary-block">
				<h5>
					<img src="@/assets/ic/otros/ubicacion.svg" alt />
					Hora de entrega
				</h5>

				<ui-select v-model="agenda.tipo_orden" label=" " :options="tipoOrdenOptions" />

				<template v-if="agenda.tipo_orden == 'Agendar'">

					<ui-input label="Selecciona la fecha" v-model="agenda.agenda_fecha" type="date" />
					<ui-select label="Horario de entrega" v-model="agenda.tipo_horario"
						:options="agenda.tipo_horario_options" />

					<div class="row" v-if="agenda.tipo_horario == 'Horario fijo'">

						<div class="col">
							<ui-input label="Desde" placeholder="Inicio" :max="maxTimeFromLimit"
								v-model="agenda.agenda_hora_inicio" type="time" />
						</div>

						<div class="col">
							<ui-input v-if="agenda.agenda_hora_inicio" label="Hasta" placeholder="Limite"
								:min="minTimeFromBeginning" v-model="agenda.agenda_hora_fin" type="time" />
						</div>

					</div>
				</template>
			</div>

			<div class="cart-summary-block address">
				<h5>
					<img src="@/assets/ic/otros/ubicacion.svg" alt />
					Dirección de entrega
				</h5>
				<client-add-location v-model="domicilioentrega" ref="clientAddLocation" mode="addressText"
					@locationRequested="refreshLatLng" />
			</div>

		</div>

		<div class="content" v-if="selectedStep == SummaryAndTips">

			<div class="cart-summary-block products">
				<inventory-item v-for="(product, $index) in cartGet" :key="product.productos_id"
					:product-id="product.productos_id" :cart-data="product" :cart-index="$index" :blocked="true" />
			</div>

			<div class="cart-summary-block tips">
				<h5>
					<img src="@/assets/Affinity/exported/tips.svg" alt />
					Propina
				</h5>

				<ui-input v-model="propina_repa" label=" " type="numeric"/>
			</div>

		</div>

		<div class="content" v-if="selectedStep == StripeFinish">

			<stripe-payment v-if="isLogged" :amount="totalConPropina" ref="stripePayment" :url-to-fetch-client-secret-key="intentCreationURL" />

			<!-- <lock-button v-if="isLogged" :is-processing="cartIsBusy || isProcessing" label="Finalizar compra"
				@click="trigger" />
			<lock-button v-if="!isLogged" label="Inicia sesión para continuar" @click="login" /> -->
		</div>

		<div class="summary">
			<dl>
				<dt>
					<span>Subtotal:</span>
				</dt>
				<dd>{{ (cartSummary.subtotal) | currency }}</dd>
				<template v-if="isShippingCalculated">
					<dt>
						<span>Envío:</span>
					</dt>

					<dd @click="showAhorro">
						<i class="fa fa-info" v-if="apiComputedTotals.ahorroEnvioGratis"></i>
						{{ apiComputedTotals.ammountShipping | currency }}
					</dd>

					<template v-if="parseInt(propina_repa)">
						<dt class="propina">
							Propina
						</dt>
						<dd class="total" @click="toggleUI()">{{ propina_repa | currency }}</dd>
					</template>

					<dt class="total">
						<span>Total:</span>
					</dt>

					<dd class="total" @click="toggleUI()">{{ (totalConPropina) | currency }}</dd>
				</template>
			</dl>
		</div>

		<div class="row style-buttons">

			<lock-button class="col-tight" v-if="modalSteps.indexOf(selectedStep) && !isProcessing"
				@click="selectedStep = modalSteps[modalSteps.indexOf(selectedStep) - 1]">
				Anterior
			</lock-button>

			<lock-button class="col-tight" @click="triggerNextStep" :disabled="!isShippingCalculated" :is-processing="isProcessing">
				<span v-if="isLastStep">Finalizar</span>
				<span v-else>Siguiente</span>
			</lock-button>

		</div>

	</div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript';
import moment from "moment";
import inventoryItem from '@@/inventory-item.vue';
import ClientAddLocation from '@@/ClientAddLocation.vue';
import router from "@/js/router.js";
import swal from "sweetalert";
import uiInput from '@@/ui-input'
import uiSelect from '@@/ui-select'
import LockButton from '@@/shared/LockButton.vue';

const SummaryAndTips = 'SummaryAndTips',
	Address = 'Address',
	StripeFinish = 'StripeFinish',
	modalSteps = [Address, SummaryAndTips, StripeFinish];

import {
	mapGetters,
	mapActions
} from "vuex";

import PaymentMethodsIndex from './PaymentMethodsIndex.vue';
import StripePayment from '../../shared/stripe-payment.vue';

// Una vista en este formato reuqiere que se manejen de manera independiente
// el formulario, la página y la lista.
export default {
	name: "cart-summary-page",
	components: {
		inventoryItem,
		ClientAddLocation,
		uiInput,
		uiSelect,
		LockButton,
		PaymentMethodsIndex,
		StripePayment
	},
	computed: {
		...mapGetters([
			"cartGet",
			"cartSummary",
			"mapsGetGeocoded",
			"cartGetPaymentMethod",
			"isLogged",
			"cartIsBusy",
		]),
		comissionsCalcURL(){
			return 'comisiones/calcporcarrito/' + this.queriableIds + "/" + this.workingGPS.lat + "/" + this.workingGPS.lng 
		},
		intentCreationURL(){
			return this.comissionsCalcURL + '/createpaymentintent/' + this.propina_repa
		},
		isLastStep(){
			return this.modalSteps.length == (this.modalSteps.indexOf(this.selectedStep) + 1)
		},
		isShippingCalculated() {
			return (!!this.apiComputedTotals.total && !this.isProcessing)
		},
		totalConPropina() {
			return parseFloat(this.apiComputedTotals.total) + parseFloat(this.propina_repa)
		},
		minTimeFromBeginning() {
			return moment().toDate();
		},
		queriableIds() {
			let ids = []
			_.forEach(this.cartGet, cartItem => {
				ids.push(('' + cartItem.productos_id + ':' + cartItem.cantidad))
			})
			return (ids).toString(',')
		},
		maxTimeFromLimit() {
			return moment().toDate();
		},
		minDateToSchedule() {
			return moment().toDate();
		},
		minTime() {
		},
		maxTime() {
		}
	},
	methods: {
		...mapActions([
			"geocodePosition",
			"cartProcess",
			"mapsUpdatePositionByGPS",
		]),
		showAhorro() {
			if (this.apiComputedTotals.ahorroEnvioGratis) {
				let currency = this.$options.filters.currency(this.apiComputedTotals.ahorroEnvioGratis)
				swal({
					icon: 'info',
					text: `Estás ahorrando ${currency} con los envíos gratis.`
				})
			}
		},
		refreshLatLng(latLng) {
			console.log("latLng", latLng)
			this.workingGPS.lat = latLng.lat
			this.workingGPS.lng = latLng.lng
			this.updateComissions()
		},
		goTo(URI) {
			router.push({
				name: URI
			});
		},
		toggleUI() {
			this.fullUI = !this.fullUI;
		},
		login() {
			this.$router.push({
				name: "SignIn"
			});
		},
		async triggerNextStep($event){
			if(this.isLastStep){
				this.isProcessing = true;
				const paymentId = await this.$refs.stripePayment.processPayment($event)
				if(paymentId)
					await this.trigger(paymentId)
				this.isProcessing = false;
			} else {
				this.selectedStep = this.modalSteps[this.modalSteps.indexOf(this.selectedStep) + 1];
			}
		},
		async trigger(paymentId) {
			try {
				this._quickRevision()
				let response = await this.cartProcess({
					domicilioentrega: this.domicilioentrega,
					agenda: this.agenda,
					workingGPS: this.workingGPS,
					propina_repa: this.propina_repa,
					paymentId
				})
				if (response.status) {
					this.$emit('purchaseComplete', response.data)
				}
			} catch (e) {
				swal(e)
			}
		},
		_quickRevision() {
			if (!this.domicilioentrega || !this.workingGPS.lat || !this.workingGPS.lng) {
				throw ("Debes especificar un domicilio para la entrega");
			}
		},
		cleanAddress() {
			this.domicilioentrega = ''
			this.$refs.domicilioentrega.focus()
		},
		async updateComissions() {
			this.isProcessing = true
			try {
				let response = await axios.get( this.comissionsCalcURL)

				console.log("response", response)

				// ANALIZA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
				if (response.msg) {
					mobiscroll.snackbar({
						display: 'top',
						theme: 'ios',
						message: response.msg,
						color: response.class,
						duration: 7000
					})
				}
				if (response.status) {
					this.apiComputedTotals = response.data
				} else {
					this.apiComputedTotals.ammountSummary = 0
					this.apiComputedTotals.comissionSummary = 0
					this.apiComputedTotals.total = 0
				}
			} catch (e) {
				console.error(e)
			}
			this.isProcessing = false
		}
	},
	data() {
		return {
			isProcessing: false,
			cart: [],
			SummaryAndTips,
			Address,
			StripeFinish,
			modalSteps,
			selectedStep: _.clone(modalSteps[0]),
			workingGPS: {
				lat: null,
				lng: null
			},
			configs: {
				maxFechaAgenda: moment().add(2, "months").toDate(),
				minWorkingHour: 9, // min hour to work with
				maxWorkingHour: 23, // last hour to work with
				workingWindow: 3 // hours working window
			},
			fullUI: false,
			domicilioentrega: null,
			propina_repa: 0.00,
			agenda: {
				tipo_orden: "Urgente",
				tipo_horario: "Cualquier hora", // ['Cualquier hora'|'Horario fijo']
				tipo_horario_options: [
					{
						value: 'Cualquier hora',
						label: 'Cualquier hora'
					},
					{
						value: 'Horario fijo',
						label: 'Horario fijo'
					}
				],
				agenda_fecha: null,
				agenda_hora_inicio: null,
				agenda_hora_fin: null,
			},
			tipoOrdenOptions: [{
				value: "Urgente",
				label: "Inmediata"
			},
			{
				value: "Agendar",
				label: "Agendada"
			},
			],
			apiComputedTotals: {
				ammountSummary: 0,
				comissionSummary: 0,
				total: 0
			}
		};
	},
	beforeMount() {
		this.mapsUpdatePositionByGPS().then(() => {
			this.geocodePosition();
		});
	}
};
</script>

<style lang="less">
#cart-summary-page {
	margin-bottom: -1.8em;

	.address-holder {
		position: relative;
	}

	.address-holder DIV.ui-input INPUT {
		padding-right: 2.5em;
	}

	.address-clearer {
		position: absolute;
		top: 1.25em;
		right: 1.25em;
		padding: 0.5em;
	}

	.address-clearer IMG {
		width: 1.5em;
	}

	.pin-map {
		height: 2em;
		position: relative;
		top: 1.5em;
		right: 0.5em;
	}

	/* En línea #27 | http://localhost:8088/#cart-summary */

	.cart-summary-block>h5 {
		display: flex;
		flex-flow: row;
		width: 100%;
		align-items: center;
		border-bottom: 1px solid lightgray;
	}

	.cart-summary-block.payment>div:not(.not-flex) {
		display: flex;
		align-items: center;
	}

	.cart-summary-block.payment>div img {
		width: 3.5em;
		margin-right: 1em;
	}

	.cart-summary-block>h5 {
		margin-bottom: 0.5em;
		margin-top: 0.5em;
	}

	.cart-summary-block.address {
		display: row;
	}

	.cart-summary-block.address>* {
		flex: 1 0 auto;
	}

	.cart-summary-block.products>div {
		margin-top: -0.5em;
	}

	dl {
		padding: 0 1em;
		margin-bottom: 1px solid lightgray;
		padding-left: 50%;
		display: flex;
		flex-flow: wrap;
	}

	dl>* {
		flex: 1 50%;
		width: 50%;
		box-sizing: border-box;
		margin: 0;
		padding: 0.25em 0;
		position: relative;
	}

	dl dd {
		font-weight: bold;
	}

	dd {
		text-align: right;
	}

	.button-holder {
		padding-top: 0px;
	}

	.row.style-buttons {
		margin: 0em;
		justify-content: end;
		padding: 0.5em;
		gap: 0.5em;
		margin-bottom: 1em;
		border-top: var(--ui-border);
	}

	.button-holder.col-tight {
		margin: 0;
		padding: 0;
	}

	.summary {
		// background: var(--dimmed);
		margin-bottom: -1em;
		background: #eee;
		border-top: var(--ui-border);
		color: #383838;
	}
}
</style>