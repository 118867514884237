require('./bootstrap');
require('./apiConfig.js');

import Vue from 'vue';
import mobiscroll from '@mobiscroll/javascript';
import moment from 'moment';

//Rutas del website
import { store } from './store/store-init';
import Router from './router';
import Vue2Filters from 'vue2-filters';

//Librerias globales
import Library from './libs.js';

Vue.use(Library);
Vue.use(Vue2Filters);

//Componentes del website
import components from './components/components.js';
import { mapActions } from 'vuex';

Vue.use(components);

import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

Vue.use(VueGoogleMap, {
	load: {
		apiKey: 'AIzaSyBivXA5Zyz_oV_FNga56guJ4YuZGNfNz9o',
		libraries: ['places']
	}
})

Vue.filter('humanizar_fecha_completa', (value) => {
	moment.locale('es');
	return moment(value).format("dddd D [de] MMMM, h:mm:ss a");
	// return moment(value).format("dddd D [de] MMMM [del] YYYY, h:mm:ss a");
});

Vue.filter('humanizar_fecha', (value) => {
	moment.locale('es');
	return moment(value).format("dddd D [de] MMMM");
	// return moment(value).format("dddd D [de] MMMM [del] YYYY, h:mm:ss a");
});

Vue.filter('humanizar_diferencia', (value) => {
	moment.locale('es');
	return moment(value).fromNow();
});

Vue.filter('solo_hora', (value) => {
	moment.locale('es');
	return moment(value).format("h:mm a");
});

// window.Vue = Vue;

//Instancia principal
new Vue({
	el: '#app',
	router: Router,

	store,

	data: () => {
		return {
			isFirstLoaded: false,
			cartCount: 0,
			logged: false,
			user: {},
		}
	},

	async beforeMount() {
		this.isFirstLoaded = false;
		try {
			await Promise.all([
				this.recoverFromDixie(),
				this.favsRecoverFromDixie(),
				this.catalogUpdate(),
				this.cartRecoverFromDixie()
			])
		} catch (e) {
			console.error(e)
			mobiscroll.snackbar({
				display: 'top',
				theme: 'ios',
				message: msg,
				color: 'danger',
				duration: 7000
			})
		}
		this.isFirstLoaded = true;
	},

	methods: {
		...mapActions(['recoverFromDixie', 'favsRecoverFromDixie', 'catalogUpdate', 'cartRecoverFromDixie']),

		// == functions para la modificación de urls amigables ==
		_clearString(str) {
			if (str != null) {
				var newStr = str.trim()            // Quitar espacios al inicio y final
					.toLowerCase()          // Convertir a minusculas
					.replace(/\s/g, '-')    // Convertir espacios a "-"
					.normalize('NFD').replace(/[\u0300-\u036f]/g, "") // Vocales sin acento
					.replace(/[^a-z0-9-]+/gi, '')   // Quitar todo lo que no es del a-z, A-Z o 0-9 (excepto el simbolo "-")
					.replace(/--+/g, '-')   // Convertir multiples "-" en uno solo
					.replace(/^-/, '')      // Quita el simbolo "-" al inicio
					.replace(/-$/, '');     // Quita el simbolo "-" al final
				// console.log(newStr);
				return newStr;
			} else {
				return 'error';
			}
		},

		_converToURL(name, id) {
			var url = '';
			var newName = this._clearString(name);

			if (/^\d+$/.test(id)) { // Si es un numero
				url = newName + '/' + id;
			} else {
				url = '/error';
			}

			return url;
		},

	}

});
