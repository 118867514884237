<template>
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="register">

            <h1 class="mb-3">{{name}}</h1>

            <b-form-group class="custom-group-s-1" label="Nombre">
              <b-form-input type="text" v-model="form.nombre" required placeholder=""></b-form-input>
            </b-form-group>
        
            <b-form-group class="custom-group-s-1" label="Teléfono">
              <b-form-input type="text" v-model="form.telefono" required placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group class="custom-group-s-1" label="Email">
              <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group class="custom-group-s-1" label="Contraseña">
              <b-form-input type="password" v-model="form.password" required placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group v-if="form.password.length" class="custom-group-s-1" label="Confirma la contraseña">
              <b-form-input type="password" v-model="form.password_confirm" required placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group v-if="!isProcessing">
              <b-button key="proceed" type="submit" class="btn-blue" variant="primary">Registrar cuenta</b-button>
            </b-form-group>

            <b-form-group v-else>
              <b-button key="processing" class="btn-blue" variant="primary" disabled>Procesando...</b-button>
            </b-form-group>

          </b-form>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Crear mi cuenta',
  data(){
    return{
      isProcessing: false,
      form: {
					nombre: '',
					telefono: '',
					email: '',
					password: '',
					password_confirm: ''
      }
    }
  },

  methods: {
    ...mapActions(['trySignup']),
    async register(evt){
      evt.preventDefault()
      this.isProcessing = true;
      const response = await this.trySignup(this.form);
      if(response){
        this.$router.replace('/');
      }
      this.isProcessing = false;
    },
  }
}
</script>
