<template>
    <div class="sample-product shad-prod">

        <!-- [P: {{product.id}}] -->

        <div class="outlined-box">

            <a class="btn-favorite" v-bind:class="{ active: product.favorite }" @click="addFavorite()"></a>
            
            <router-link class="box-image" :to=" '/producto/' + $root._converToURL( product.name, product.id ) ">

                <div class="out-of-stock" v-if="product.stock < 1">
                    Agotado
                </div>

                <!-- <ApiImgPristine :comp="product.gallery[0]" /> -->

                <div class="placed-backg contain img" v-if="!!product.gallery" v-bind:style="{ backgroundImage: 'url(' + product.gallery[0] + ')', }">
                    <img src="public/images/shared/product.png" />
                </div>

                <div class="placed-backg contain img" v-else>
                    <img src="public/images/shared/product.png" />
                </div>
            </router-link>

            <div class="box-shot-descr">
                <h6 class="brand">{{ product.sku }}</h6>
                <h6 class="name">{{ product.name }}</h6>

                <div class="box-shipping" v-if="product.envio == 'Gratis'">
                    <div class="shopping">
                        <i class="fas fa-shipping-fast"></i>
                        <span>Envío gratis</span>
                    </div>
                </div>

                <h6 class="price" :class="{ discount: product.offer_price, }">
                    <span class="cross">{{ product.price | currency }}</span>
                    <span v-if="product.offer_price">${{ product.offer_price }}</span>
                </h6>
            </div>

            <p class="p-button">
                <router-link class="btn-more" :to=" '/producto/' + $root._converToURL( product.nombre, product.id ) ">
                    Ver mas
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'productItem',
    props: {
        productId: {
            type: [Number, String],
            required: true
        }
    }, 
    computed: {
        ...mapGetters(['catalogProducts'])
    },
    data: () => {
        return {
            product: {
                favorite: false
            }
        }
    },
    methods: {
        ...mapActions(['favToggleProductID', 'favIsProductID']),
        async addFavorite(){
            await this.favToggleProductID(this.product.productos_id)
            this.reviewIsFav()
        },
        async reviewIsFav() {
            this.product.favorite = await this.favIsProductID(this.product.productos_id)
            this.$forceUpdate()
        }
    },
    mounted(){
        let product = _.find(this.catalogProducts, {productos_id: this.productId})
        product.id = this.productId
        product.price = product.precio
        product.name = product.nombre
        if(!!product){
            this.product = _.merge(product, this.product)
            this.reviewIsFav()
        }
    }
}
</script>