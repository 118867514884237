<template>
	<div id="my-orders-index">

		<template v-if="getOrdersIsUpdated">

            <div class="pedido-summary" v-for="item in getOrdersIndex" :key="item.id">
                <div class="part-1">
                    <div class="img-holder">
                        <img :src="item.img" />
                    </div>
                    <div>
                        <div class="make-one-line">
                            <div>No. de pedido:</div>
                            <div class="normal column-cell">{{ item.numero_orden }}</div>
                        </div>
                        <div class="make-one-line">
                            <div class="limited">{{item.created | humanizar_fecha }}</div>
                            <div class="normal column-cell">{{item.total | currency}}</div>
                        </div>
                    </div>
                </div>
                <div class="part-2">
                    <div class="info">
                        <div>
                            <strong>{{item.pedido_estatus}}</strong>
                        </div>
                    </div>
                    <div class="button">
                        <!-- <router-link :to="{name: 'OrderDetail', params: {id: item.id}}"> -->
                        <button @click="$refs.orderDetailModal.loadUpOrder(item.id)">Detalles</button>
                        <!-- </router-link> -->
                    </div>
                </div>
            </div>

            <div class="empty" v-if="!getOrdersIndex.length">
                Aún no has dado de alta pedidos.
            </div>

		</template>
        <order-detail-modal ref="orderDetailModal" @closingModal="ordersUpdate"/>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash'
import OrderDetailModal from './OrderDetailModal.vue'

// Una vista en este formato reuqiere que se manejen de manera independiente
// el formulario, la página y la lista.
export default {
	name: "my-orders-index",
    components: {OrderDetailModal},
	computed: {
		...mapGetters(["getOrdersIndex", "getOrdersIsUpdated", "getOrderSellers"])
	},
	methods: {
		...mapActions(["ordersUpdate"])
	},
	mounted() {
		this.ordersUpdate();
	},
};
</script>
<style lang="less">
#my-orders-index {
	min-height: 100vh;
	.pedido-summary {
		padding: 1em;
		margin: 1.5em 0 0 0;
		background-color: #f2f2f2;
		border-radius: 0.5em;

		& + .pedido-summary {
			margin-top: 1em;
		}

		.part {
			&-1 {
				display: flex;
				flex-flow: row;
				align-items: center;
				padding-bottom: 0.75em;
				border-bottom: 1px solid lightgray;

				.img-holder {
					flex: 0;
					padding: 0em 1em 0em 0;
					img {
						width: 5em;
						height: 5em;
						object-fit: cover;
						border: 1px solid #e3e3e3;
						padding: 0em;
						border-radius: 0.5em;
						box-shadow: 0 0 0.35em rgba(0, 0, 0, 0.144) inset;
					}
				}

				.make-one-line {
					display: inline-flex;
					width: 100%;
					& > * {
						flex: 1 0 auto;
						margin:  0.0em 0em;
						&.limited {
							flex: 0 0 40vw;
							max-width: 10em;
						}
					}
				}
			}

			&-2 {
				display: flex;
				flex-flow: row;
				align-items: center;
				padding-top: 1em;

				.info {
					flex: auto;
				}

				.button {
					flex: 0;
					button {
						background-color: var(--primary);
						border: none;
						border-radius: 2em;
						font-size: 1rem;
						color: white;
						padding: 0.5em 2em;
						transition: 300ms ease all;
						cursor: pointer;
						&:hover,
						&:active {
							transition: 0ms ease all;
							background-color: #fb7722;
						}
					}
				}
			}
		}

		.slim {
			color: lightgrey;
		}

		.normal {
			font-weight: bold;
			text-align: right;
		}
	}
}
</style>