// IMPORTS
import _ from 'lodash'
import swal from 'sweetalert'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript'
import router from '../../router.js'
import Db from '../../dixie.js'
import emptyPicture from '../../../../public/images/empties/placeholder.svg'
import {OneSignalLink, OneSignalUnLink} from '../../onesignal.js'
// import router from './router.js'
// import Db from '../../dixie.js'
// import emptyPicture from '@/resources/js/assets/empties/placeholder.svg'

// DEFINITIONS
const loginApi 		= 'account/trylogin',
		signupApi = 'account/register',
		updateApi = 'account/update'

const state = {
	token: null,
	processing: null,
	user: null,
	selected_unit: null,
	loginCredentials: {},
	avatarPicture: null,		// STORES THE FUNCTIONAL USER AVATAR
	newAvatarPicture: null,		// STORES THE UPDATING USER AVATAR
	requireAvatarUpdate: false,
}

const getters = {
	accountGetAvatar: state => { 
		return (state.avatarPicture || emptyPicture )
	},
	accountUpdatingAvatar: state => { 
		return (state.newAvatarPicture || state.avatarPicture || emptyPicture )
	},
	accountGetRequireAvatarUpdate: state => { 
		return state.requireAvatarUpdate 
	},
	isLogged: state => {
		return (state.token!=null)
	},
	accountGetToken: state => {
		return state.token
	},
	accountGetLoginCredentials: state => {
		return state.loginCredentials
	},
	accountIsProcessing: state => {
		return state.processing
	},
	accountNewGetAvatar: state => { 
		return state.newAvatarPicture
	},
	accountGetUser: (state) => {
		return state.user || {
			imagenes_id_avatar: null
		}
	},
	accountGetData: (state) => {
		return state.login
	}
}

const mutations = {
	accountTakePicture: (state, payload) => {
		state.newAvatarPicture = payload
		state.requireAvatarUpdate = true
	},
	accountSetBusy: (state) => {
		state.processing = true
	},
	accountUnsetBusy: (state) => {
		state.processing = false
	},
	accountSet: (state, payload) => {
		state.processing = false
		state.token = payload.token
		state.user = payload
		Db.configs.where('key').equalsIgnoreCase('token').or().equalsIgnoreCase('user').delete().then(()=>{
			Db.configs.add({
				key: 'token',
				value: state.token
			})
			Db.configs.add({
				key: 'user',
				value: JSON.stringify(state.user)
			})
		})
	},
	accountClean: (state) => {
		Db.configs.where('key').equalsIgnoreCase('token').delete()
		Db.configs.where('key').equalsIgnoreCase('user').delete()
		state.processing = false
		state.token = null
		state.user = null	
		state.avatarPicture = ''		// STORES THE FUNCTIONAL USER AVATAR
		state.newAvatarPicture = ''		// STORES THE UPDATING USER AVATAR
		state.requireAvatarUpdate = false
		mobiscroll.toast({
			theme: 'ios',
			color: 'success',
			message: '¡Hasta pronto!',
			duration: 7000
		})
	}
}

const actions = {
	tryUpdate: async ({commit}, payload ) => {
		commit('accountSetBusy')
		try {
			const response = await axios.post( updateApi, payload )

			// ANALISA SI HAY MENSAJES PARA EL USUARIO
			if (response.msg) {
				mobiscroll.snackbar({
					display: 'top',
					theme: 'ios',
					message: response.msg,
					color: response.class,
					duration: 7000
				})
			}

			if(response.status){
				commit('accountSet', response.data) // FROM LOGIN
			}

			commit('accountUnsetBusy')

			return response.status;
		} catch(e){
			console.error(e);
			mobiscroll.toast({
				theme: 'ios',
				display: 'top',
				message: 'Asegurate de contar con conexión activa a internet antes de continuar.',
				color: 'warning',
				duration: 7000
			})
			commit('accountUnsetBusy')
			return false;
		}
	},
	recoverFromDixie: ({commit, dispatch}) => {
		return new Promise(( success ) => {
			Db.configs.where('key').equalsIgnoreCase('user').first().then(user => {
				let userData = JSON.parse(user.value)
				commit('accountSet', userData) // RESTARTING FROM DIXIE
				// dispatch('pushInit', userData) // RESTARTING FROM DIXIE
				success('Session recovered from dixie')
			}).catch(d => {
				success('No session available')
			})
		})
	},
	tryLogin: async ({commit}, payload) => {
		commit('accountSetBusy')
		try {
	
			let response = await axios.post( loginApi, payload )
	
			// ANALISA SI HAY MENSAJES PARA EL USUARIO
			if (response.msg) {
				mobiscroll.snackbar({
					display: 'top',
					message: response.msg,
					color: response.class
				})
			}
	
			if(response.status){
				commit('accountSet', response.data) // FROM LOGIN
				OneSignalLink(response.data.id)
			}

			return response.status
	
		} catch(e){
			console.error(e)			
		}
		commit('accountUnsetBusy')
	},
	tryLogout: ({dispatch, commit}) => {
		return new Promise((success, fail) => {
			swal({
				text: '¿Estás seguro que deseas cerrar sesión?',
				icon: 'info',
				buttons: {
					cancel: 'Cancelar',
					sign_out: 'Si, cerrar sesión'
				}
			}).then(selected => {
				if(selected == 'sign_out') {
					dispatch('cartSetClean')
					commit('accountClean')	// CLANS USER DATA AND CREDENTIALS
					commit('unitClean')			// CLEANS USERS SELECTED UNIT
					OneSignalUnLink()
					router.replace('/');
				}
			})
		})
	},
	trySignup: async ({commit}, payload) => {
		commit('accountSetBusy')
		try {
			const response = await axios.post( signupApi, payload );

			// ANALISA SI HAY MENSAJES PARA EL USUARIO
			if (response.msg && !response.status) {
				mobiscroll.snackbar({
					display: 'top',
					theme: 'ios',
					message: response.msg,
					color: response.class
				})
			} else if(response.msg){
				await swal({
					title: response.msg,
					icon: response.class
				})
			}

			if(response.status){
				commit('accountSet', response.data) // FROM SIGNUP
			}

			return response.status
		} catch(e){
			console.error(e)
			mobiscroll.toast({
				theme: 'ios',
				display: 'top',
				message: 'Asegurate de contar con conexión activa a internet antes de continuar.',
				color: 'warning'
			})
		}
		commit('accountUnsetBusy')
	},
	accountChangeAvatar: ({commit, getters}) => {
		return new Promise((success, fail) => {
			// INIT
			let done = function(serverResponse) {
					// console.log("serverResponse", serverResponse.response)
					let response = JSON.parse(serverResponse.response)

					if(!!response.msg){
						swal({text: response.msg, icon: response.class})
					}

					if(response.status){
						let userUpdate = _.clone(getters.accountGetUser)
						userUpdate.imagenes_id_avatar = response.data.imagenes_id_avatar
						commit('accountSet', userUpdate) // NO PUSH UPDATE REQUIRED
						success(response)
					} else {
						fail(response)
					}
				},
				errorHandler = function(error) {
					console.error("errorHandler", error)
					swal({text: 'An error ocurred trying to update the picture', icon: 'warning'})
					fail(serverResponse)
				},
				picture = getters.accountNewGetAvatar,
				options = new FileUploadOptions(),
				ft = new FileTransfer()

			// CONSTRUYE EL OBJETO DE CONFIGURACIÓN
			options.fileKey 	= 'picture'
			options.fileName 	= picture.substr(picture.lastIndexOf('/') + 1)
			options.mimeType 	= 'image/jpeg'
			options.params 		= {token: getters.accountGetToken}
			options.headers 	= { Connection: "close" }
			options.chunkedMode = false

			// CARGA AL SERVIDOR
			ft.upload(picture, encodeURI(axios.defaults.baseURL + "/account/uploadpicture"), done, errorHandler, options)
		})
	}
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
