// IMPORTS
import _ from 'lodash'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript'

let dliverySyncAPI = "ordenes/sync"

// DEFINITIONS
const state = {
    isBusy: false,
	data: []
}

const getters = {
    deliveryGet: (state) => {
        return state.data
    },
    deliveryIsBusy: (state) => {
        return state.isBusy
    }
}

const mutations = {
	deliverySet: (state, payload) => {
        state.data = payload
    },
    deliveryClean: (state) => {
        state.data = []
    },
    deliverySetBusy: (state) => {
        state.isBusy = true
    },
    deliverySetUnbusy: (state) => {
        state.isBusy = false
    }
}

const actions = {
	deliverySync: ({commit, getters}) => {
        commit('deliverySetBusy')
        return new Promise((processDone, processFail) => {
            axios.post(dliverySyncAPI, {token: getters.accountGetToken}).then((response) => {
                // free items
                commit('deliverySetUnbusy')
                // ANALIZA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
                if (response.msg && response.msg.length) {
                  mobiscroll.snackbar({
                    display: "top",
                    theme: "ios",
                    message: response.msg,
                    color: response.class,
                    duration: 7000,
                  });
                }
                if (response.status && response.data) {
                    commit('deliverySet', response.data);
                    processDone()
                }else {
                    commit('deliveryClean');
                    processFail()
                }
              }).catch((error) => {
                    // ANALIZA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
                    console.error(error)
                    // mobiscroll.snackbar({
                    //     display: 'top',
                    //     theme: 'ios',
                    //     message: 'Hay un problema de comunicación con el servidor, por favor verifica tu conexión',
                    //     color: 'danger',
                    //     duration: 7000
                    // })
                    commit('deliverySetUnbusy')
                    processFail()
              });
        })
    }
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};