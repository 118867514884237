// IMPORTS
import _ from 'lodash'
import axios from 'axios'

// DEFINITIONS
const getMineURI = 'orders/getlist'

const state = {
    updated: false,
	headers: [],
	details: [],
	vendedores: [],
}

const getters = {
    getOrdersIndex: state => { return state.headers },
    getOrdersIsUpdated: state => { return state.updated },
    getOrderSellers: state => { return state.vendedores }
}

const mutations = {
	setOrders: (state, payload) => {
        state.updated = true
        state.headers = payload.headers
        state.details = payload.details
        state.vendedores = payload.vendedores
    }
}

const actions = {
    orderReturnByID:({state}, payload) => {
        return new Promise((success) => {
            let header = _.find(state.headers, {id: payload}), 
                details = _.filter(state.details, {pedidos_id: payload})
            success({header, details})
        })
    },
	ordersUpdate: ({getters, commit}) => {
        let data2send = { token: getters.accountGetToken }
        axios.post(getMineURI, data2send).then( (response) => {
            if(response.status){
                commit('setOrders', response.data)
            }
        })
    }
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
