<template>
	<div id="user-access-page" class="placed-backg">

		<div class="container">
			<div class="row mx-0 flex-center-xy page-size">

				<div class="form-container">
					<div class="box-color">
						<i>
							<u class="fas fa-user"></u>
						</i>
					</div>


					<div class="form">
						<h1 class="mb-3">Recuperar contraseña</h1>
						<a-recovery />
					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import aRecovery from './../usuario-cuenta/asides/aRecovery.vue'
export default {
	components: {aRecovery}
};
</script>
