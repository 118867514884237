<template>
	<div class="navigation-holder">
		<b-pagination
			v-model="currentPage"
			:total-rows="rows"
			:per-page="perPage"
			align="right"
		/>
	</div>
</template>

<script>
import _ from "lodash";
export default {
	name: "Navigation",
	props: {
		rows: {
			type: [Number, String],
			default: 100000,
		},
		perPage: {
			type: [Number, String],
			default: 8,
		},
	},
	data: () => {
		return {
			currentPage: "1",
			isMounted: false,
		};
	},
	methods: {
		checkPage() {
			let route = {
				name: this.$route.name,
				query: _.clone(this.$route.query),
				params: {
					id: this.$route.params.id,
					page: 1,
				},
			};

			if (typeof this.$route.params.page == "undefined") {
				this.$router.replace(route);
			} else if (
				parseInt(this.$route.params.page) != parseInt(this.currentPage)
			) {
				route.params.page = parseInt(this.currentPage);
				this.$router.push(route);
			}
		},
	},
	beforeMount() {
		// this.currentPage = _.clone(this.$route.params.page)
		// this.checkPage()
		this.isMounted = false;
		setTimeout(() => {
			this.currentPage = _.clone(this.$route.params.page);
			this.isMounted = true;
		}, 150);
	},
	watch: {
		currentPage() {
			if (this.isMounted) this.checkPage();
		},
	},
};
</script>

<style lang="less">
.navigation-holder {
	.page-item.active .page-link {
		z-index: 3;
		color: #fff;
		background-color: var(--primary);
		border-color: var(--primary);
	}
	.page-link {
		// color: #007bff;
		color: var(--primary);
	}
}
</style>