// IMPORTS
import swal from 'sweetalert'
import _ from 'lodash'

// DEFINITIONS
const state = {
    geocode: [],
	currentLocation:{
		lat: null,
		lng: null
	},
	configs:{
		googleMapsKey: "AIzaSyBivXA5Zyz_oV_FNga56guJ4YuZGNfNz9o"
	}
}

const getters = {
    mapsGetGeocoded: (state) => {
        return state.geocode
    },
	mapsGetGoogleMapsKey: (state) => {
		return state.configs.googleMapsKey
	},
	mapsGetGPSPositionLat: (state) => {
		return state.currentLocation.lat
	},
	mapsGetGPSPositionLng: (state) => {
		return state.currentLocation.lng
	}
}

const mutations = {
	mapsSetCurrentCoordenates: (state, payload) => {
		state.currentLocation.lat = payload.coords.latitude
		state.currentLocation.lng = payload.coords.longitude
    },
    mapsGeocode: (state, payload) => {
        state.geocode = payload
    }
}

const actions = {
	loadMaps:({getters}) => {
		var s = document.createElement('script');
		s.setAttribute('src', "https://maps.googleapis.com/maps/api/js?key=" + getters.mapsGetGoogleMapsKey + "&libraries=places");
		s.setAttribute('type', 'text/javascript');
		s.setAttribute('async', 'async');
		document.body.appendChild(s);
	},
    geocodePosition: async ({state,commit}) => {
        let data2send = {
            latlng: state.currentLocation.lat + ',' + state.currentLocation.lng,
            key: state.configs.googleMapsKey
        }
		const fetchResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data2send.latlng}&key=${data2send.key}`, {
			"method": "GET",
			"headers": {}
		})
		if(fetchResponse.ok) {
			const httpResponse = await fetchResponse.json()
			commit("mapsGeocode", httpResponse)
		}
    },
	mapsUpdatePositionByGPS: ({commit}, payload) =>	{
		return new Promise((success, fail) => {
			let GPSWatcher,
				onSuccess = function(position) {
					commit('mapsSetCurrentCoordenates', position)
					if (GPSWatcher == null) {
						GPSWatcher = navigator.geolocation.watchPosition(onSuccessForWatcher, onError, {
							timeout: 30000,
							maximumAge: 15000,
							enableHighAccuracy: true
						});
					}
					success()
					onSuccessForWatcher(position)
				},
				onSuccessForWatcher = function(position) {
					commit('mapsSetCurrentCoordenates', position)
				},
				onError = function(error) {
					if (!payload) {
						console.log("error triggered but user didn´t required this event, data passed by payload", error);
						return false;
					}
					if (error.code) {
						swal('No se ha recibido tu ubicación actualizada.',
							'Asegurate de tener encendio el GPS y que la aplicación tenga permiso para acceder a tu ubicación.',
							'warning');
					} else {
						swal({text: ('code: [' + error.code	+ '] message: [' + error.message + "]"), icon: 'error'});
					}
					navigator.geolocation.clearWatch(GPSWatcher);
					GPSWatcher = null;
					fail()
				};
	
			navigator.geolocation.getCurrentPosition(onSuccess, onError, {
				timeout: 10000,
				maximumAge: 3000,
				enableHighAccuracy: false
			})
		})

	}
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
