<template>
	<!-- 
		API IMG PRISTINE

		# Version 1.3
		AllowZoom ahora muestra la imagen sin crop!

		# Version 1.2
		Con el parámtro thumbnail automáticamente redondea la imágen y la define a 7em
	 -->
	<img :src="computedUri" @click="openGLightBox" :class="{allowZoom: allowZoom, thumbnail}" :alt="alt" />
</template>

<script>
	import axios from "axios";
	import GLightBox from 'glightbox' // https://github.com/biati-digital/glightbox
	// import noPicture from "@/assets/images/no-picture.png";
	let noPicture = 'public/images/no-picture.png'

	require('glightbox/dist/css/glightbox.min.css')

	export default {
		props: {
			thumbnail: {
				type: Boolean,
				default: false
			},
			comp: {
				type: [String, Number],
				default: null
			},
			size: {
				type: String,
				default: null
			},
			alt: {
				type: [String]
			},
			allowZoom: {
				type: [Boolean, Number, String],
				default: false
			}
		},
		computed: {
			unZoomedPicture(){
				return axios.defaults.maediaImageRender + this.comp + "/b-imgs.jpg"
			},
			computedUri() {
				if (
					this.comp != null &&
					this.comp != "" &&
					typeof parseInt(this.comp) == "number"
				) {
    				if(!!this.size)
	    				return axios.defaults.maediaImageRender + this.comp + "/" + this.size + "/b-resized.jpg";
                    else
	    				return this.unZoomedPicture;

				} else {
					return noPicture;
				}
			}
		},
		data: () => {
			return {
				lBox: null
			}
		},
		methods: {
			openGLightBox() {
				if(this.allowZoom && this.allowZoom != 'false' && !!this.comp) {
					this.lBox = new GLightBox()
					this.lBox.insertSlide({
						href: this.unZoomedPicture,
						type: 'image'
					})
					this.lBox.open()
				} else {
					console.log(this.allowZoom)
				}
			}
		}
	};
</script>

<style lang="less">
	.thumbnail {
        width: 6em;
        height: 6em;
		border-radius: 0.5em;
	}

	.glightbox-mobile .goverlay {
		background: hsla(0, 0%, 0%, 0.7) !important;
	}

	.gslide-image img {
		box-shadow: var(--ui-box-shadow);
	}
</style>