<template>
	<header id="header">
		<div class="header-menu">
			<b-navbar toggleable="lg" type="light" variant="light">
				<div class="container oversized-container">
					<b-navbar-brand to="/">
						<img src="public/images/logo-web.svg" />
					</b-navbar-brand>

					<div class="ml-auto d-lg-none">
						<router-link class="nav-btn-search" to="/cart">
							<i class="far fa-shopping-cart"></i>
						</router-link>

						<router-link class="nav-btn-search" to="/mis-favoritos">
							<i class="far fa-heart"></i>
						</router-link>

						<a class="nav-btn-search" @click="$refs['modal-search'].show()">
							<i class="far fa-search"></i>
						</a>
					</div>

					<b-navbar-toggle target="nav-collapse"><i class="fas fa-bars bar-icon"></i></b-navbar-toggle>

					<b-collapse id="nav-collapse" is-nav>
						<b-navbar-nav class="ml-auto">
							<!-- <b-nav-item to="/">Inicio</b-nav-item> -->
							<li class="nav-item simple-item">
								<a class="nav-link" @click="isPActive = !isPActive">
									CATEGORIAS
									<i class="fas fa-bars"></i>
								</a>
							</li>
							<b-nav-item to="/afiliados">Afiliados</b-nav-item>
							<b-nav-item to="/nosotros">Nosotros</b-nav-item>
							<b-nav-item to="/preguntas-frecuentes"><span>FAQs</span></b-nav-item>
							<b-nav-item-dropdown class="nav-reg" v-if="!isLogged" right>
								<template #button-content>
									<span>Acceder</span>
								</template>

								<b-dropdown-item to="/login">Iniciar sesión</b-dropdown-item>
								<b-dropdown-item to="/signup">Crear mi cuenta</b-dropdown-item>

								<li class="li-line">
									<div class="dropdown-item">
										<hr class="my-0" />
									</div>
								</li>

								<b-dropdown-item target="_blank" href="https://refapp.online/ventas/#/auth/login/">Ir a mi panel de ventas</b-dropdown-item>
								<b-dropdown-item to="/registrar-mi-negocio">Registra tu negocio de
									refacciones</b-dropdown-item>
								<b-dropdown-item to="/registrar-repartidor">Registrate como repartidor</b-dropdown-item>
							</b-nav-item-dropdown>

							<b-nav-item-dropdown class="nav-reg" v-else right>
								<template #button-content>
									<span>Mi cuenta</span>
								</template>

								<b-dropdown-item to="/usuario">Mi perfil</b-dropdown-item>
								<b-dropdown-item to="/usuario/historial-compras">Mis pedidos</b-dropdown-item>

								<li class="li-line">
									<div class="dropdown-item">
										<hr class="my-0" />
									</div>
								</li>

								<b-dropdown-item @click="tryLogout">Cerrar sesión</b-dropdown-item>

								<li class="li-line">
									<div class="dropdown-item">
										<hr class="my-0" />
									</div>
								</li>

								<!-- <b-dropdown-item target="_blank" href="https://refapp.online/admin/">Como Administrador</b-dropdown-item> -->
								<b-dropdown-item to="/registrar-mi-negocio">Registra tu negocio de
									refacciones</b-dropdown-item>
								<b-dropdown-item to="/registrar-repartidor">Registrate como repartidor</b-dropdown-item>
							</b-nav-item-dropdown>

							<li class="nav-item item-search">
								<div class="nav-link" to="/cart">
									<router-link to="/cart"> <i class="fas fa-shopping-cart"></i> </router-link>
									<router-link to="/mis-favoritos"> <i class="fas fa-heart"></i> </router-link>
								</div>
							</li>

							<NotificationsHandler />

							<li class="nav-item nav-apps">
								<span class="nav-link">
									<span class="title">¡Descarga la App!</span>
									<a class="btn-app" target="_blank"
										href="https://play.google.com/store/apps/details?id=com.sustam.refapp">
										<img src="public/images/shared/google-play.svg" />
									</a>

									<a class="btn-app" target="_blank"
										href="https://apps.apple.com/mx/app/refapp/id1552936207">
										<img src="public/images/shared/app-store.svg" />
									</a>
								</span>
							</li>

						</b-navbar-nav>
					</b-collapse>
				</div>
			</b-navbar>
		</div>

		<div class="header-search-box">
			<div class="container med-container">
				<b-form class="form-inline" @submit="onSubmitSearch">
					<b-button type="submit" class="btn-search-icon"><i class="far fa-search search-icon"></i></b-button>
					<b-form-input v-model="form.keywords" placeholder="Buscar..."></b-form-input>
					<b-button type="submit" class="btn-send">Buscar</b-button>
				</b-form>
			</div>
		</div>

		<!-- Products menu -->
		<div class="tr-250 products-menu" v-bind:class="{ active: isPActive }">
			<div class="menu-bg" @click="isPActive = false"></div>

			<div class="tablist" role="tablist">
				<div class="tr-250 box-title" @click="isPActive = false">
					<h4>
						CATEGORIAS
						<i class="fas fa-bars"></i>
					</h4>
				</div>

				<b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
					<b-card-header header-tag="header" role="tab">
						<div v-if="category.subcategories" class="btn-toggle">
							<router-link :to="'/categoria/' +
								$root._converToURL(
									category.name,
									category.id
								)
								">{{ category.name }}</router-link>
							<span class="fake-toggle" v-b-toggle="'accordion-' + pcatIndex"></span>
						</div>
						<router-link v-else class="btn-toggle" :to="'/categoria/' +
							$root._converToURL(category.name, category.id)
							">{{ category.name }}</router-link>
					</b-card-header>

					<b-collapse v-if="category.subcategories" :id="'accordion-' + pcatIndex" accordion="my-accordion"
						role="tabpanel">
						<b-card-body>
							<ul class="ul-sub-options">
								<li v-for="subcategory in category.subcategories">
									<router-link class="tr-250" :to="'/subcategoria/' +
										$root._converToURL(
											subcategory.name,
											subcategory.id
										)
										">{{ subcategory.name }}</router-link>
								</li>
							</ul>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
		<!--  -->

		<!-- Search -->
		<b-modal ref="modal-search" modal-class="modal-search-e" size="lg" title="" @hidden="form.keywords = null"
			hide-footer no-close-on-esc no-close-on-backdrop>
			<!-- NOTA: form.keywords será null siempre al cerrar el modal, esto por usar el evento @hidden -->

			<b-form @submit="onSubmitSearch">
				<b-form-group>
					<b-form-input v-model="form.keywords" type="text" placeholder="Buscar..." size="lg"
						required></b-form-input>
				</b-form-group>

				<b-button type="submit" class="btn-search"><i class="far fa-search"></i></b-button>
			</b-form>
		</b-modal>
		<!--  -->
	</header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationsHandler from "./notifications-handler.vue";

export default {
    name: "Header",
    computed: {
        ...mapGetters(["isLogged", "catalogPlainCats"]),
        categories() {
            return _.map(this.catalogPlainCats, (item) => {
                let itemNew = {
                    id: parseInt(item.id),
                    name: item.categoria_nombre,
                };
                return itemNew;
            });
        },
    },
    data() {
        return {
            isPActive: false,
            // categories:[
            //   { id: 1, name: 'Refacciones', subcategories: [
            //     { id: 2, name: 'Subcategoría Refacciones 1' },
            //     { id: 3, name: 'Subcategoría Refacciones 2' },
            //     { id: 4, name: 'Subcategoría Refacciones 3' },
            //     ]
            //   },
            //   { id: 10, name: 'Llantas', subcategories: [
            //     { id: 5, name: 'Subcategoría Llantas 1' },
            //     { id: 8, name: 'Subcategoría Llantas 2' },
            //     { id: 9, name: 'Subcategoría Llantas 3' },
            //     ]
            //   },
            //   { id: 182, name: 'Accesorios' },
            //   { id: 182, name: 'Servicios' },
            // ],
            form: {
                keywords: null,
            },
        };
    },
    methods: {
        ...mapActions(["tryLogout"]),
        onSubmitSearch(event) {
            event.preventDefault();
            this.$router.push({
                name: "isSearch",
                params: { page: "1" },
                query: { keywords: this.form.keywords },
            });
            this.$refs["modal-search"].hide();
        },
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.keywords) {
                this.form.keywords = this.$route.query.keywords;
            }
            else {
                this.form.keywords = "";
            }
            // Ocultar menu cada vez que cambie la ruta
            this.isPActive = false;
            // Ocultar el modal de busqueda cada vez que cambie la ruta
            this.$refs["modal-search"].hide();
        },
    },
    mounted() {
        if (!!this.$route.query.keywords)
            this.form.keywords = this.$route.query.keywords;
    },
    components: { NotificationsHandler }
};
</script>
