<template>
	<div id="results-page">
		<section class="breadcrumb-section">
			<div class="container oversized-container">
				<span>Home</span>
				<span class="line">/</span>
				<span>Afiliados</span>
			</div>
		</section>

		<section class="container oversized-container products-section">
			<div class="row mb-3">
				<div class="col-12 col-page-title">
					<h2 class="title-s2">Afiliados</h2>
				</div>
			</div>

			<div class="row">
				<!-- Results -->
				<div class="col-lg">
					<div class="row sample-products-wrapper xxl-4e align-center">

						<div class="col-sm-6 col-lg-4 mb-4 sample-affiliates shad-prod" v-for="(seller, pInx) in catalogSellers" :key="'pInx-' + pInx">

							<router-link class="outlined-box" :to="{ name: 'AfiliadoShowroom', params: {id: seller.vendedores_id} }">

								<div class="box-image">
									<div class="img">
										<img
											src="public/images/shared/product.png"
											v-bind:style="{
												backgroundImage:
													'url(' + seller.logo + ')',
											}"
										/>
									</div>
								</div>

								<div class="box-shot-descr">
									<h6 class="name">{{ seller.nombre }}</h6>
								</div>

							</router-link>
							
						</div>

						<h5 v-if="!catalogSellers.length">
							No se encontraron resultados
						</h5>
					</div>
				</div>
				<!--  -->
			</div>
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			products: [
				// { id: 1, img: 'public/images/logo-web.png', name: 'Nombre del afiliado' },
				{
					id: 1,
					img: "public/images/logo-web.png",
					name: "Nombre del afiliado",
				},
			],
		};
	},

	computed: {
		...mapGetters(['catalogSellers'])
	},

	methods: {},

	mounted() {},
};
</script>
