// IMPORTS
import _ from 'lodash'
import axios from 'axios'

// DEFINITIONS
const bannersApi = 'banners'

const state = {
	localBanners: []
}

const getters = {
    bannersList: (state) => {
        return state.localBanners
    }
}

const mutations = {
    bannersSetList: (state, payload) => {
        state.localBanners = payload
    }
}

const actions = {
	bannersUpdate: ({commit}) => {
        axios.get(bannersApi).then(response => {
            if(response.status)
                commit("bannersSetList", response.data)
        })
    }
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
