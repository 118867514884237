<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/faqs/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center">

        <div class="col-lg-6 col-xl-5 col-info">
          <h1 class="mb-4 page-title">Preguntas frecuentes</h1>

          <h5 class="subtitle-sm">¿Son una refaccionaria?</h5>
          <p>
            No, somos una plataforma que conecta refaccionarias automotrices con usuarios.
          </p>

          <h5 class="mt-2 subtitle-sm">¿Garantizan mi producto?</h5>
          <p>
            Si, auditamos a nuestros afiliados para que provean los mejores productos, con un precio dentro del mercado y en el menor tiempo posible.
          </p>

          <h5 class="mt-2 subtitle-sm">¿Qué pasa si me entregaron un producto incorrecto?</h5>
          <p>
            Reportalo de inmediato a <a href="mailto:ayuda@refaapp.com">ayuda@refaapp.com</a>
          </p>

          <h5 class="mt-2 subtitle-sm">¿Cómo puedo convertirme en un vendedor afiliado?</h5>
          <p>
            Envíanos tu información a <a href="mailto:afiliaciones@refaapp.com">afiliaciones@refaapp.com</a>
          </p>

        </div>

        <div class="col-lg-5 col-xl-6 col-image"></div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null
      },
      redes: [],
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    getRedes: function(){
      axios.get(tools.url('/redes')).then((response)=>{
        this.redes = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/contacto"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },

  beforeMount(){
    this.getRedes();

    if(this.$route.query.p){
      this.form.msg = 'Me intresa el producto '+ this.$route.query.p;
    }
  }
}
</script>
