import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home.vue').default },
		{ path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros"} },
		{ path: '/preguntas-frecuentes', component: require(page+'faqs/index.vue').default, meta:{title:"Preguntas Frecuentes"} },
		{ path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"} },

		{ path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de Privacidad"} },
		{ path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"} },

		{ path: '/afiliados', component: require(page+'affiliates/index.vue').default, meta:{title:"Afiliados"}},
		{ path: '/afiliados/:id/:page?', name: 'AfiliadoShowroom', component: require(page+'products/categories.vue').default, meta:{title:"Afiliado"}},

		{ path: '/categoria/:catName/:id/:page?', name: 'isCat', component: require(page+'products/categories.vue').default, meta:{title:"Categorias"}},
		{ path: '/busqueda/:page?', name: 'isSearch', component: require(page+'products/categories.vue').default, meta:{title:"Búsqueda"}},
		
		// { path: '/subcategoria/:id', component: require(page+'products/subcategories.vue').default, meta:{title:"Subcategorias"}},
		{ path: '/producto/:productName/:id', name: 'ProductDetail', component: require(page+'products/description.vue').default, meta:{title:"Producto"}},

		{ path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}, name: 'Cart'},
		{ path: '/mis-favoritos', component: require(page+'carrito/mis-favoritos.vue').default, meta:{title:"Mis favoritos"}},

		{ path: '/login', name: 'SignIn', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
		{ path: '/signup', component: require(page+'user-access/signup.vue').default, meta:{title:"Crear mi cuenta"}},
		{ 
			path: '/recuperar-contrasena',
			name: 'ARecovery',
			redirect: '/recuperar-contrasena/solicita',
			children: [
				{path:'solicita', name: 'ARecoveryRequire', meta: {type:"require"}},
				{path:'consulta', name: 'ARecoveryVerify', meta: {type:"verify"}},
				{path:'actualiza', name: 'ARecoveryUpdate', meta: {type:"update"}},
			],
			component: require(page+'user-access/reset-password.vue').default, 
			meta:{title:"Recuperar contraseña"}
		},
		{ path: '/registrar-mi-negocio', component: require(page+'user-access/signup-business.vue').default, meta:{title:"Registra tu negocio"}},
		{ path: '/registrar-repartidor', component: require(page+'user-access/signup-delivery-boy.vue').default, meta:{title:"Registrate como repartidor"}},

		{ 	
			path: '/usuario', 
			component: require(page+'usuario-cuenta/index.vue').default, 
			redirect: '/usuario/mis-datos',
			children: [
				{
					name: 'MyData',
					path: 'mis-datos',
					component: require(page+'usuario-cuenta/mis-datos.vue').default,
				},
				// {
				// 	path: 'metodos-pago',
				// 	component: require(page+'usuario-cuenta/asides/PaymentMethodsIndex.vue').default,
				// },
				{
					path: 'historial-compras',
					component: require(page+'usuario-cuenta/historial-compras.vue').default,
				},
			]
		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "REFAAPP", filter: (title)=>{ return title+" - REFAAPP"; } }
);

// export {routes};
export default MyRouter;
