// AXIOS SETUP
import axios from "axios"
import { store } from "./store/store-init"

axios.defaults.maediaImageRender = 'https://api.refapp.online/img/render/';
axios.defaults.timeout = 30000
axios.defaults.withCredentials = false
axios.defaults.switchAPI = (waty) => {
	switch (waty) {
		case 'sales':
			axios.defaults.baseURL = 'https://api.refapp.online/salesmobile'
			axios.defaults.isSales = true
			break;
		case 'consumer':
			axios.defaults.baseURL = 'https://api.refapp.online/api'
			axios.defaults.isSales = false
			break;
	}
}
axios.defaults.switchAPI('consumer')

axios.interceptors.request.use((config) => {

    // Do something before request is sent
    config.headers['Content-Type'] = 'application/json';

    if (!!store.getters.accountGetToken)
        config.headers['AUTH-TOKEN'] = store.getters.accountGetToken;
    else if (typeof config.headers['AUTH-TOKEN'] != 'undefined')
        delete config.headers['AUTH-TOKEN'];

    if (!!axios.defaults.headers.common && !!axios.defaults.headers.common['X-Requested-With'])
        delete axios.defaults.headers.common['X-Requested-With'];

    return config;

}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
    // Do something with response data
    let returnable = response.data
    return returnable
}, (error) => {
    // Do something with response error
    return Promise.reject(error);
});