<template lang="html">
	<div class="col-cart-data loggedin-cart">

		<div class="col-12 pt-3 product-list-table">
			<my-orders-index />
		</div>

	</div>
</template>

<script>
import myOrdersIndex from './asides/MyOrdersIndex.vue'
export default {
	name: 'HistorialCompras',
	components: {myOrdersIndex}
};
</script>
