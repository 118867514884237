<template>
	<div class="ui-input">
		<!--
			Version 3.4
			Metodología CARD, CARD-CVV, CARD-VIGENCY con Mobiscroll

			Version 3.3
			Metodología Number con Mobiscroll

			Version 3.2
			Metodología Phone con Mobiscroll

			Version 3.1
			Metodología Phone con Mobiscroll

			Version 3.0
			Se removió la función triggerModelUpdate en favor de v-model nativo.
			
			Version 2.1
			Hotfix en en textarea 

			Version 2.0
			Ahora permite el tipo textarea 
            -->
		<label v-if="label != 'false'" :for="workingId">{{
			computedLabel
		}}</label>
		<textarea
			:id="workingId"
			rows="3"
			v-model="workingValue"
			:placeholder="placeholderAllowed"
			v-if="type == 'textarea'"
			:pattern="patternAllowed"
			:maxlength="maxlengthAllowed"
			:disabled="readonlyAllowed"
			:class="{ mobi: handledWithMobi }"
			@blur="$emit('blur', $event)"
			@focus="$emit('focus', $event)"
			@change="$emit('change', $event)"
			:autocomplete="variableName"
		>
		</textarea>
		<input
			v-else
			:id="workingId"
			v-model="workingValue"
			:placeholder="placeholderAllowed"
			:type="computedType"
			:pattern="patternAllowed"
			:maxlength="maxlengthAllowed"
			:disabled="readonlyAllowed"
			:class="{ mobi: handledWithMobi }"
			@blur="$emit('blur', $event)"
			@focus="$emit('focus', $event)"
			@change="$emit('change', $event)"
			@keyup.enter="$emit('keyupEnter', $event)"
			:autocomplete="variableName"
		/>
		<div
			v-if="type == 'password'"
			@click="showingPass = !showingPass"
			class="pass-hint"
		>
			<span v-if="!showingPass" key="a">
				<i class="fa fa-eye-slash"></i
			></span>
			<span v-else key="b"> <i class="fa fa-eye"></i></span>
		</div>
		<span class="mobi-text-holder" v-if="handledWithMobi">{{
			computedInputText
		}}</span>
	</div>
</template>

<script>
import _ from "lodash";
import mobiscroll from "@mobiscroll/javascript";
import moment from "moment";
moment.locale("es");
// mobiscroll.customTheme('mobiscroll-skycel', 'mobiscroll');
export default {
	data() {
		return {
			workingId: null,
			showingPass: false,
			mobiInstance: null,
			variableName: null,
			workingValue: null,
		};
	},
	props: [
		"type",
		"label",
		"value",
		"id",
		"maxlength",
		"pattern",
		"placeholder",
		"readonly",
		"min",
		"max",
	],
	computed: {
		handledWithMobi() {
			let r = false;
			switch (this.type) {
				case "date":
				case "datetime":
				case "time":
					r = true;
					break;
			}
			return r;
		},
		computedInputText() {
			// DATETIME
			if (this.type == "datetime" && this.value) {
				return moment(this.value).format("dddd D, h:mm A");
			} else if (this.type == "datetime") {
				return this.placeholder ? this.placeholder : "Fecha y hora";
			}

			// DATE
			else if (this.type == "date" && this.value) {
				return moment(this.value).format("dddd D [de] MMMM, YYYY");
			} else if (this.type == "date") {
				return this.placeholder ? this.placeholder : "Fecha";
			}

			// TIME
			else if (this.type == "time" && this.value) {
				return moment(this.value).format("h:mm A");
			} else if (this.type == "time") {
				return this.placeholder ? this.placeholder : "Hora";
			}

			// Default... Not quite used actually
			else {
				return this.value;
			}
		},
		readonlyAllowed() {
			if (!!this.readonly) return this.readonly;
			else return null;
		},
		placeholderAllowed() {
			if (!!this.placeholder) return this.placeholder;
			else return null;
		},
		patternAllowed() {
			if (!!this.pattern) return this.pattern;
			else return null;
		},
		maxlengthAllowed() {
			if (!!this.maxlength) return this.maxlength;
			else return null;
		},
		computedLabel() {
			return !!this.label ? this.label : "Label";
		},
		computedType() {
			return !!this.type &&
				!this.handledWithMobi &&
				this.type == "password" &&
				!this.showingPass
				? this.type
				: "text";
		},
	},
	methods: {
		linkMinAndMaxMobi() {
			if (this.handledWithMobi && !!this.mobiInstance) {
				this.mobiInstance.option({
					min: this.min,
					max: this.max,
				});
			}
		},
		loadMobiDatetime() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.datetime("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
				steps: {
					minute: 15,
					second: 5,
					zeroBased: true,
				},
				dateWheels: "|d|M|YY|",
				timeWheels: "|h|ii|A|",
			});
			// this.mobiInstance.setVal(this.value);
			this.linkMinAndMaxMobi();
		},
		loadMobiDate() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.date("#" + this.workingId, {
				theme: "skycel",
				lang: "es",
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
				steps: {
					minute: 30,
					second: 5,
					zeroBased: true,
				},
				dateWheels: "|DD d|MM|YY|",
			});
			//this.mobiInstance.setVal(this.value);
			this.linkMinAndMaxMobi();
		},
		loadMobiTime() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.time("#" + this.workingId, {
				theme: "skycel",
				lang: "es",
				timeWheels: "|h|ii|A|",
				circular: false,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
				steps: {
					minute: 30,
					second: 5,
					zeroBased: true,
				},
			});
			// this.mobiInstance.setVal(this.value);
			this.linkMinAndMaxMobi();
		},
		loadMobiPhone() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.numpad("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				fill: "ltr",
				template: "(ddd) ddd-dddd",
				showOnFocus: true,
				allowLeadingZero: true,
				placeholder: "-",
				// touchUi: false,
				validate: function (event, inst) {
					if (inst.isVisible()) {
						// Display the formatted value
						inst._markup[0].querySelector(
							".mbsc-np-dsp"
						).innerHTML =
							inst.settings.formatValue(
								event.values,
								event.variables,
								inst
							) || "&nbsp;";
					}
					return {
						// Set the set button invalid until 10 chars filled
						invalid: event.values.length != 10,
					};
				},
				formatValue: function (numbers, variables, inst) {
					// Specify how the value will be formatted
					return (
						(numbers.length > 2 ? "(" : "") +
						numbers.slice(0, 3).join("") +
						(numbers.length > 2 ? ") " : "") +
						numbers.slice(3, 6).join("") +
						(numbers.length > 5 ? "-" : "") +
						numbers.slice(6, 10).join("")
					);
				},
				parseValue: function (v, i) {
					if (v) {
						return v
							.replace("(", "")
							.replace(") ", "")
							.replace("-", "");
					}
				},
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
		loadMobiCard() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.numpad("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				fill: "ltr",
				template: "dddd dddd dddd dddd",
				showOnFocus: true,
				allowLeadingZero: false,
				defaultValue: 1,
				placeholder: "-",
				min: 0,
				// step: 1,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
		loadMobiCode() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.numpad("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				fill: "ltr",
				template: "ddd ddd",
				placeholder: "-",
				showOnFocus: true,
				allowLeadingZero: true,
				// step: 1,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
		loadMobiCardVigency() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.numpad("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				fill: "ltr",
				template: "dd/dd",
				placeholder: "-",
				showOnFocus: true,
				allowLeadingZero: true,
				// step: 1,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
		loadMobiCardCVV() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.numpad("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				fill: "ltr",
				template: "ddd",
				placeholder: "-",
				showOnFocus: true,
				allowLeadingZero: true,
				// step: 1,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
		loadMobiNumber() {
			// Create a datepicker
			this.mobiInstance = mobiscroll.number("#" + this.workingId, {
				display: "bubble",
				theme: "skycel",
				lang: "es",
				// fill: 'ltr',
				// template: 'ddddddddddd',
				showOnFocus: true,
				// allowLeadingZero: false,
				step: 1,
				onSet: (e, inst) => {
					this.$emit("input", inst.getVal());
				},
			});
		},
	},
	watch: {
		workingValue() {
			this.$emit("input", this.workingValue);
		},
		value() {
			if (this.handledWithMobi && !!this.mobiInstance) {
				this.mobiInstance.setVal(this.value);
			} else {
				this.workingValue = this.value;
			}
		},
		min() {
			this.linkMinAndMaxMobi();
		},
		max() {
			this.linkMinAndMaxMobi();
		},
	},
	beforeMount() {
		this.workingValue = _.clone(this.value);
	},
	mounted() {
		if (!this.id) {
			this.workingId = _.uniqueId("ui-input-");
		} else {
			this.workingId = this.id;
		}
		this.variableName = _.uniqueId("___");
		if (this.type == "datetime") setTimeout(this.loadMobiDatetime, 10);
		else if (this.type == "date") setTimeout(this.loadMobiDate, 10);
		else if (this.type == "time") setTimeout(this.loadMobiTime, 10);
		else if (this.type == "phone") setTimeout(this.loadMobiPhone, 10);
		else if (this.type == "number") setTimeout(this.loadMobiNumber, 10);
		else if (this.type == "code") setTimeout(this.loadMobiCode, 10);
		else if (this.type == "card") setTimeout(this.loadMobiCard, 10);
		else if (this.type == "card-vigency")
			setTimeout(this.loadMobiCardVigency, 10);
		else if (this.type == "card-cvv") setTimeout(this.loadMobiCardCVV, 10);
	},
};
</script>

<style lang="less">
	@padding: 0.5rem;

	.mbsc-np-dsp {
		font-size: 1.5em;
		line-height: 1.8em;
	}

	.ui-input {
		display: flex;
		flex-flow: column;
		width: 100%;
		padding: 0 @padding;
		margin: @padding 0;
		position: relative;

		.pass-hint {
			position: absolute;
			right: 1em;
			bottom: 0em;
			padding: 0.9em;
			color: var(--base-color);
			color: var(--primary-dark);
			z-index: 2;
		}

		label {
			flex: 1;
			// padding: 0.5em 1.25em;
			color: var(--gray);
			position: relative;
			font-weight: bold;
			margin: 0;
		}

		input,
		textarea {
			display: block;
			width: 100%;
			height: calc(1.5em + 0.75rem + 2px);
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			&:disabled {
				background-color: #f4f4f4;
			}
		}

		textarea {
			height: 115px;
		}

		span.mobi-text-holder {
			color: var(--gray);
			padding: var(--ui-padding);
			font-weight: var(--ui-font-weight);
			font-style: var(--ui-font-style);
			border-bottom: var(--ui-border);
			font-size: var(--ui-font-size);
			transition: var(--ui-transition);
			position: relative;
			z-index: 1;
		}
	}
</style>