<template>
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="login()">

            <h1 class="mb-3">Iniciar Sesión</h1>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required placeholder="Correo electrónico"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="password" v-model="form.password" required placeholder="Contraseña"></b-form-input>
            </b-form-group>

            <p class="mb-3">
              <router-link to="/recuperar-contrasena">¿Olvidó su contraseña?</router-link>
            </p>

            <b-form-group v-if="!isProcessing">
              <b-button key="proceed" type="submit" class="btn-blue" variant="primary">Acceder</b-button>
            </b-form-group>

            <b-form-group v-else>
              <b-button key="processing" class="btn-blue" variant="primary" disabled>Procesando...</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  data(){
    return{
      isProcessing: false,
      form: {
        email: '',
        password: ''
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    ...mapActions(['tryLogin']),
    async login(){
      this.isProcessing = true;
      let resp = await this.tryLogin(this.form)
      if(resp)
        this.$router.push('/usuario');
      this.isProcessing = false;
      // this.$root.logged = true;
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }

            if(errors.response.data.message && !errors.response.data.errors){
                err = errors.response.data.message;
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  mounted(){
    if(this.$root.logged){
        this.$router.push("/usuario");
    }
  }
}
</script>
