<template>
    <div id="stripe-payment">
        
        <template v-if="!isLoading">
            <h5>
                <img src="@/assets/ic/otros/pago.svg" alt />
                Método de pago
            </h5>

            <div class="padding">
                <form id="payment-form">
                    <div id="payment-element">
                        <!-- Elements will create form elements here -->
                    </div>
                    <div id="error-message">
                        <!-- Display error message to your customers here -->
                    </div>
                </form>
            </div>
        </template>

        <div class="processing-holder" v-if="isProcessing || isLoading">
            <div class="processing">
                <i class="fa fa-cog fa-spin"></i>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import swal from 'sweetalert';
export default {
    name: 'stripePayment',
    props: {
        amount: {
            type: Number,
            default: 0
        },
        urlToFetchClientSecretKey: {
            type: String,
            required: true
        },
        extra: {
            type: [Number, String],
            default: 0
        }
    },
    data: () => {
        return {
            isLoading: false,
            isProcessing: false,
            clientSecret: null,
            publicKey: null,
            emailAddress: null,

            // STRIPE COMPONENTS
            stripe: null,
            elements: null,
        }
    },
    methods: {
        async processPayment(e) {
            if (_.isObject(e) && _.isFunction(e.preventDefault))
                e.preventDefault();

            if (this.isProcessing)
                return false;  // EVITA QUE SE VUELVA A LLAMAR MIENTRAS NO SE HA FINALIZADO LA LLAMADA ANTERIOR.

            this.isProcessing = true;

            try {
                const confirmParams = {
                    // Make sure to change this to your payment completion page
                    // return_url: "http://localhost:8080/checkout.html",
                };

                console.debug(confirmParams)

                let { paymentIntent, error } = await this.stripe.confirmPayment({
                    elements: this.elements,
                    redirect: 'if_required',
                    receipt_email: this.emailAddress,
                    confirmParams
                });

                console.debug(paymentIntent)

                if (_.isObject(paymentIntent) && paymentIntent.status == 'succeeded') {
                    return paymentIntent.id
                }

                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, your customer will be redirected to
                // your `return_url`. For some payment methods like iDEAL, your customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error.type === "card_error" || error.type === "validation_error") {
                    swal(error.message, '', 'warning');
                } else {
                    swal("An unexpected error occurred.", '', 'warning');
                }

            } catch (e) {
                console.error(e)
            }

            this.isProcessing = false;

            return false;

        },
        async fetchSecretKey() {
            const response = await axios.get(this.urlToFetchClientSecretKey);
            if (response.status) {
                this.clientSecret = response.data.clientSecret;
                this.emailAddress = response.data.emailAddress;
                this.publicKey = response.data.publicKey;
            } else {
                swal(response.msg, '', response.class)
            }
        },
        loadStripeElement() {
            this.stripe = Stripe(this.publicKey);

            const options = {
                // mode: 'payment',
                // amount: Math.floor(this.amount * 100),
                // currency: 'mxn',
                clientSecret: this.clientSecret,
                redirect: 'if_required'
            };

            // Set up Stripe.js and Elements to use in checkout form
            this.elements = this.stripe.elements(options);

            // Create and mount the Payment Element
            const paymentElement = this.elements.create('payment', {
                // layout: 'accordion',
                wallets: 'never',
            });
            paymentElement.mount('#payment-element');
        }
    },
    async mounted() {
        this.isLoading = true
        await this.fetchSecretKey()
        this.isLoading = false
        setTimeout(this.loadStripeElement, 50) // MANDA A TIME OUT PARA QUE ESPERE
    }
}

</script>

<style lang="less">
/* En línea #40 | http://localhost:8080/#/cart */

#stripe-payment {
    display: block;
    width: auto;
    height: auto;
    float: none;
    min-height: 14em;
    position: relative;

    .padding {
        padding: 1em;
    }

    .processing-holder {
        background: white;
        opacity: 0.7;
        position: absolute;
        top: -1em;
        left: 0;
        width: 100%;
        height: calc(100% + 1em);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-dimmed);

        .processing {
            font-size: 3.5em;
        }   
    }
}
</style>