<template>
    <div id="notification-hander" v-if="isLogged" no-gutters>
        <div class="btn-group">
            <div v-if="hasOneSignal" class="btn" @click="toggleNotif()" :class="{ 'btn-outline-primary': !isPushActive, 'btn-primary': isPushActive }">
                <i key="icon-bell-active" v-if="isPushActive" class="icon icon-bell-active" aria-hidden></i>
                <i key="icon-bell-inactive" v-else class="icon icon-bell-inactive" aria-hidden></i>
            </div>
            <div class="btn btn-primary" @click="updateNotifiacions">
                <i key="icon-notifications-list" class="icon icon-notifications-list" aria-hidden></i>
            </div>
        </div>

        <b-modal id="myModal" hide-footer v-model="displayList" no-gutters>

            <div slot="modal-title">
                <i key="icon-notifications-list" class="icon icon-notifications-list" aria-hidden></i> Notificaciones
            </div>

            <!-- Modal content goes here -->
            <b-spinner v-if="isProcessing" label="Cargando..." variant="primary"></b-spinner>

            <b-table v-else-if="items.length" hover responsive :items="items" :fields="captions" no-gutters
                @row-clicked="handleRowClick">

                <template #cell(titulo)="data">
                    <strong v-if="data.item.estatus == 'Pendiente'">{{ data.value }}</strong>
                    <span v-else>{{ data.value }}</span>
                    <template v-if="data.item.texto">
                        <br>
                        <small>{{ data.item.texto }}</small>
                    </template>
                    <br><small>{{ data.item.created | humanizar_fecha_completa }}</small>
                </template>

                <template #cell(_)="data">
                    <b-button variant="outline-danger" size="xs" @click="askForDelete(data.item.id)">
                        <i class="icon icon-trash"></i>
                    </b-button>
                </template>

            </b-table>

            <b-jumbotron v-else lead="No hay resultados para mostrar en este momento.">
                <p>Por favor, vuelva más tarde.</p>
            </b-jumbotron>



        </b-modal>

    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'notificationHander',
    computed: {
        ...mapGetters(['isLogged'])
    },
    props: {
        captions: {
            type: [Array, Object],
            default: () => {
                return [
                    { key: 'titulo', label: 'Notificación' },
                    { key: '_' },
                ]
            }
        }
    },
    data: () => {
        return {
            hasOneSignal: false,
            isPushActive: false,
            displayList: false,
            isProcessing: true,
            items: []
        }
    },
    methods: {

        async handleRowClick(item) {
            try {
                if(item.estatus == 'Pendiente')
                    await axios.get('notificaciones/markasreaded/' + item.id)
                let params = JSON.parse(item.jsonData)
                this.$router.push({ name: params.name, params })
                this.displayList = false;
            } catch (e) {
                console.error(e)
            }
            // Perform actions based on the clicked row
            console.log(item);
        },
        toggleNotif() {
            window.OneSignalDeferred = window.OneSignalDeferred || [];
            window.OneSignalDeferred.push((OneSignal) => {
                try {

                    if (this.isPushActive) {
                        OneSignal.User.PushSubscription.optOut()
                    }

                    else if (!this.isPushActive && OneSignal.Notifications.permission) {
                        OneSignal.User.PushSubscription.optIn()
                    }

                    else {
                        OneSignal.Notifications.requestPermission(() => {
                            if (!OneSignal.Notifications.permission)
                                throw 'No se habilitó el permiso...';
                            OneSignal.User.PushSubscription.optIn()
                        })
                    }

                } catch (e) {
                    console.error(e)
                }
            });
        },
        preLoad() {
            window.OneSignalDeferred = window.OneSignalDeferred || [];
            setTimeout(() => {
                window.OneSignalDeferred.push((OneSignal) => {
                    this.hasOneSignal = true
                    this.isPushActive = _.clone(OneSignal.User.PushSubscription.optedIn)

                    console.log('registering event listener')

                    OneSignal.User.PushSubscription.addEventListener("change", (props) => {
                        this.isPushActive = props.current.optedIn
                    });

                });
            }, 2000)
        },

        async updateNotifiacions() {
            this.displayList = true;
            try {
                const response = await axios.get('notificaciones/index')
                if (!response.status)
                    throw 'Error de conexión';
                this.items = response.data.rows
                console.log(response)
            } catch (e) {
                console.log(e)
            }
            this.isProcessing = false;
        },

        async askForDelete( itemId ) {
            try {
                const response = await axios.delete('notificaciones/item/' + itemId)
                if(response.status){
                    this.updateNotifiacions()
                }
            } catch(e){
                console.error(e)
            }
        }

    },
    mounted() {
        this.preLoad()
    }
}
</script>

<style scoped>
#notification-hander {
    margin-top: 0.2em;
}

i {
    font-size: 1.5em;
    position: relative;
    top: 0.1em;
}

.btn:not(:hover) {
    opacity: 0.5;
}

.btn-outline-primary:not(:hover) {
    /* color: var(--orange); */
    color: var(--orange);
    border-color: var(--orange);
}

.btn-outline-primary:hover {
    background-color: var(--orange);
    border-color: var(--orange);
}

.btn-primary {
    background-color: var(--orange);
    border-color: var(--orange);
}

.btn-primary:hover,
.btn-primary:active {
    background-color: var(--orange);
    border-color: var(--orange);
}
</style>