<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-2 page-title">Términos y Condiciones de uso de la aplicación REFAAPP® para usuarios tipo CLIENTES.</h1>

      <h6>1.- Lea y comprenda estos Términos y Condiciones detenidamente antes de acceder o usar los servicios.</h6>
      <p>
        Mediante su acceso y uso de los Servicios usted acuerda vincularse por estos Términos y Condiciones, que establecen una relación entre usted y REFAAPP®. Si usted no acepta estos Términos y Condiciones, no podrá acceder o usar los Servicios. Estos Términos y Condiciones sustituyen expresamente los acuerdos o compromisos previos con usted. En caso de un incumplimiento a los presentes Términos y Condiciones por parte de usted, REFAAPP® podrá denegarle el acceso a su cuenta y/o a los Servicios.<br />
        Por otro lado, REFAAPP® podrá modificar o poner fin a estos Términos y Condiciones o a cualquiera de los Servicios ofrecidos en las aplicaciones de manera total o parcial, mediante notificación previa a través de las aplicaciones y/o correo electrónico.
      </p>

      <br />
      <h6>2.- Servicios que provee la plataforma de REFAAPP®.</h6>
      <p>
        Los Servicios constituyen una plataforma de tecnología que permite a los usuarios de aplicaciones móviles de REFAAPP® pedir y pagar refacciones automotrices de cualquier tipo, incluidos terceros independientes conforme a un acuerdo con REFAAPP®.<br />
        USTED RECONOCE QUE REFAAPP® NO PRESTA SERVICIOS DE REPARACION AUTOMOTRIZ DE NINGUN TPO, Y QUE DICHOS SERVICIOS REPARACION SE PRESTAN POR TERCEROS CONTRATISTAS INDEPENDIENTES, QUE NO ESTÁN EMPLEADOS POR REFAAPP®.
      </p>

      <br />
      <h6>3. Licencia.</h6>
      <p>
        Sujeto al cumplimiento de estos Términos y Condiciones, REFAAPP® le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso de las Aplicaciones en su dispositivo personal solo en relación con su uso de los Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los Servicios, en cada caso solo para su uso personal, no comercial. REFAAPP® y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente.
      </p>

      <br />
      <h6>4.- Restricciones.</h6>
      <p>
        Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de los Servicios; (ii) reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los Servicios, excepto como se permita expresamente por REFAAPP®; (iii) descompilar, realizar ingeniería inversa o desmontar los Servicios, excepto como se permita por la ley aplicable; (iv) enlazar, reflejar o enmarcar cualquier parte de los Servicios; (v) causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de los Servicios o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios; o (vi) intentar obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios o sus sistemas o redes relacionados.
      </p>

      <br />
      <h6>5.- Prestación de los Servicios.</h6>
      <p>
        Usted reconoce que parte de los Servicios podrán ponerse a disposición bajo varias marcas u opciones de pedidos de REFAAPP® asociadas con la venta de refacciones automotrices.
      </p>

      <br />
      <h6>6.- Cuentas de usuario.</h6>
      <p>
        Con el fin de usar la mayor parte de los aspectos de los Servicios, usted debe registrarse y mantener activa una cuenta personal de usuario de los Servicios (“Cuenta”). Para obtener una Cuenta debe tener como mínimo 18 años. <br />
        El registro de la cuenta le requiere que comunique a REFAAPP® determinada información personal, como su nombre, dirección, número de teléfono móvil, así como por lo menos un método de pago válido (bien una tarjeta de crédito o débito). <br />
        Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada. Si no mantiene la información de Cuenta de forma exacta, completa y actualizada, incluso el tener un método de pago inválido o que haya vencido, podrá resultar en su imposibilidad para acceder y utilizar los Servicios o en la resolución por parte de REFAAPP® de estos Términos y Condiciones celebrados con usted. Usted es responsable de toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta. A menos que REFAAPP® permita otra cosa por escrito, usted solo puede poseer una Cuenta.
      </p>

      <br />
      <h6>7.- Requisitos y conducta del usuario.</h6>
      <p>
        El Servicio no está disponible para el uso de personas menores de 18 años. Usted no podrá autorizar a terceros a utilizar su Cuenta, asimismo no podrá permitir a personas menores de 18 años que reciban servicios de venta de comida o bebida de Terceros proveedores, a menos que aquellos sean acompañados por usted. No podrá ceder o transferir de otro modo su Cuenta a cualquier otra persona o entidad. Usted acuerda cumplir con todas las leyes aplicables al utilizar los Servicios y solo podrá utilizar los Servicios con fines legítimos (p. ej., no para el transporte de materiales ilegales o peligrosos).<br />
        En algunos casos, se le podrá requerir que facilite un documento de identificación u otro elemento de verificación de identidad para el acceso o uso de los Servicios, y usted acepta que se le podrá denegar el acceso o uso de los Servicios si se niega a facilitar el documento de identidad o el elemento de verificación de identidad.
      </p>

      <br />
      <h6>8.- Acceso a la red y dispositivos.</h6>
      <p>
        Usted es responsable de obtener el acceso a la red de datos necesario para utilizar los Servicios. Podrán aplicarse las tarifas y tasas de datos y mensajes de su red móvil si usted accede o utiliza los Servicios desde un dispositivo inalámbrico y usted será responsable de dichas tarifas y tasas. Usted es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios y Aplicaciones y cualquier actualización de estos. REFAAPP® no garantiza que los Servicios, o cualquier parte de estos, funcionen en cualquier hardware o dispositivo particular. Además, los Servicios podrán ser objeto de disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.
      </p>

      <br />
      <h6>9. Pago.</h6>
      <p>
        Usted comprende que el uso de los Servicios puede derivar en cargos por los servicios que reciba de REFAAPP®.<br />
        Estos cargos le cobrarán los impuestos aplicables en Mexico.<br />
        El pago de los Cargos e impuestos (cuando resulten aplicables) de dicha manera se considerará como el pago efectuado directamente por usted a REFAAPP®.<br />
        Los cargos pagados por usted son definitivos y no reembolsables, a menos que REFAAPP® determine lo contrario. <br />
        Todos los cargos e impuestos (cuando resulten aplicables) son pagaderos inmediatamente y el pago se facilitará por REFAAPP® utilizando el método de pago preferido indicado en su Cuenta. Si se determina que el método de pago de su Cuenta principal ha caducado, es inválido o de otro modo no sirve para cobrarle, usted acepta que REFAAPP®, como agente de cobro utilice un método de pago secundario en su Cuenta, si estuviera disponible.
      </p>

      <br />
      <h6>10.- Soporte; Renuncias; Limitación de responsabilidad; Indemnidad.</h6>
      <p>
        REFAAPP® brinda soporte los 365 días del año, en caso de que lo necesite. Para recibir soporte, escribir al correo de SOPORTE@REFAAPP®.ONLINE<br />
        Las limitaciones y la renuncia en este apartado no pretenden limitar la responsabilidad o alterar sus derechos como consumidor que no puedan excluirse según la ley aplicable.<br />
        <br />
        Renuncia.<br />
        Los servicios se proporcionan “tal cual” y “como disponibles”. REFAAPP® renuncia a toda declaración y garantía, expresa, implícita o estatutaria, no expresamente establecida en estas condiciones, incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no violación. Además, REFAAPP® no hace declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios, la calidad de la comida, la bebida, la fecha de caducidad de los alimentos o bebidas distribuidos, o cualquier producto que se distribuya a través del uso de los servicios, o que los servicios no serán interrumpidos o estarán libres de errores. REFAAPP® no garantiza la calidad, idoneidad, seguridad o habilidad de los terceros proveedores. Usted acuerda que todo riesgo derivado de su uso de los servicios y cualquier servicio o bien solicitado en relación con aquellos será únicamente suyo, en la máxima medida permitida por la ley aplicable.<br />
        <br />
        Limitación de responsabilidad.<br />
        REFAAPP® no será responsable de daños indirectos, incidentales, especiales, ejemplares, punitivos o emergentes, incluidos el lucro cesante, la pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en relación con, o de otro modo derivados de cualquier uso de los servicios, incluso aunque REFAAPP® haya sido advertido de la posibilidad de dichos daños. REFAAPP® no será responsable de cualquier daño, responsabilidad o pérdida que deriven de: (I) Su uso o dependencia de los servicios o su incapacidad para acceder o utilizar los servicios; o (ii) Cualquier transacción o relación entre usted y cualquier tercero proveedor, aunque REFAAPP® hubiera sido advertido de la posibilidad de dichos daños. REFAAPP® no será responsable del retraso o de la falta de ejecución resultante de causas que vayan más allá del control razonable de REFAAPP®.<br />
        <br />
        Indemnidad.<br />
        Usted acuerda mantener indemnes y responder frente a REFAAPP® y sus consejeros, directores, empleados y agentes por cualquier reclamación, demanda, pérdida, responsabilidad y gasto (incluidos los honorarios de abogados) que deriven de: (i) su uso de los Servicios o servicios o bienes obtenidos a través de su uso de los Servicios; (ii) su incumplimiento o violación de cualquiera de estos Términos y Condiciones; (iii) el uso por parte de REFAAPP® de su Contenido de usuario; o (iv) su infracción de los derechos de cualquier tercero, incluidos Terceros proveedores.
      </p>

      <br />
      <h6>11. Legislación aplicable.</h6>
      <p>
        En caso de que usted tenga algún problema con el uso de los Servicios y/o considere que alguna parte de estos Términos y Condiciones no está siendo observada o cumplida por REFAAPP® y/o el Tercero proveedor, o de presentarse cualquier diferencia entre las Partes derivada o relacionada con estos Términos y Condiciones, usted acepta que el proceso a seguir consiste en comunicar dicha situación al correo de soporte@REFAAPP®.com con el propósito de analizar y resolver el caso concreto.
      </p>

      <br />
      <h6>12. Otras disposiciones</h6>
      <p>
        No podrá ceder ni transferir estos Términos y Condiciones, en todo o en parte, sin el consentimiento previo por escrito de REFAAPP®. Usted da su aprobación a REFAAPP® para ceder o transferir estos Términos y Condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente del capital, del negocio o de los activos de REFAAPP®; o (iii) un sucesor por fusión. No existe entre usted, REFAAPP® o cualquier Tercer proveedor una empresa conjunta o relación de socios, empleo o agencia como resultado del contrato entre usted y REFAAPP® o del uso de los Servicios. 
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/privacidad')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
