
<template>
	<div id="results-page">

		<template v-if="isAfiliado">

			<section class="breadcrumb-section">
				<div class="container oversized-container">
					<span>Home</span>
					<span class="line">/</span>
					<span>Afiliados</span>
					<span class="line">/</span>
					<span>{{seller.nombre}}</span>
				</div>
			</section>

			<div class="container oversized-container seller-info-section">
				<p class="text-not-found">
					<img class="logo" :src="seller.logo" />
				</p>

				<h2 class="mb-1 name">{{seller.nombre}}</h2>

				<div class="box-info text-not-found">
					<p>
						{{seller.descripcion}}
					</p>
				</div>
				<hr>
			</div>

		</template>

		<section class="breadcrumb-section" v-if="isSearchResultsView">
			<div class="container oversized-container">
				<span>Búsqueda</span> <span class="line">/</span> <span>{{ $route.query.keywords }}</span>
			</div>
		</section>

		<section v-if="isCatView" class="breadcrumb-section">
			<div class="container oversized-container">
				<span>Categorías</span> <span class="line">/</span> <span>{{ category.name }}</span>
			</div>
		</section>

		<section class="container oversized-container products-section">
			<div class="row mb-3">
				<div class="col-12 col-page-title">
					<h2 class="title-s2">{{ category.name }}</h2>
				</div>
			</div>

			<div class="row">
				<!-- Mobile filters -->
				<div class="col-12 col-filters-mobile">
					<div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false">
					</div>
					<a class="btn-filters" @click="showFilters = !showFilters">
						<i class="fas fa-filter"></i>
						<span>Ver filtros</span>
					</a>
				</div>
				<!--  -->

				<!-- Col filters -->
				<div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }" v-if="brandsInCurrentConfig.length">
					<h4 class="_title">
						<span>Filtros de Búsqueda</span>
						<a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
					</h4>

					<b-form class="filters-container">

						<!-- Marca -->
						<div class="box-filters">

							<a class="btn-action">
								<i class="sy">-</i> 
								Filtrar por marcas
							</a>

							<b-form-group class="box-filters-group">
								<b-form-checkbox v-model="filter_brand" :value="i.id" v-for="(i, fbrInx) in brandsInCurrentConfig" :key="'fbrInx-' + fbrInx" >
									{{ i.name }}
								</b-form-checkbox>
							</b-form-group>

						</div>

					</b-form>
				</div>
				<!--  -->

				<!-- Results -->
				<div class="col-lg">
					<div class="row sample-products-wrapper xxl-4e">
						<product-item class="col-sm-6 col-lg-4" v-for="product in products" :productId="product" :key="'pID-' + product" />
						<h5 v-if="!products.length" class="not-found">
							No se encontraron resultados
						</h5>
					</div>
					<div class="row fix-nav-margin" v-if="products.length">
						<navigation class="col-lg-12" :perPage="perPage" :rows="productsNonPaginated.length" />
					</div>
				</div>
				
			</div>
		</section>

	</div>
</template>

<script>
	import _ from "lodash";
	import { mapActions, mapGetters } from "vuex";
	import ProductItem from './productItem.vue';
	import Navigation from './navigation.vue';
	export default {
		components: { ProductItem, Navigation },

		data() {
			return {
				perPage: (4 * 3),
				isLoading: true,
				showFilters: false,
				listType: 'cat',
				cat: {
					products: []
				},
				seller: {},
				productsOfSeller: [],
				filter_brand: []

			};
		},

		computed: {

			...mapGetters({
				catalogProducts: "catalogProducts",
				catalogPlainCats: "catalogPlainCats",
				catalogGetBrands: "catalogGetBrands"
			}),

			productsComplete(){
				let productsComplete = [];
				_.forEach(this.productsNonPaginated, pId => {
					let toPush = _.find(this.catalogProducts, {productos_id: pId})
					if(!!toPush)
						productsComplete.push(toPush);
				});
				return productsComplete;
			},

			productsMarcas(){
				let marcasComplete = [];
				_.forEach(this.productsComplete, prod => {
					marcasComplete.push(prod.marca)
				})
				return _.uniq(marcasComplete)
			},

			brandsInCurrentConfig (){
				return _.filter(this.catalogGetBrands, brand => {
					return (this.productsMarcas.indexOf(brand.id) != -1);
				})
			},

			products() {
				return _.takeRight(_.take(this.productsNonPaginated, (this.perPage * parseInt(this.$route.params.page))), this.perPage)
			},

			productsNonPaginated(){
				return _.filter(this.allProductsIDs, item => {
					let product = _.find(this.catalogProducts, {productos_id: item})
					return (this.filter_brand.length == 0 || this.filter_brand.indexOf(product.marca) != -1)
				})
			},
			
			allProductsIDs() {
				if (this.isCatView) return this.cat.products;
				else if (this.isAfiliado) return this.productsOfSeller;

				if(this.$route.query.keywords == undefined)
					return [];

				let products = [],
					termSplitted = this.$route.query.keywords.split(/[ ]|\,/);

				_.map(this.catalogProducts, (item) => {
					let matchesRequired = 0;
					_.map(termSplitted, (term) => {
						let elTerminoRegEXP = new RegExp(
							this.fixLatin(
								_.escapeRegExp(this.fixCommonNaming(term))
							),
							"i"
						);
						if (
							elTerminoRegEXP.test(item.marca) ||
							elTerminoRegEXP.test(item.condicion) ||
							elTerminoRegEXP.test(item.descripcion) ||
							elTerminoRegEXP.test(item.descripcion_corta) ||
							elTerminoRegEXP.test(item.sku) ||
							elTerminoRegEXP.test(item.nombre) ||
							elTerminoRegEXP.test(item.lado) ||
							elTerminoRegEXP.test(item.numero_parte)
						)
							matchesRequired++;
					});
					if (matchesRequired == termSplitted.length)
						products.push(item.productos_id);
				});

				return products;
			},
			
			category() {
				if(this.isCatView){
					let catFound = _.find(this.catalogPlainCats, {
						id: this.$route.params.id,
					});
					return {
						name: catFound.categoria_nombre,
						imagenes_id_carrusel: catFound.imagenes_id_carrusel,
						imagenes_id_portada: catFound.imagenes_id_portada,
						id: this.$route.params.id,
					};
				} else {
					return {
						name: null,
						imagenes_id_carrusel: null,
						imagenes_id_portada: null,
						id: null
					};
				}
			},
			
			isAfiliado(){
				return (this.$route.name == 'AfiliadoShowroom');
			},
			
			isCatView(){
				return (this.$route.name == 'isCat');
			},
			
			isSearchResultsView(){
				return (this.$route.name == 'isSearch');
			}
		},

		methods: {

			...mapActions(["catalogGetCatByID", "catalogGetSellerByID", "cataIntemsGetBySellerID"]),

			async loadSeller(){
				this.isLoading = true
				try {
					this.seller = await this.catalogGetSellerByID(this.$route.params.id);
					this.productsOfSeller = _.map(await this.cataIntemsGetBySellerID(this.$route.params.id), item => {
						item.name = item.nombre
						item.price = item.precio
						item.stock = item.cantidad
						item.img = item.gallery[0]
						return item.productos_id // return item
					})
				} catch(e){
					console.error(e)
				}
				this.isLoading = false
			},

			fixLatin(term) {
				let termRet = term.replace(/a|á/gi, "[a|á]");
				termRet = termRet.replace(/e|é/gi, "[e|é]");
				termRet = termRet.replace(/i|í/gi, "[i|í]");
				termRet = termRet.replace(/o|ó/gi, "[o|ó]");
				termRet = termRet.replace(/u|ú|ü/gi, "[u|ú|ü]");
				termRet = termRet.replace(/ñ/gi, "[n|ñ]");
				return termRet;
			},

			fixCommonNaming(naming) {
				let returning = null;
				switch (naming) {
					case "cigüeñal":
						returning = "ciguenal";
						break;

					default:
						returning =
							typeof naming == "string"
								? naming.toLowerCase()
								: naming;
						break;
				}
				return returning;
			},

			reviewRoute() {
				if(!this.$route.params.page){
					let route = {
						name: _.cloneDeep(this.$route.name),
						params: _.cloneDeep(this.$route.params)
					}
					route.params.page = '1';
					this.$router.replace(route);
				} else if (this.isCatView) {
					this.catalogGetCatByID(this.$route.params.id).then((cat) => {
						this.cat = cat;
					});
					this.listType = "cat";
				} else if (this.isSearchResultsView) {
					this.listType = "search";
				} else if (this.isAfiliado){
					this.listType = "afiliado"
					this.loadSeller()
				}
			}

		},

		mounted() {
			this.reviewRoute();
		},

		watch: {
			"$route.params.page"(){
				this.reviewRoute()
			},
			"$route"(){
				this.reviewRoute()
			},
			"category.id"() {
				this.reviewRoute();
			}
		}

	};
</script>

<style lang="less" scoped>
	.logo {
		width: 8em;
		height: 8em;
		object-fit: cover;
		border-radius: 1em;
	}
	.fix-nav-margin {
		margin-top: 01.0em !important;
		// background-color: red;
	}
	.not-found {
		width: 100%;
		text-align: center;
		padding: 70px 10px;
		background-color: #ececec;
		border-radius: 10px;
		margin: 64px;
	}
</style>