<template>
	<!-- 
		V.2.2
		Actualización de select con array simple

		V.2.1
		Agregado Outline 0 y parámetro propiedad de filtrado

		V.2.0
		Opcionalmente agrega en automático una opción vacía para resolver los tipos null en value 
		@author Daniel Lepe 2021
	-->
	<div class="ui-select">
		<label class="ui-select-label" :for="id">
			{{ label }}
			<i class="fa fa-chevron-down"></i>
		</label>
		<select class="input__select" type="text" :id="id" />
	</div>
</template>

<script>
	import _ from "lodash";
	import mobiscroll from "@mobiscroll/javascript";
	// import backIcon from '@/assets/svg/chevrons/left-chevron.svg'

	export default {
		inheritAttrs: false,
		data() {
			return {
				selectedInstance: null,
				selected: null,
				valueUnTouched: null,
				id: null,
				// backIcon,
			};
		},
		// props: ["label", "value", "options", "placeholder", "group", "filter"],
		props: {
			label: {
				type: String,
				default: 'Ui Select',
				required: false
			},
			value: {
				type: [String, Number],
				default: null,
				required: false
			},
			options: {
				type: Array,
				required: true
			},
			placeholder: {
				type: String, 
				rquired: false
			},
			group: {
				type: [String, Number, Boolean], 
				default: false,
				rquired: false
			},
			filter: {
				type: [String, Number, Boolean], 
				default: false,
				rquired: false
			}
		},
		computed: {
			theme(){
				try {
					// dark-mode media query matched or not
					if(typeof window.matchMedia != "function")
						throw 'Imposible detectactar modo oscuro';

					let darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

					if(darkMode){
						return 'ios-dark'
					}else{
						return 'ios'
					}
				} catch(e){
					console.error(e)
				}
			},
			cOptions() {
				let text = "Selecciona una opción",
					opt = [],
					fullOptions = _.cloneDeep(this.options)

				if(this.value == null || this.value == ''){
					opt.push({ value: null, text, label: text, disabled: true })
				}

				// EN CASO DE QUE VENGAN EN JSON
				if (typeof fullOptions == "string")
					fullOptions = JSON.parse(fullOptions);

				_.forEach(fullOptions, (item) => {
					if(typeof item == 'object'){
						opt.push(item);
					}
					else if(typeof item == 'string'){
						opt.push({ value: item, text: item, label: item });
					}
				});

				return opt;
			},
		},
		methods: {
			updateFromOutside() {
				let updatePrefetch = _.find(this.cOptions, { value: this.value });

				if (!!updatePrefetch) {
					this.selected = updatePrefetch.value;
				} else {
					this.selected = null;
					this.selectedInstance.clear();
				}

				this.selectedInstance.refresh(this.cOptions);
			},
			loadUpSelectInput() {
				this.selectedInstance = mobiscroll.select("#" + this.id, {
                    display: 'bubble',
                    theme: 'skycel',
                    lang: 'es',
					filter: (!!this.filter && this.filter != 'false'),
					group: this.group,
					dataText: "label",
					dataValue: "value",
					dataGroup: "group",
					inputClass: "input__select",
					filterPlaceholderText: "Escribe para filtrar",
					headerText: this.label,
					onSet: (ev, inst) => {
						this.selected = inst.getVal();
						this.$emit("input", this.selected);
					},
				});

				if (typeof this.selectedInstance.setVal == "function") {
					this.selectedInstance.setVal(this.value, true);
					this.selectedInstance.option({
						data: _.cloneDeep(this.cOptions),
					});
				}
			}
		},
		beforeDestroy() {
			if (typeof this.selectedInstance.destroy == "function")
				this.selectedInstance.destroy();
		},
		watch: {
			value() {
				this.updateFromOutside();
			},
			options() {
				this.updateFromOutside();
			}
		},
		beforeMount() {
			this.valueUnTouched = this.value;
			this.selected = this.value;
			this.id = _.uniqueId("form-select-");
		},
		mounted() {
			this.loadUpSelectInput();
		}
	};
</script>

<style lang="less">
.ui-select {
	padding: 0 1em;
	margin: 1em 0;
	position: relative;
    outline: none;
	.ui-select-label {
		margin: 0.6em 0em;
		font-size: 1em;
		font-weight: normal;
		font-family: var(--ui-font-family) !important;
		color: var(--base-color);
		display: block;
		
		IMG {
			position: absolute;
			right: 1.5em;
			top: 2.75em;
			z-index: 100;
			transform: rotate(-90deg);
			width: 2em;
			height: 2em;
		}

		.fa  {
			position: absolute;
			right: 1.4em;
			bottom: 0.6em;
			font-size: 1.3rem;
		}

	}
	.input__select {
		border: none;
		border-radius: calc(var(--ui-font-size) / 2);
		// border-bottom: var(--ui-border);
		// border-color: var(--primary);
		display: block;
		width: 100%;
		padding: var(--ui-padding);
		font-weight: var(--ui-font-weight);
		font-style: var(--ui-font-style);
		font-family: var(--ui-font-family);
		font-size: var(--ui-font-size);
		color: #484848;
        outline: none;
		background-color: var(--lightgray);
		color: var(--primary-dark);
	}
}
</style>