<template>
	<div id="user-access-page" class="placed-backg">
		<div class="container">
			<div class="row mx-0 flex-center-xy page-size">
				<div class="form-container w-100">
					<div class="box-color">
						<i><u class="fas fa-user"></u></i>
					</div>

					<b-form class="form" @submit.prevent="register">
						<h1 class="mb-3">Registrar mi negocio <span v-if="debug">DEV</span></h1>

						<h5 class="mb-2 f-w-700 txt-green">TUS DATOS</h5>

						<div class="row">
							<template v-for="item in archetype">
								<div class="col-lg-6" :key="item.key" v-if="['hidden', 'imagen'].indexOf(item.type) == -1">
									<b-form-group class="custom-group-s-1" :label="item.label" > 
										<b-form-textarea v-if="item.type == 'textarea'" v-model="form[item.key]" />
										<b-form-input v-else :type="item.type" v-model="form[item.key]"/>
									</b-form-group>
								</div>
							</template>
						</div>

						<!-- <h5 class="mt-3 mb-2 f-w-700 txt-green">DETALLES</h5>

						<div class="row">
							<div class="col-lg-6">
								<b-form-group class="custom-group-s-1" label="Logo del negocio">
									<b-form-file plain v-model="form.empresaLogo"/>
								</b-form-group>
							</div>

							<div class="col-lg-6">
								<b-form-group class="custom-group-s-1" label="Nombre">
									<b-form-input type="text" v-model="form.empresaNombre" required placeholder="">
								</b-form-group>
							</div>

							<div class="col-lg-12">
								<b-form-group
									class="custom-group-s-1"
									label="Acerca de"
								>
									<b-form-textarea
										v-model="form.empresaInfo"
										placeholder="Escribe acerca de tu empresa"
										rows="4"
										max-rows="4"
									></b-form-textarea>
								</b-form-group>
							</div>
						</div> -->

						<div id="AcceptanceToC" class="d-inline-block mb-3">
							<b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required >
								Acepto los términos y condiciones
							</b-form-checkbox>
						</div>

						<p id="LoginLink" class="mb-1">
							<router-link to="/login">
							¿Ya tiene una cuenta?
							</router-link>
						</p>

						<b-form-group class="mt-3">
							<b-button type="submit" class="btn-blue" variant="primary" v-if="!isProcessing">
								Crear una cuenta
							</b-button >
							<b-button disabled class="btn-blue" variant="primary" v-else>
								Procesando...
							</b-button >
						</b-form-group>

						<div class="debug" v-if="debug"><pre>{{form}}</pre></div>
					</b-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import {archetype, formFromArchetype, signupAsBusiness} from './business/archetype'
export default {
	props: {
		debug: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			archetype: _.cloneDeep(archetype),
			isProcessing: false,
			form: formFromArchetype()
		};
	},

	methods: {
		async register(evt) {
			if(!this.isProcessing){
				try {
					this.isProcessing = true;
					let response = await signupAsBusiness(this.form)
					if(response)
						this.$router.replace('/')
					this.isProcessing = false;
				} catch(e){
					console.error(e)
				}
			}
		},
	},

	mounted() {
	}

};
</script>
