<template>
	<div id="cart-page" class="d-flex account-page">
		<div class="container main-box">
			<div class="row">
				<div class="col-12 px-2">
					<h1 class="mb-3 page-title">Mis favoritos</h1>
				</div>

				<div class="col-lg px-2 col-menu">
					<div class="white-box">
						<div class="box-menu py-2">
							<p class="item">
								<router-link class="btn-menu" to="/cart"> <i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
							</p>
							<p class="item"> 
								<router-link class="btn-menu" to="/mis-favoritos" > <i class="fas fa-heart"></i> Mis favoritos</router-link>
							</p>
						</div>
					</div>
				</div>

				<div class="col-lg px-2 col-cart-data">
					<!-- <div class="white-box"> -->
					<div class="white-box">
						<!-- Paso 1 (Lista de productos agregados a carrito) -->
						<div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago'">
							<div class="col-12 px-0">
								<h5>Productos agregados a favoritos</h5>

								<h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!favProducts.length">
									<i class="fas fa-shopping-basket"></i>
									Ningun producto ha sido agregado al carrito
								</h6>

							</div>

							<div class="row row-products" v-for="product in favProducts" :key="'pFav-'+product.productos_id">

								<div class="col col-image">
									<img :src="product.gallery[0]" />
								</div>

								<div class="col col-info">
									<h5 class="txt-name">
										<router-link :to=" '/producto/' + $root._converToURL( product.nombre, product.productos_id )">
											{{ product.nombre }}
										</router-link>
									</h5>

									<hr class="mt-0 mb-1" />

									<a class="link-sm text-danger nowarp" @click="toggleFav(product.productos_id)">
										<i class="far fa-trash-alt"></i> 
										Quitar de favoritos
									</a>
								</div>

								<div class="col text-center col-price">
									<p>
										{{ favProducts.precio | currency }}
									</p>
								</div>
							</div>
						</div>
						<!-- paso 1, Fin -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters(["favGetItems", "catalogProducts"]),
		favProducts() {
			return _.filter(this.catalogProducts, (item) => {
				return this.favGetItems.indexOf(item.productos_id) != -1;
			});
		},
	},
	methods: {
		...mapActions(['favToggleProductID']),
		async toggleFav(pId){
			await this.favToggleProductID(pId);
		}
	}
};
</script>
