<template>
  <div>
    <b-form @submit.prevent="onSubmit()" v-if="!!form">

      <!-- <h6>Mis datos</h6> -->

      <!-- <hr class="c-hr" /> -->

      <b-form-group class="custom-group-s-1" label="Nombre">
        <b-form-input type="text" v-model="form.nombre" required></b-form-input>
      </b-form-group>
  
      <b-form-group class="custom-group-s-1" label="Teléfono">
        <b-form-input type="text" v-model="form.telefono" required></b-form-input>
      </b-form-group>

      <b-form-group class="custom-group-s-1" label="Email">
        <b-form-input type="email" v-model="form.email" readonly></b-form-input>
      </b-form-group>

      <b-form-group class="custom-group-s-1" label="Cambiar la contraseña">
        <b-form-input type="password" v-model="form.password" id="pass-change" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group v-if="form.password.length" class="custom-group-s-1" label="Confirma la nueva contraseña">
        <b-form-input type="password" v-model="form.password_confirm" id="pass-change-confirm" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-group v-if="!isProcessing">
        <b-button key="proceed" type="submit" class="btn-blue" variant="primary">Actualizar mis datos</b-button>
      </b-form-group>

      <b-form-group v-else>
        <b-button key="processing" class="btn-blue" variant="primary" disabled>Procesando...</b-button>
      </b-form-group>

    </b-form>

  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['accountGetUser'])
  },
  data: () => {
    return {
      isProcessing: false,
      form: {
          nombre: '',
					telefono: '',
					email: '',
					password: '',
					password_confirm: ''
      }
    }
  },
  methods: {
    ...mapActions(['tryUpdate']),
    async onSubmit(){
      this.isProcessing = true
      await this.tryUpdate(this.form)
      this.isProcessing = false
    }
  },
  mounted(){
    this.form = _.merge(this.form, _.clone(this.accountGetUser))
    // this.form.password = '';
    // this.form.password = '';
  }
}
</script>
