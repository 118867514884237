// IMPORTS
import _ from 'lodash'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript'

const update = 'paymentmethods/fetch'

const state = {
	paymentmehtodsList: []
}

const getters = {
    paymentmehtodsGetList: (state) => {
        return state.paymentmehtodsList
    }
}

const mutations = {
	paymentmehtodsSetList: (state, payload) => {
        state.paymentmehtodsList = payload
    }
}

const actions = {
	paymentmehtodsUpdate: ({commit, getters}) => {
        return new Promise((success) => {
            let data2send = { token: getters.accountGetToken }
            if(getters.paymentmehtodsGetList.length){
                success()
            } else {
                axios.post(update, data2send).then(response => {
                    commit('paymentmehtodsSetList', response.data)
                    success()
                }).catch((e) => {
                    console.error(e)
                    mobiscroll.snackbar({
                        display: 'top',
                        message: 'Ocurrió un error al tratar de recuperar los métodos de pago',
                        color: 'warning'
                    })
                })
            }
        })
    }
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
