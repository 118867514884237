<template>
    <div class="modal-vue" :id="id" v-if="value">
        <div class="modal-vue-backdrop animated fadeIn"> </div>
        <div class="modal-vue-frame animated fadeInDown delay-1s">
            <div class="modal-vue-header" v-if="!!header && header && header != 'false'"> 
                <div class="row">
                    <div class="col padded">
                        <slot name="header"/>
                    </div>
                    <div class="col col-tight padded close" @click="closeModal">
                        <i class="fa fa-times"></i>
                    </div>
                </div>
            </div>
            <div class="modal-vue-body"> 
                <slot name="default"/>
            </div>
            <div class="modal-vue-footer" v-if="!!footer && footer && footer != 'false'"> 
                <slot name="footer">
                    <div class="block">
                        <button @click="closeModal">Cerrar</button>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            value: {
                type: [String, Boolean, Number]
            },
            footer: {
                type: [String, Boolean, Number],
                default: true
            },
            header: {
                type: [String, Boolean, Number],
                default: true
            },
            id: {
                type: String
            }
        },
        methods: {
            closeModal(){
                this.$emit('input', false)
            }
        }
    }
</script>

<style lang="less">
    @primary: #659d31;
    @primary-dimmed: #667c52;
	@secondary: #df8436;
	@error: #e83b5b;
	@dark: fade(darken(@primary, 35%), 85%);
	@dimmed: rgb(210, 210, 210);
	@dimmed-highlited: rgb(225, 225, 225);

	:root {

		--primary: @primary;
		--primary-light: lighten(@primary, 10%);
		--primary-dark: darken(@primary, 15%);
		--primary-transparent: fade(darken(@primary, 15%), 15%);

		--secondary: @secondary;
		--secondary-light: lighten(@secondary, 15%);
		--secondary-dark: darken(@secondary, 15%);
		// --secondary-light: fade(darken(@secondary, 15%), 15%);

		--error: @error;
		--error-light: lighten(@error, 15%);
		--error-dark: darken(@error, 15%);
		--error-transparent: fade(darken(@error, 15%), 15%);

		--dark: @dark;
		--error-light: lighten(@dark, 15%);
		--error-dark: darken(@dark, 15%);
		--error-transparent: fade(darken(@dark, 15%), 15%);

		--danger: @error;
		--danger-light: lighten(@error, 15%);
		--danger-dark: darken(@error, 14%);

		--padding: 1em;
		--small-padding: 0.5em;
		--subtil: @dimmed;
		--dimmed: @dimmed;
		--dimmed-light: lighten(@dimmed, 10%);
		--dimmed-dark: darken(@dimmed, 10%);
		--white: #ffffff;
		--lightgray: hsla(0, 0%, 94%, 0.475);
		--min-height-calc: calc(100vh - 6.4em);
		--border-style: solid 0.08rem var(--subtil);
		--border-radious: 0.5rem;

		--ui-padding: 0.5em;
		--ui-border-special: solid 1px var(--primary);

		--black: @dark;
		--black-dimmed: fade(@dark, 50%);
		--primary-dimmed: rgb(139, 139, 139);
		--dark: var(--black);
		--iphone-xr-fix: calc(env(safe-area-inset-bottom) / 3);
		
		/* UI Helpers */
		--ui-border-size: 0.15em;
		--ui-border: var(--ui-border-size) solid fade(@dark, 20%);
		--ui-gap: 0.75em;
		--ui-padding: 0.75em 1.25em 0.9em 1.25em;
		--ui-padding-small: 0.5em;
		--ui-font-weight: normal;
		--ui-font-style: italic;
		--ui-radius: 0.5em;
		--ui-font-size: 0.90rem;
		--ui-border-radius: 3em;
		--ui-transition: ease all 300ms;
		--ui-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
		--ui-box-shadow: var(--ui-shadow);
		--ui-avatar-length: 7.5rem;
	}
	
    .text {
        &-white {
            color: var(--white);
        }
        &-primary {
            color: var(--primary);
        }
        &-secondary {
            color: var(--secondary);
        }
        &-danger {
            color: var(--danger);
        }
        &-black {
            color: var(--black);
        }
        &-dimmed {
            color: var(--dimmed);
        }
        &-small {
            font-size: 0.8em;
        }
    }

    .modal-vue {
        @fixedMargin: 6em;
        z-index: 1001;
        overflow-y: auto;
        
        
        &, &-backdrop {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        &-backdrop {
            background-color: rgba(0, 0, 0, 0.342);
            z-index: -1;
            backdrop-filter: blur(10px);
        }
        &-frame {
            z-index: 1;
            margin-top: @fixedMargin;
            margin-bottom: @fixedMargin;
            width: 80vw;
            max-width: 720px;
            margin-left: auto;
            margin-right: auto;
            background-color: var(--white);
            border-radius: var(--ui-radius);
            box-shadow: var(--ui-shadow);
        }
        .row {
            display: flex;
            flex-flow: row;
            grid: 1em;

            &.row-end {
                align-items: end;
                justify-content: end;
            }

            &.row-centered-v {
                align-items: center;
                justify-content: center;
            }

            &>.col {
                flex: 1;

                &.col-tight {
                    flex: 0 1 3em;
                }
            }
        }
        &-header {
            text-align: center;
            border-bottom: var(--ui-border);
            color: var(--primary);
            font-family: "Open Sans", sans-serif;
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: uppercase;
            & > DIV {
                padding: 1.2rem;
            }
            .close {
                cursor: pointer;
            }
        }
        &-body {
            padding: 1em 0;  
        }
        &.no-body {
            .modal-vue-body {
                padding: 0;  
            }
        }
        &-footer {
            text-align: right;
            button {
                position: relative;
                z-index: 1;
                padding: 0.8em 1.5em;
                border-radius: 0.3em;
                border: none;
                overflow: clip;
                color: #ffffff;
                font-size: 1.1rem;
                font-weight: 600;

                text-align: center;
                background: var(--primary);
                &:before {
                    transition: all 250ms ease-in-out;
                    z-index: -1;
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background: linear-gradient(50deg, var(--primary) 0%, var(--primary-light) 100%);
                }
                &:hover {
                    background-color: var(--primary);
                }
                &.dimmed {
                    background: #d7d6d6;
                    color: #454545;
                    &:before {
                        background: linear-gradient(50deg, #eeeeee 0%, #d7d6d6 100%);
                    }
                    &:hover {
                        background-color: @dimmed;
                    }
                }
                &:hover:before {
                    width: 100%;
                    max-width: 10em;
                }
            }
            
            border-top: var(--ui-border);
            padding: calc(var(--ui-font-size) / 1.618);
            padding: 0.5em 1.5em;
            
            .button-holder {
                // margin: 0 !important;
                // padding: 0 !important;
            }
        }
        h5, p {
            padding: 12px;
        }
    }

    .swal-button {
        background: @primary-dimmed;
        &:not([disabled]):hover, &:active {
            background: @primary;
        }
        &--cancel {
            background: @dimmed;
            &:not([disabled]):hover, &:active {
                background: @dimmed-highlited;
            }
        }
    }

    .btn-primary {
        background-color: var(--primary);
        border-color: var(--primary);
        &:not(disabled):hover, &:active , &:focus {
            background-color: var(--primary-dark);
            border-color: var(--primary-dark);
        }
    }

    .btn-primary:not(:disabled):not(.disabled):active, 
    .btn-primary:not(:disabled):not(.disabled).active, 
    .show > .btn-primary.dropdown-toggle {
        background-color: var(--primary-dark);
        border-color: var(--primary-dark);
    }


</style>