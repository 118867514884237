<template>
    <router-link class="box-seller" to="/afiliados/1">
        <div class="col col-logo">
            <div class="image" :style="{ backgroundImage: 'url(' + seller.logo + ')'}">
                <img src="public/images/shared/empty.png" />
            </div>
        </div>

        <div class="col col-name">
            <h5>{{seller.nombre}}</h5>
        </div>
    </router-link>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'sellerBelt',
        props: {
            vendedoresId: {
                type: [String, Number],
                required: true
            }
        },
        data(){
            return {
                seller: {}
            }
        },
        methods: {
            ...mapActions(['catalogGetSellerByID']),
            loadSeller() {
                this.catalogGetSellerByID(this.vendedoresId).then(seller => {
                    this.seller = seller
                    console.log("seller", this.seller)
                }).catch(e => {
                    console.error(e)
                })
            }
        },
        mounted(){
            this.loadSeller()
        }
    }
</script>