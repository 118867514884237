<template>
	<!--
		ClientAddLocation

		V.1.1

		Permite la búsqueda via autocomplete nativo de google de domicilios y permite actualizar el input del domicilio localizado con solo arrastrar el mapa.

		Dependencias:
		https://www.npmjs.com/package/vue-google-autocomplete
		https://www.npmjs.com/package/vuejs-google-maps

		La configuración de la KEY debe ser cargada en el registro:

		Vue.use(VueGoogleMap, {
			load: {
				apiKey: 'your-api-key',
				libraries: [/* rest of libraries */]
			}
		})

		-->
	<div id="ClientAddLocation">
		<div id="autocomplete">
			<vue-google-autocomplete
				id="autocomplete-input"
                ref="autocompleteInput"
				class="form-control"
				:country="['mx']"
				v-model="workingValue"
				placeholder="Escribe tu domicilio"
				@placechanged="getAddressData" />
		</div>
		
		<div id="google-map-holder">
			<google-map id="google-map" ref="mainGoogleMap"
				@dragend="mapChanged($event)"
				:options="mapOptions">
				<!-- <google-map-infowindow></google-map-infowindow> -->
			</google-map>
			<div id="pin">
				<img :src="MapPin" alt />
			</div>
		</div>
		<lock-button id="locationButton"  label="Establecer mi ubicación" class="dimmed" @click="tryToLocate()" :is-processing="isGPSProcesing"></lock-button>
	</div>

</template>

<script>
import _ from "lodash";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

import LockButton from '@@/shared/LockButton.vue';

let MapPin = '/public/images/app-imported/MapPin.svg'

// import MapPin from "@/assets/MapPin.png"

let mapOptions = {disableDefaultUI: true, draggable: true, center: { lat: 20.74, lng: -103.41}}

let archetype = {
	// OJECTO DE DOMICILIO (INCASE OF)
	address: {},

	// PARÁMETROS EXTRAÍBLES
	domicilio: null,
	colonia: null,
	estado: null,
	lat: null,
	lng: null,

    // AR
    ar: null,     // CONTIENE LA CONFIGURACIÓN COMPLETA DEL AUTOCOMPLETE

	// REQUERIDOS POR EL USUARIO
	numero: null,
	comentarios_domicilio: null,
};

export default {
	name: "ClientAddLocation",
    props: {
        value: {
            type: [Object, String],
            default: () => {
                return _.clone(archetype)
            }
        },
        mode: {
            type: String,
            default: 'fullObject' // [fullObject|addressText]
        }
    },

	data: () => {
		return {
			workingValue: null,
			isProcessing: false,
			isGeoCoding: false,
			autocomplete: null,
			places: null,
			map: null,
			interval: null,
			isGPSProcesing: false,
			form: _.clone(archetype),
            MapPin,
			mapOptions
		};
	},

	components: {
		VueGoogleAutocomplete,
		LockButton
	},

	methods: {
		async refreshAddressFromLatLng(data2geocode){
			let latlng = `${data2geocode.lat},${data2geocode.lng}`,
				YOUR_API_KEY = `AIzaSyBivXA5Zyz_oV_FNga56guJ4YuZGNfNz9o`;

			let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${YOUR_API_KEY}&latlng=${latlng}`);
			if(response.status == 200){
				// console.log('json', await response.json())
				let parsedResponse = await response.json();
				// console.log("results", results)
				if(typeof parsedResponse == 'object' && parsedResponse.results.length && typeof parsedResponse.results[0].formatted_address == 'string'){
					this.$refs.autocompleteInput.update(parsedResponse.results[0].formatted_address)
					this.$emit('input', parsedResponse.results[0].formatted_address)
				}
			}
		},
		async tryToLocate(){
			this.isGPSProcesing = true
			navigator.geolocation.getCurrentPosition(async (ftchedCoords, timestamp)=>{
				this.zoom = 16
				let data2geocode = {
					lat: ftchedCoords.coords.latitude,
					lng: ftchedCoords.coords.longitude
				}
				await this.refreshAddressFromLatLng(data2geocode)
				this.mapOptions.zoom  		= 18
				this.mapOptions.center.lat 	= data2geocode.lat
				this.mapOptions.center.lng 	= data2geocode.lng
				this.isGPSProcesing 		= false  
				this.$emit('locationRequested', data2geocode)
			}, (d, a)=>{
				console.log("datas", d, a);        
				this.isGPSProcesing = false  
			})
		},
		async mapChanged($event) {
			this.isGeoCoding = true
			try {
				let data2geocode = {
					lat: this.$refs.mainGoogleMap.lastCenter.lat(),
					lng: this.$refs.mainGoogleMap.lastCenter.lng(),
				}
				this.refreshAddressFromLatLng(data2geocode)
				this.$emit('locationRequested', data2geocode)
			} catch (error) {
				console.error(error)
			}
		},
		async getAddressData(ar) {
			try {
				this.form.domicilio = ar.route;
				this.form.colonia = ar.locality;
				this.form.estado = ar.administrative_area_level_1;
				this.form.numero = ar.street_number;
				this.form.lat = ar.latitude;
				this.form.lng = ar.longitude;
				this.form.ar = _.clone(ar)
				this.form.fullText = null
                await new Promise (succes => {
                    setTimeout(()=>{
                        succes()
                    }, 250)
                })
                this.form.fullText = this.$refs.autocompleteInput.autocompleteText
				this.mapOptions.zoom  		= 18
				this.mapOptions.center.lat = _.clone(this.form.lat)
				this.mapOptions.center.lng = _.clone(this.form.lng)
				switch(this.mode){
					case 'addressText':
						this.$emit('input', this.form.fullText)
						break;
					default:
						this.$emit("input", this.form);
						break;
				}
				this.$emit('locationRequested', _.clone(this.mapOptions.center))
            } catch(e){
                console.error('error trying to get component text', e)
            }
		},
		getMapOptions(){
			return _.clone({
				lat: this.$refs.mainGoogleMap.lastCenter.lat(),
				lng: this.$refs.mainGoogleMap.lastCenter.lng(),
			})
		}
	}
};
</script>

<style lang="less">
#ClientAddLocation {

	#locationButton {
		position: relative;
		// margin-top: -3em;
		z-index: 10;
	}

	#google-map-holder {
        position: relative;
        height: auto;
        border-bottom: var(--ui-border);
        // margin-bottom: -1.0em;
		border-top: var(--ui-border);
        
		#pin {
			position: absolute;
			text-align: center;
			top: calc(50% - 2em);
			left: calc(50% - 1em);
			user-select: none;
			IMG {
				object-fit: contain;
				width: 2em;
				height: 4em;
			}
		}
	}

	#autocomplete {
		width: 100%;
		padding: 0em 1em;
		position: relative;
		z-index: 10;
		margin-bottom: -3em;

		.form-control {
			width: 100%;
			outline: 0;

			background-color: var(--white);
			color: var(--gray);

			padding: var(--ui-padding);
			font-weight: var(--ui-font-weight);
			font-style: var(--ui-font-style);
			font-family: var(--ui-font-family);
			border: var(--ui-border);
			font-size: var(--ui-font-size);
			border-radius: 0.5em;
			transition: var(--ui-transition);
		}
	}

	#google-map {
		height: 350px;
	}


}
.pac-container {
	z-index: 10000;
}
</style>