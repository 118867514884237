/**
 * OneSignalInit
 * 
 * Inicializado desde la carga de la página.
 * 
 * @author Daniel Lepe
 * @version 1.0
 */
const OneSignalInit = () => {
    console.log("OneSignalInit triggered!")
}

/**
 * OneSignalLink
 * 
 * Estable en OneSignal el ID de la cuenta que se está trabajando.
 * 
 * Eventualmente se debería solicitar en ésta sección el permiso de 
 * notificaciones PUSH con un modal.
 * 
 * @author Daniel Lepe
 * @version 1.0
 * @param {*} id 
 */
const OneSignalLink = (id) => {
    let ExternalUID = 'usr-' + id;
    window.OneSignalDeferred.push( (OneSignal) => {
        OneSignal.login(ExternalUID);
    })
}

/**
 * OneSignalUnLink
 * 
 * Remueve de OneSignal el ID actual.
 * 
 * @author Daniel Lepe
 * @version 1.0
 */
const OneSignalUnLink = () => {
    window.OneSignalDeferred.push( (OneSignal) => {
        OneSignal.logout();
    })
}
export {
    OneSignalInit,
    OneSignalLink,
    OneSignalUnLink
}