<template>  
<div class="preguntaItem">
    <!-- CUERPO DE LA PREGUNTA -->
    <div class="row row-centered-v bottom-line main-row tappable" @click="$emit('toggleQuestion', pregunta.id)">
        <div class="col-tight">
            <api-img-pristine class="usuario-avatar" :comp="pregunta.usuario_imagenes_id_avatar"></api-img-pristine>
        </div>
        <div class="col">
            <div class="block main">{{pregunta.usuario_nombre}}</div>
            <div class="block"><small>{{pregunta.created | humanizar_diferencia}}</small></div>
            <div class="block">{{pregunta.pregunta}}</div>
        </div>
        <div class="col-tight dimmed toggler" :class="{active: displyingQuestion == pregunta.id}">
            ({{pregunta.respuestas.length}}) <i class="fas fa-comments"></i> 
        </div>
    </div>
    <!-- CUERPO DE LA PREGUNTA -->

    <!-- COMENTARIOS EXTENIDOS -->
    <div class="row bottom-line bottom-changed animated fadeIn" v-if="displyingQuestion == pregunta.id">
        <div class="col-tight reserved"></div>
        <div class="col extended-content">
            <!-- OTRAS RESPUESTAS -->
            <div class="row bottom-line" v-for="resp in pregunta.respuestas" :key="resp.id">
                <div class="col-tight">
                    <api-img-pristine class="usuario-avatar" v-if="!!resp.usuarios_id" :comp="resp.usuario_imagenes_id_avatar"></api-img-pristine>
                    <api-img-pristine class="usuario-avatar seller" v-else :comp="resp.vendedor_imagenes_id_logo"></api-img-pristine>
                </div>
                <div class="col">
                    <template v-if="!!resp.usuarios_id">
                        <div class="block main">{{resp.usuario_nombre}}</div>
                        <div class="block"><small>{{resp.created | humanizar_diferencia}}</small></div>
                    </template>
                    <template v-else>
                        <div class="block main">{{resp.vendedor_nombre}}</div>
                        <div class="block"><small>{{resp.created | humanizar_diferencia}}</small></div>
                    </template>
                    <div class="block">{{resp.respuesta}}</div>
                </div>
            </div>
            <!-- OTRAS RESPUESTAS -->

            <!-- PUBLICACION DE NUEVA RESPUESTA / COMENTARIO -->
            <ui-input type="textarea" label="Comentar" v-model="form.respuesta" @keyup.enter="makeANewResponse"></ui-input>
            <lock-button :is-processing="isProcessing" :callback="makeANewResponse">Publicar comentario</lock-button>
            <!-- PUBLICACION DE NUEVA RESPUESTA / COMENTARIO -->
        </div>
    </div>
    <!-- COMENTARIOS EXTENIDOS -->
</div>
    
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import mobiscroll from '@mobiscroll/javascript'

import apiImgPristine from '@@/shared/api-img-pristine.vue'
import uiInput from '@@/ui-input.vue'
import lockButton from '@@/shared/LockButton.vue'

let emptyResp = {
    respuesta: '',
    productos_preguntas_id: null
}

export default {
    name: 'preguntaItem',
    components: {apiImgPristine, uiInput, lockButton},
    props: {
        displyingQuestion: {
            type: [String, Number],
            required: false
        },
        pregunta: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            isProcessing: false,
            form: _.cloneDeep(emptyResp)
        }
    },
    methods:  {
        async makeANewResponse() {
            this.isProcessing = true
            try {
                let data2send = _.cloneDeep(this.form)
                data2send.productos_preguntas_id = _.clone(this.pregunta.id)
                const response = await axios.post('comentarios/makearesponse', data2send)
                if (response.msg) {
                    mobiscroll.snackbar({
                        display: "top",
                        theme: "ios",
                        message: response.msg,
                        color: response.class,
                        duration: 7000,
                    });
                }
                if(response.status) {
                    await this.$parent.loadUp()
                    this.form = _.cloneDeep(emptyResp)
                }
            } catch(e) {
				console.error(e);
				mobiscroll.snackbar({
					display: "top",
					theme: "ios",
					message: "Ocurrió un error de conexión: " + e,
					color: "warning",
					duration: 7000,
				});
            }
            this.isProcessing = false
        }
    }
}
</script>

<style lang="less">
.preguntaItem {
    @size: 3em;
    @sizeMini: calc(@size / 1.2);
    .bottom-changed {
        border-bottom-color: var(--dimmed);
    }
    .toggler {
        padding: .5em;
        user-select: none;
    }
    .main-row {
        padding: 0.5em;
    }
    .usuario-avatar {
        height: @size;
        object-fit: cover;
        border-radius: 50%;
        border: var(--ui-border);
        margin-right: .5em;
    }
    .usuario-avatar, .reserved {
        width: @size;
        margin-right: .5em;
    }
    .reserved {
        background: var(--primary-transparent);
    }
    .col    {
        color: var(--black-dimmed);   
    }
    .dimmed {
        color: var(--dimmed);
        &.active {
            color: var(--primary);
        }
    }
    .extended-content {
        // padding: 0.5em 0 ;
        // margin-top: 0.75em;
        .row {
            padding: 0.5em;
        }
        .usuario-avatar {
            width: @sizeMini;
            height: @sizeMini;
        }
    }
}
</style>