<template>
  	<footer id="footer">
		<div class="container oversized-container">
			<div class="row">

				<div class="col-lg col-footer col-logo">
					<router-link to="/">
						<img src="public/images/logo-web.svg">
					</router-link>
				</div>

				<div class="col-lg col-footer col-networks align-self-center">
					<a class="link-net" target="_blank" href="">
						<i class="fab fa-facebook-square"></i>
					</a>
					<a class="link-net" href="">
						<i class="fab fa-instagram"></i>
					</a>
					<!-- <a class="link-net" href="">
						<i class="fab fa-twitter-square"></i>
					</a> -->
				</div>

				<div class="col-sm-6 col-lg col-footer">
					<h5 class="title">Pagina</h5>
					<p>
						<router-link to="/">Inicio</router-link><br />
						<router-link to="/nosotros">Nosotros</router-link><br />
						<router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link><br />
						<router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link><br />
						<router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
					</p>
				</div>

				<div class="col-sm-6 col-lg col-footer">
					<h5 class="title">Dirección</h5>
					<p>
						Juan de la Barrera #6467,<br />
						Tlaquepaque, Jalisco,<br />
						C.P. 45590.
					</p>
					<p class="mt-1">
						<strong>Tel: </strong> 33 4697 0564
					</p>
				</div>

				<!-- <div class="col-md-6 col-lg col-footer">
					<h5 class="title">Legal</h5>

					<p>
						<router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
						<router-link to="/terminos-y-condiciones">Términos y condiciones</router-link><br />
					</p>
				</div> -->

				<div class="col-12 col-lg col-footer">
					<h5 class="title">¡Descarga la App!</h5>

					<p>
						<a class="btn-app" target="_blank" href="https://play.google.com/store/apps/details?id=com.sustam.refapp">
							|<img src="public/images/shared/google-play.svg">
						</a>

						<a class="btn-app" target="_blank" href="https://apps.apple.com/mx/app/refapp/id1552936207">
							<img src="public/images/shared/app-store.svg">
						</a>
					</p>
				</div>

			</div>
		</div>

  	</footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>
