<template lang="html">
  <div class="placed-backg about-page" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row row-content align-items-center">
        <div class="col-lg-5 col-image">
        </div>

        <div class="col-lg-7 col-xl-6 col-info">
          <h1 class="mb-2 page-title">Nosotros</h1>

          <p class="text-justify">
            Somos REFAAPP® una plataforma mexicana que une a comercializadores, fabricantes y vendedores de refacciones de forma simple y segura.
          </p>

          <p class="mt-3 text-justify">
            REFAAPP® ha sido creado por expertos de la industria automotriz, especialistas del giro refaccionario y clientes del mercado. Tiene el objetivo de proveer al usuario final de una plataforma segura para comparar entre cientos de opciones de productos y elegir la mejor.
          </p>

          <p class="mt-3 text-justify">
            REFAAPP® ha comenzado operaciones en la ciudad de Guadalajara pero muy pronto estara en todo Mexico, ofreciendo el servicio de ventas digitales y permitiendo a muchas pymes del giro entrar en el comercio electrónico.
          </p>
          <!-- <p class="mt-4 text-center">
            <img class="img-fluid" src="public/images/logo-white.svg">
          </p> -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  // methods: {
  //   getContent: function(){
  //     axios.get(tools.url('/historia')).then((response)=>{
  //       this.content = response.data;;
  //     }).catch((error)=>{
  //       console.log(error);
  //     })
  //   }
  // },

  // beforeMount(){
  //   this.getContent();
  // }
}
</script>
