<template>
	<div id="cart-result-page">

		<div class="content">

			<div class="centered img-job-done">
				<img :src="completedPicture" />
			</div>
			
			<div class="centered">
				<h5>Tu pedido ha sido generado exitosamente. </h5>
			</div>
			
			<div class="padded">
				<p>
					Puedes dar seguimiento a tu pedido con los siguientes números de órden.
				</p>
				<p class="centered" v-for="(noID, $index) in cartGetJustCreatedData.numeros_orden"  :key="$index">
					<strong class="no-id">{{noID}}</strong>
				</p>
			</div>
			

			<div class="padded">
				<p>Te hemos envíado un correo para confirmar los detalles de tu pedido.</p>
				<p>Puedes encontrar todos los detalles en el apartado “mis pedidos”.</p>
			</div>

			<ui-button label="Continuar" :callback="trigger"></ui-button>
			
		</div>
	</div>
</template>

<script>
	import router from '@/js/router.js'
	import { mapActions, mapGetters } from 'vuex'
    let completedPicture = 'public/images/pedido_completo.png'

	// Una vista en este formato reuqiere que se manejen de manera independiente
	// el formulario, la página y la lista.
	export default {
		name: 'cart-result-page',
		methods: {
			...mapActions(['cartClean']),
			trigger(){
				router.push({name: 'Loader'})
			}
		},
        data: () => {
            return {completedPicture}
        },
		computed: {
			...mapGetters(['cartGetJustCreatedData']),
			numero_orden(){
				return this.$route.params.numero_orden
			},
			fechaentrega(){
				return this.$route.params.fechaentrega
			}
		},
		mounted(){
			this.cartClean()
		}
	}
</script>

<style lang="less">
	#cart-result-page {
		&, p {
			text-align: center;
			max-width: 30em;
			margin: 0 auto;
		}

		.img-job-done {
			// width: 50%;
            text-align: center;
			margin: 3em auto;
            IMG {
                display: inline-block;
                max-width: 10em;
            }
		}

		.no-id {
			background-color: var(--black);
			padding: 0.5em 2em; 
			border-radius: .5em;
			color: var(--primary);
			position: relative;
			top: 0.75em;
		}

		.left-margin STRONG {
			color: var(--primary);
		}

		dl {
			padding: 0 1em;
			margin-bottom: 1px solid lightgray;
			padding-left: 50%;
			display: flex;
			flex-flow: wrap;
		}

		dl > * {
			flex: 1 50%;
			width: 50%;
			box-sizing: border-box;
			margin: 0;
			padding: 1em 0;
			position: relative;
		}

		dl dd {
			font-weight: bold;
		}

		dd {
			text-align: right;
		}

		.button-holder {
			padding-top: 0px;
		}
	}
</style>