<template>
	<div id="preguntasYrespuestas">
		<div class="centered" v-if="isLoading">
			<i class="fa fa-cog fa-spin"></i>
		</div>
		<div v-else>

			<img class="empty-questions" src="public/images/empties/questions.png" v-if="!data.length"/>

			<div class="preguntas-index top-line" v-else>
				<pregunta-item v-for="pregunta in data" :key="pregunta.id" :pregunta="pregunta" :displying-question="displyingQuestion" @toggleQuestion="toggleQuestion($event)"/>
			</div>

			<div class="nueva-pregunta " v-if="isLogged">
				<ui-input label="Pregunta al vendedor" type="textarea" v-model="form.pregunta"></ui-input>
				<lock-button v-if="!!form.pregunta" :is-processing="isProcessing" :callback="triggerNewQuestion" label="Preguntar al vendedor" />
			</div>

			<ui-iconify class="fix-margin-top" v-else>
				<div class="block main">¿Quieres hacer una pregunta?</div>
				<div class="block">
					<router-link :to="{name: 'SignIn'}">Inicia sesión</router-link>
				</div>
			</ui-iconify>

		</div>
	</div>
</template>

<script>
import _ from "lodash";
import mobiscroll from "@mobiscroll/javascript";
import axios from "axios";
import { mapGetters } from "vuex";
import preguntaItem from "./preguntaItem.vue";
import emptyIcon from "@/assets/empties/questions.png";
import uiIconify from '@@/iconify.vue'

import uiInput from '@@/ui-input.vue'
import lockButton from '@@/shared/LockButton.vue'

let emptyForm = {
	productos_id: null,
	pregunta: "",
};

export default {
	name: "preguntasYrespuestas",
	components: { preguntaItem, uiInput, lockButton, uiIconify },
	props: {
		productId: {
			type: [Number, String],
			required: true,
		},
	},
	data: () => {
        return {
            isLoading: true,
			isProcessing: false,
			data: [],
            emptyIcon,
			form: _.cloneDeep(emptyForm),
            displyingQuestion: null
		};
	},
	computed: {
		...mapGetters(["isLogged"]),
	},
	methods: {
        toggleQuestion(quesitonId){
            this.displyingQuestion = (this.displyingQuestion != quesitonId) ? quesitonId : null;
        },
		async triggerNewQuestion() {
			this.isProcessing = true;
			try {
				let data2send = _.clone(this.form);
				data2send.productos_id = _.clone(this.productId);

				const response = await axios.post(
					"comentarios/makeaquestion",
					data2send
				);

				// ANALIZA ESTATUS DE RESPUESTA
				if (response.msg) {
					mobiscroll.snackbar({
						display: "top",
						theme: "ios",
						message: response.msg,
						color: response.class,
						duration: 7000,
					});
				}

				if (response.status) {
					this.form = _.cloneDeep(emptyForm);
					this.loadUp();
				}
			} catch (e) {
				console.error(e);
				mobiscroll.snackbar({
					display: "top",
					theme: "ios",
					message: "Ocurrió un error de conexión: " + e,
					color: "warning",
					duration: 7000,
				});
			}
			this.isProcessing = false;
		},
		async loadUp() {
			try {
                let response = await axios.get(
                    "comentarios/getbyproductid/" + this.productId
				);
				if (response.status) {
					this.data = response.data;
				}
			} catch (e) {
                console.error(e);
				mobiscroll.snackbar({
					display: "top",
					theme: "ios",
					message: "Ocurrió un error de conexión: " + e,
					color: "warning",
					duration: 7000,
				});
			}
            this.isLoading = false;
		},
	},
    beforeMount(){
        this.loadUp();
    }
};
</script>

<style lang="less">
#preguntasYrespuestas {
    a {
        color: var(--primary);
    }
	.fix-margin-top {
		margin-top: 3em;
	}
    .preguntas-index {
        margin: 0;
        padding: 0;
    }
    .nueva-pregunta {
        @spaceFix: 6em;
        // background: var(--primary-transparent);
        .ui-input {
            margin: 0;
        }
        padding-top: calc(@spaceFix / 3);
        padding-bottom: @spaceFix;
        margin-bottom: -@spaceFix;
    }
    .empty-questions {
        width: 60vw;
		max-width: 15em;
        margin: 2em auto;
        display: block;
    }
    .button-holder {
        margin : 1em;
        padding: 0;
        button {
            background: var(--black);
            font-size: 0.75rem;
        }
    }
}
</style>