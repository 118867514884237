<template>
	<div id="description-page" v-if="isDisplaying">
		<section class="breadcrumb-section">
			<div class="container oversized-container">
				<span>Producto</span>
				<span class="line">/</span>
				<span>{{ product.nombre }}</span>
			</div>
		</section>

		<!-- {{product.vendedores_id}} -->

		<section class="container oversized-container description-section" v-if="!!product.vendedores_id" id="product-description">
			<div class="row">

				<!-- IMAGE GALLERY -->

				<div class="col-lg-6 col-gallery">
					<swiper :options="galleryOptions" v-if="showGallery">
						<swiper-slide v-for="(row, galIndex) in product.gallery" :key="galIndex">
							<div class="image-container">
								<div class="img">
									<v-zoom :img="row" :width="'100%'"></v-zoom>
								</div>
							</div>
						</swiper-slide>

						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>

				<!-- MAIN FORM -->

				<div class="col-lg-5 col-description">

					<b-form  @submit.prevent="addCart">

						<seller-belt :vendedores-id="product.vendedores_id" />

						<h1 class="name">{{ product.nombre }}</h1>

						<template v-if="!!product.sku">
							<h6 class="mt-2 subtitle">SKU</h6>

							<h6 class="sku">
								{{product.sku}}
							</h6>
						</template>

						<h4 class="price">{{ product.precio | currency}}</h4>

						<h6 class="mt-2 subtitle">Descripción</h6>

						<div class="txt-description">
							{{product.descripcion}}
						</div>


						<h6 class="mt-2 subtitle">Marca</h6>
						<div class="txt-description">{{product.marca}}</div>

						<h6 class="mt-2 subtitle">Condición</h6>
						<div class="txt-description">{{product.condicion}}</div>

						<h6 class="mb-1 subtitle">Cantidad</h6>

						<div class="col-quantity2">
							<a class="form-control btn-q" @click="changeCantidad(-1)">
								<i class="fas fa-minus"></i>
							</a>
							<b-form-input class="input-q" type="number" min="1" v-model="form.quantity" @keypress="isNumber($event)" />
							<a class="form-control btn-q" @click="changeCantidad(1)">
								<i class="fas fa-plus"></i>
							</a>
						</div>

						<div class="d-block mt-4 mb-2">
							<b-button type="submit" class="my-2 my-xl-0 btn-submit">
								Agregar al carrito
								<i class="fas fa-shopping-cart"></i>
							</b-button>
						</div>

					</b-form>

					<hr />

					<preguntas-y-respuestas :productId="product.productos_id" v-if="!!product.productos_id"/>
				</div>

			</div>
		</section>

		<productos-relacionados :productId="product.productos_id" />

	</div>
</template>

<script>
import vZoom from "vue-zoom"; // https://www.npmjs.com/package/vue-zoom
import { mapActions } from 'vuex';
import SellerBelt from '../daniel/seller-belt.vue';
import swal from 'sweetalert'
import PreguntasYRespuestas from './preguntasYRespuestas.vue';
import ProductosRelacionados from './productosRelacionados.vue';
export default {
	components: { 
		vZoom,
		SellerBelt,
		PreguntasYRespuestas,
		ProductosRelacionados
	 },

	data() {
		return {
			id: null,
			isDisplaying: false,
			showGallery: true,

			form: {
				productId: null,
				quantity: 1,
			},

			product: {
				descripcion: ""
			},

			qtyPrices: [],

			category: [],

			products: [
				{
					id: 1,
					img: "public/images/pages/products/product-1.jpg",
					name: "Otro producto ejemplo 1",
					sku: "4X2S3SH",
					stock: 1,
					price: "300",
					offer_price: "150.00",
				},
			],


			gallery: [],

			comments: [],

			// == Carrusel options ==
			galleryOptions: {
				loop: false,
				speed: 600,

				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				}
			}
		};
	},

	methods: {
		...mapActions(['catalogGetProductByID', 'cartAdd']),
		async initProductDetail(){
			this.isDisplaying = false;
			this.product = await this.catalogGetProductByID(this.$route.params.id)
			this.form.productId = this.$route.params.id
			this.isDisplaying = true;
		},
		changeCantidad(mod){
			let qty = _.clone(this.form.quantity);

			if(!_.isNumber(qty)){
				qty = 1
			}

			qty += mod

			if(qty == 0){
				qty = 1;
			} if(qty > parseInt(this.product.cantidad)){
				qty = parseInt(this.product.cantidad);
			}
			this.form.quantity = qty;
		},
		async trigger(){
			try {

				let adding = {
					vendedores_id: 	this.product.vendedores_id,
					productos_id: 	this.product.productos_id,
					cantidad: 		this.form.quantity
				};

				let text = await this.cartAdd(adding)

				let selected = await swal({
					text,
					icon: "success",
					buttons: {
						cancel: "Continuar",
						go_to_pay: "Ir a pagar",
					},
				})

				if (selected == "go_to_pay") {
					this.$router.push({
						name: "Cart",
					});
				}
			} catch(e){
				swal({
					text: e,
					icon: "warning",
				});
				console.error(e)
			}
		},
		addCart(){
			this.trigger()
		}
	},

	mounted() {
		this.initProductDetail()
	},

	watch: {
		'$route'(){
			this.initProductDetail()
		}
	}
};
</script>
