<template>
	<footer class="main">				
		&copy; 2021 <strong><a href="http://sustam.com" target="_blank">Sustam</a></strong> Version 2.0	Using <i class="fab fa-vuejs"></i> and <i class="fab fa-laravel"></i>
	</footer>
</template>

<script type="text/javascript">
	export default {
        mounted() {
            
        }
    }
</script>