// IMPORTS
import _ from 'lodash'
import Db from '../../dixie.js'

// DEFINITIONS
const state = {
	favsItems: []
}

const getters = {
    favGetItems: (state) => { return state.favsItems }
}

const mutations = {
    favAddProductID: (state, payload) => {
        state.favsItems.push(payload)
    },
	favRemoveProductID: (state, payload) => {
        let currentIndex = state.favsItems.indexOf( payload )
        state.favsItems.splice(currentIndex, 1)
    },
    favSet: (state, payload) => {
		if(!!payload) state.favsItems = payload
	}
}

const actions = {
    favsRecoverFromDixie: ({commit}) => {
		return new Promise(( success ) => {
			Db.configs.where('key').equalsIgnoreCase('favsItems').first().then( (favs) => {
                commit('favSet', JSON.parse(favs.value))
				success('Favs recovered from dixie', favs.value)
			}).catch(d => {
				success('No favs available')
			})
		})
	},
    favToggleProductID: ({commit, dispatch}, payload) => {
        return new Promise( (success) => {
            dispatch('favIsProductID', payload).then( response => {
                if(!response) {
                    commit('favAddProductID', payload)
                }
                else {
                    commit('favRemoveProductID', payload)
                }
                dispatch('favWriteDixie').then( success )
            })
        })
    },
	favIsProductID: ( {getters} , payload) => {
        return new Promise(success => {
            let resp = (getters.favGetItems.indexOf(payload) !== -1)
            success(resp)
        })
    },
	favWriteDixie: ({state}) => {
        return new Promise(success => {
            Db.configs.where('key').equalsIgnoreCase('favsItems').delete().then(()=>{
                Db.configs.add({
                    key: 'favsItems',
                    value: JSON.stringify(state.favsItems)
                })
                success()
            })
        })
	}
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
