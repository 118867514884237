<template>
    <div class="order-detail">
        <ui-modal v-model="displayModal">
            <template v-if="!isLoading">
                <div slot="header">
                    No. de pedido 
                    <strong>{{pedido.header.numero_orden}}</strong>
                </div>

                <div slot="footer" v-if="!!pedido.header.clave_repa && pedido.header.pedido_estatus == 'En ruta'">
                    <div class="block">
                        <button @click="showClaveRepa">Mostrar PIN de entrega</button>
                        <button @click="this.displayModal = false">Cerrar</button>
                    </div>
                </div>
                

                <p>
                    <!-- PIN de control <strong>{{pedido.header.clave_repa}}</strong><br> -->
                    Fecha de creación: <strong>{{pedido.header.created | humanizar_fecha}}</strong>
                </p>

                <div class="cart-summary-block products">

                    <div class="pedido_estatus">
                        {{pedido.header.pedido_estatus}}
                    </div>

                    <h5>
                        Detalles del pedido
                    </h5>

                    <div class="items">

                        <div class="item" v-for="item in pedido.details" :key="item.id">

                            <!-- <div class="img-holder">
                                <img :src="item.imagen">
                            </div> -->

                            <div class="nombres">
                                <span>{{item.producto_nombre}}</span><br>
                                <span class="dimm">Marca: {{item.marca}}</span><br>
                                <span class="dimm">No. Parte: {{item.numero_parte}}</span><br>
                                <template v-if="!!item.lado">
                                    <span class="dimm">Lado: {{item.lado}}</span><br>
                                </template>
                                <span class="dimm">Condición {{item.condicion}}</span><br>
                                <span class="dimm">SKU: {{item.SKU}}</span>
                            </div>
                            
                            <div class="precios">
                                <span>{{item.precio_lista | currency}}</span><br>
                                <span class="dimm">Cantidad: {{item.cantidad}}</span>
                            </div>
                            
                            <div  class="subtotal">
                                <span>{{item.subtotal | currency}}</span>
                            </div>

                            
                        </div>

                    </div>

                    <dl>
                        <dt><span>Subtotal:</span></dt> <dd> {{ (pedido.header.total - pedido.header.costo_envio) | currency}} </dd>
                        <dt><span>Envío:</span></dt> <dd> {{pedido.header.costo_envio | currency}} </dd>
                        <!-- <template v-if="pedido.header.comision_refapp">
                            <dt><span>Comisión REFAAPP®:</span></dt> <dd> {{pedido.header.comision_refapp | currency}} </dd>
                        </template> -->
                        <dt class="total"><span>Total:</span></dt> <dd class="total" @click="toggleUI()"> {{pedido.header.total | currency}}</dd>
                    </dl>
                    
                </div>

                <div class="cart-summary-block payment">
                    <h5>
                        Método de pago
                    </h5>
                    <div >
                        <img v-if="pedido.header.metodospago_type == 'mastercard'" src="public/images/pago/mastercard.svg">
                        <img v-if="pedido.header.metodospago_type == 'visa'" src="public/images/pago/visa.svg">
                        <img v-if="pedido.header.metodospago_type == 'amex'" src="public/images/pago/amex.svg">
                        <p>
                            {{pedido.header.metodospago_public_number}}
                        </p>
                    </div>
                </div>

                <div class="cart-summary-block address">
                    <h5>
                        Dirección de entrega
                    </h5>
                    <div class="padded-h">
                        <p>
                            {{pedido.header.domicilioentrega}}
                        </p>
                    </div>
                </div>

                <div class="cart-summary-block shipping-guide" v-if="!!pedido.header.guia_rastreo">
                    <h5>
                        Guía de rastreo
                    </h5>
                    <div >
                        <p>
                            {{pedido.header.guia_rastreo}}
                        </p>
                    </div>
                </div>

            </template>

            <div v-else>
                <i class="fa fa-cog fa-spin"></i> Cargando...
            </div>
        </ui-modal>
    </div>
</template>

<script>
    import _ from 'lodash'
    import { mapActions } from 'vuex'
    import swal from 'sweetalert'
    import uiModal from '@@/ui-modal.vue'
    import mobiscroll from '@mobiscroll/javascript'
    import lockButton from '@@/shared/LockButton.vue'

    // Una vista en este formato reuqiere que se manejen de manera independiente
    // el formulario, la página y la lista.
    export default {
	    components: { uiModal, lockButton },
        name: 'order-detail',
        data: ()=> {
            return {
                displayModal: false,
                isLoading: false,
                orderId: null,
                pedido: {
                    header: {},
                    details: []
                }
            }
        },
        methods: {
            ...mapActions(['orderReturnByID']),
            loadUpOrder(orderId) {
                this.orderId = orderId;
                this.displayModal = true;
                this.loadOrder()
            },
            showClaveRepa(){
                swal({
                    icon: 'info',
                    title: 'PIN: ' + this.pedido.header.clave_repa,
                    text: 'Muestra éste PIN a tu repartidor para que pueda finalizar la entrega'
                })
            },
            async loadOrder(){
                this.isLoading = true;
                try {
                    
                    let found = await this.orderReturnByID(this.orderId)
                    console.log("found", found)
                    this.pedido = (found)
                    this.pedido.details = _.sortedUniqBy(this.pedido.details, (i) => {return i.id})

                } catch(e) {
                    // ANALIZA SI HAY MENSAJES PARA EL USUARIO, duración extendia.
                    mobiscroll.snackbar({
                        display: 'top',
                        theme: 'ios',
                        message: response.msg,
                        color: response.class,
                        duration: 7000
                    })
                }
                
                this.isLoading = false;
                // alert('reached down!')
            }
        }, 
        watch: {
            displayModal(){
                if(!this.displayModal){
                    this.$emit('closingModal', this.displayModal)
                }
            }
        }
    }
</script>

<style lang="less">
    .order-detail {
        
        p {
            padding: 1.5em;
        }

        .cart-summary-block {
            margin-top: 2em;
            position: relative;

            & > h2 {
                display: flex;
                flex-flow: row;
                width: 100%;
                align-items: center;
                border-bottom: 1px solid lightgray;
                margin-bottom: 0.5em;
                margin-top: 0.5em;
            }

            &.payment {
                div {
                    display: flex;
                    align-items: center;
                    img {
                        width: 3.5em;
                        margin-right: 0em;
                        margin-left: 1.2em;
                    }
                }
            }

            &.shipping-guide {
                P {
                    color: #009CDB;
                }
            }

            &.products > div {
                margin-top: -0.5em;
            }
        }

        dl {
            padding: 0 1em;
            margin-bottom: 1px solid lightgray;
            padding-left: 33.33%;
            display: flex;
            flex-flow: wrap;

            & > * {
                flex: 1 auto;
                box-sizing: border-box;
                margin: 0;
                padding: .5em 0;
                position: relative;
            }

            dd {
                text-align: left;
            }
            dt {
                text-align: right;
                padding-right: 1em;
                flex: 0 auto;
                width: 66%;
            }
            .total {
                font-weight: bold;
            }
        }

        .items {
            
            .item {
                padding: 0.5em;
                max-width: 100vw;
                background: #f4f3f3;
                display: flex;
                flex-flow: row;
                border-bottom: 1px solid lightgray;
                // align-items: center;
                // padding: 0.5em 0;
                user-select: none;
                gap: 0.5em;

                div {
                    margin: 0;
                    padding: 0 0em;
                }

                .img-holder {
                    flex: 0;
                    padding-right: 1em;
                    img {
                        width: 5em;
                        height: 5em;
                        -o-object-fit: contain;
                        object-fit: contain;
                        background-color: white;
                    }
                }

               .dimm {
                   margin-left: 1em;
                   color: darkgray;
                   font-size: 0.8em;
               }
                .nombres {
                    flex: 0 0 auto ;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    // overflow: hidden;
                }
                .precios {
                    flex: 1 0 auto;
                    text-align: center;
                }
                .subtotal {
                    flex: 1 0 auto;
                    text-align: right;
                    font-weight: bold;
                }
            }

        }

        .pedido_estatus {
            position: absolute;
            right: 1em;
            top: 2.7em;
            font-size: 0.9em;
            color: #7ED321;
        }

    }
    
</style>