<template>

	<div class="iconify-holder shaded-bg" :class="{welled: well}">
		<div class="iconify" :key="iconClass">
			<i :class="iconClass"></i>
		</div>
		<slot class="content" name="default">
			Información no disponible
		</slot>
	</div>

</template>
			
<script>
	export default {
		props: {
			well: {
				type: [Boolean, Number, String],
				default: false
			},
			iconClass: {
				type: String,
				default: 'fa fa-exclamation-circle'
			}
		}
	}
</script>
			
<style lang="less">
    @uiFontSize: 1em;
	.iconify-holder {
		padding: @uiFontSize;
		text-align: center;
		position: relative;
		top: calc(var(@uiFontSize) * -1 * pow(1.618, 1));
		margin-bottom: calc(var(@uiFontSize) * 1 * pow(1.618, 1));
		.iconify {
			color: var(--dimmed-dark);
			margin-bottom: @uiFontSize;
			i, svg {
				font-size: 8em;
			}
		}
		&.welled {
			max-width: calc(@uiFontSize * 20);
			margin: 0 auto;
			border-radius: .5em;
			box-shadow: var(--ui-box-shadow);
		}
	}
</style>