// IMPORTS
import _ from 'lodash'

const state = {
	UI:{
		searchVisibility: 0,
		searchTerm: '',
		onboardingWatched: false
	},
    UICats: {
		mainSelected: 0,
		subcats: []
    }
}

const getters = {
	UIGetOnboardingIsWatched: (state) => { return state.UI.onboardingWatched },
	UIGetSearchTerm: (state) => { return state.UI.searchTerm },
	UICatsGetMainSelected: (state) => { return state.UICats.mainSelected },
	UICatsGetSubcats: (state) => { return state.UICats.subcats },
	UISearchVisibility: (state) => { return state.UI.searchVisibility }
}

const mutations = {
	UISetSearchTerm: (state, payload) => { state.UI.searchTerm = payload },
	UISetOnBoardingWatched: (state) => { state.UI.onboardingWatched = true },
	UIToggleSearch: (state) => { state.UI.searchVisibility = !state.UI.searchVisibility },
	UICatsSetMainSelected: (state, payload) => { state.UICats.mainSelected = parseInt(payload) },
	UICatsSetSubcategories: (state, payload) => { state.UICats.subcats =  payload }
}

const actions = {
	UICatsSetMainSelected: ({commit, getters}, payload) => { 
		commit('UICatsSetMainSelected', payload)
		let filtering = getters.catalogParentCats[payload].subcategorias,
			filtered = []
		_.each(filtering, (item) => {
			filtered.push( _.find(getters.catalogCats, {categorias_id: item}) )
		})
		commit('UICatsSetSubcategories', _.compact(filtered))
	}
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
