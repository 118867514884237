<template>
	<div id="inventoryItem">
		<transition name="slide-fade">
			<div class="product-row" v-if="showing">

				<router-link class="tappable" 
					v-if="!!product && product.productos_id"
					:to="{name: 'ProductDetail', params: {id: product.productos_id, productName: product.nombre}}">
					<img class="product-row-img" :src="product.gallery">
				</router-link>

				<div class="product-row-content extended">
					<strong>{{product.nombre}} - {{product.marca}}</strong> <br>
					<span>{{product.descripcion_corta}}</span> <br>
					<template v-if="!!product.numero_parte"><span>No. de parte: {{product.numero_parte}}</span> <br></template>
					<div class="dimmed">
						<span>{{product.condicion}}</span>
						<span v-if="!!product.lado">(Lado: {{product.lado}})</span>
					</div>

					<strong>{{product.precio | currency}}</strong>

					<!-- CART QTY CONTROLS -->
					<div class="product-row-cantidad" v-if="cartData && !blocked">
						<span class="product-row-item tappable product-row-mod" @click="cartItemMinus(cartData)">-</span>
						<span class="product-row-item product-row-qty">{{cartData.cantidad}}</span>
						<span class="product-row-item tappable product-row-mod" @click="cartItemPlus(cartData)">+</span>
					</div>
					<!-- CART QTY CONTROLS -->

					<!-- CART BLOCKED CONTROLS -->
					<div class="" v-else-if="cartData">
						<span class="" v-if="cartData.cantidad == 1">{{cartData.cantidad}} Unidad</span>
						<span class="" v-else>{{cartData.cantidad}} Unidades</span>
					</div>
					<!-- CART BLOCKED CONTROLS -->
				</div>

				<div class="product-row-controls" v-if="cartData && !blocked" @click="remove()">
					<!-- <img src="@/assets/ic/nav/cerrar.svg" /> -->
                    <i class="fa fa-trash"></i>
				</div>

			</div>
		</transition>
	</div>
</template>

<script>
	import _ from 'lodash'
	import { mapActions } from 'vuex'

	export default {
        name: 'InventoryItem',
		data() {
			return {
				product: {},
				showing: true
			}
		},
		props: ['productId', 'cartData', 'cartIndex', 'blocked'],
		methods: {
			...mapActions([
				'catalogGetProductByID',
				'catalgoGetStockByproductId',
				'cartItemPlus',
				'cartItemMinus',
				'cartRm'
			]),
			remove() {
				this.showing = false
				setTimeout(() => {
					this.cartRm(this.cartIndex)
				}, 300)
			}
		},
		mounted() {
			// this.catalgoGetStockByproductId(this.productId).then(inv => {
				this.catalogGetProductByID(this.productId).then(product => {
					this.product = product
				})
			// })
		}
	}
</script>

<style lang="less">
	#inventoryItem {
		.product-row {
			padding: var(--ui-gap);
			display: flex;
			width: 100%;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			border-bottom: var(--ui-border);
			background-color: var(--white);
	
			.dimmed {
				span {
					color: var(--primary);
				}
			}
	
			&-details {
	
				line-height: 1.2em;
				margin-bottom: -1em;
	
				SPAN.color {
					display: inline-block;
					width: 1em;
					height: 1em;
					border-radius: 50%;
					top: 0.2em;
					position: relative;
					box-shadow: 0 0 0.05em 0.1em #5b4f4f2b;
				}
			}
	
			&-item {
				flex: 1 0 auto;
				text-align: center;
				padding: 0.3em 0;
				font-size: 0.8rem !important;
			}
	
			&-img {
				width: 5em;
				height: 5em;
				object-fit: contain;
				padding-right: var(--ui-gap);
				background-color: var(--white);
			}
	
			&-controls {
				flex: 0;
				padding: 0.5em;
				color: #659d31;
				position: relative;
				right: 4em;
			}
	
			&-cantidad {
				display: flex;
				background-color: var(--light);
				border-radius: 0.25em;
				width: 7em;
				margin-top: 0.5em;
				border-radius: 0.25em;
				border: var(--ui-border);
				margin: 0.2em 0;
			}
	
			&-content {
				flex: 1 0 auto;
				padding: 0.5em;
				&.extended {
					width: 40vw;
				}
			}
	
			&-qty {
				flex: 1 0 auto;
				background-color: var(--white);
				border: 1px solid lightgray;
				border-top: none;
				border-bottom: none;
				flex: 2 0 auto;
			}
			
			strong,
			span {
				font-size: 0.9em;
			}
	
			span {
				color: #848484;
			}
		}
	}
</style>