<template lang="html">
  <div id="home-page">

    <section class="banners-section">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(banner, bhoInx) in banners" :key="'bho-'+bhoInx">
          <img :src="banner.img">
          <img class="img-mobile" :src="banner.imgMob">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-titles">
            <h2 class="title">Una plataforma muy sencilla de utilizar</h2>
            <h6 class="subtitle">Recibe asistencia rápidamente.</h6>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg-4 col-opt">
            <div class="d-block">
              <div class="circle">
                <img src="public/images/pages/home/step-1.jpg">
              </div>

              <h3 class="num">1</h3>
              <p>
                Encuentra productos de forma efectiva y en segundos.
              </p>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-opt">
            <div class="d-block">
              <div class="circle">
                <img src="public/images/pages/home/step-2.jpg">
              </div>

              <h3 class="num">2</h3>
              <p>
                Explora a detalle cada pieza que necesites antes de comprarla.
              </p>
              <!-- <p>
                Paga con: <img src="public/images/pages/home/cards.png">
              </p> -->
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-opt">
            <div class="d-block">
              <div class="circle">
                <img src="public/images/pages/home/step-3.jpg">
              </div>

              <h3 class="num">3</h3>
              <p>
                Pídela, paga de forma segura y recibe en menos de 3 horas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="newsletter-section">
      <h2>Suscríbete a nuestro Newsletter</h2>

      <b-form @submit="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Correo electrónico"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [
        { img: 'public/images/pages/home/banner-1.jpg', imgMob: 'public/images/pages/home/banner-1-m.jpg' },
        { img: 'public/images/pages/home/banner-2.jpg', imgMob: 'public/images/pages/home/banner-2-m.jpg' },
      ],

      form: {
        email: null
      },

      // == Carousels options ==
      swiperOption: {
        loop: true,
        effect: 'fade',
        speed: 750,

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // == ==
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          toaster: 'b-toaster-top-right',
          appendToast: true
        });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas suscribirte al Newsletter?");
      if (r == true) {
        axios.post(tools.url("/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            email: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.email, 'Error');
        });
      }
    },
  }
}
</script>
