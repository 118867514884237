<template>
	<div id="PaymnetMethodsIndex">

		<div v-if="updating">
			Actualizando...
		</div>

		<div class="cards-list" v-else>
			<div id="CreditGallerySwiper">

				<!-- SLIDES GROUP START -->
				<div class="swiper-wrapper">

					<div class="swiper-slide" v-for="myCard in paymentmehtodsGetList" :key="'card-' + myCard.id">
						<div class="card">
							<div class="logo"> 
								<img v-if="myCard.tipo == 'visa'" :src="icons.iconVisa">
								<img v-if="myCard.tipo == 'amex'" :src="icons.iconAmex">
								<img v-if="myCard.tipo == 'mastercard'" :src="icons.iconMastercard">
							</div>
							<div class="titular">{{myCard.titular}}</div>
							<div class="number">**** **** **** *{{myCard.ultimos_tres}}</div>
						</div>
					</div>

					<div class="swiper-slide">
						<div class="card">
							<div class="logo"> 
								<img :src="icons.iconNew">
							</div>
							<div class="titular">{{card.titular}}</div> 
							<div class="number">{{creatingCardNumber}}</div>
						</div>
					</div>
					
				</div>

				<!-- Add Pagination -->
				<div class="swiper-pagination"></div>

			</div>
		</div>

		<!-- FORMULARIOS -->
		<div class="limit-width">

			<div v-if="!updating && isCreatingCard" id="form" class="card-form card-create animated fadeInUp">
				<ui-input v-model="card.titular" placeholder="Juan Pérez" label="Nombre del titular"></ui-input>

				<div class="row">
					<div class="col"><ui-input placeholder="XXXX XXXX XXXX X123" v-model="card.number" maxlength="19" pattern="[\d| ]*" label="Numero de tarjeta" ></ui-input></div>
					<div class="col col-tight"><div class="col"><ui-input v-model="card.cvv" maxlength="4" pattern="[\d|\/]*" placeholder="123" label="Numero de seguridad"></ui-input></div></div>
				</div>
				
				<!-- <ui-input v-model="card.vigency" maxlength="5" pattern="[\d|\/]*" placeholder="12/24" label="Fecha de vencimiento"></ui-input> -->
				<div class="row">
					<div class="col"><ui-input v-model="card.vigency_month" maxlength="2" pattern="[\d|\/]*" placeholder="12" label="Mes de vencimiento"></ui-input></div>
					<div class="col"><ui-input v-model="card.vigency_year" maxlength="4" pattern="[\d|\/]*" placeholder="2023" label="Año de vencimiento"></ui-input></div>
				</div>
				
				<lock-button class="create" v-if="isCreatingCard" label="Agregar método de pago" :is-processing="isPostcardProcessing" :callback="postCard"></lock-button>
				
			</div>

			<div v-else-if="!updating" id="view" class="card-form card-existent animated fadeInUp">
				<ui-input v-model="activeCardViewer.titular" label="Nombre del titular" readonly=true></ui-input>
				<ui-input v-model="activeCardViewer.number" label="Numero de tarjeta" readonly=true></ui-input>
				<!-- <ui-input v-model="activeCardViewer.vigency" placeholder="12/24" label="Fecha de vencimiento" readonly=true></ui-input> -->
				<lock-button class="optional" v-if="cartGetPaymentMethod.id != activeCardViewer.id" label="Seleccionar método de pago" :is-processing="isPostcardProcessing" :callback="selectMethod(activeCardViewer)" ></lock-button>
				<lock-button class="selected" v-else label="Método seleccionado" :is-processing="isPostcardProcessing" disabled></lock-button>
			</div>
			
		</div>
		<!-- FORMULARIOS -->

	</div>
</template>

<script>
	import _ from 'lodash'
	import Swiper from 'swiper'
	import { Pagination } from 'swiper';
	import axios from 'axios'
	import moment from 'moment'
	import mobiscroll from '@mobiscroll/javascript'
    import uiInput from '@@/ui-input.vue'
    import lockButton from '@@/shared/LockButton.vue'
	
	//icons 
	let iconVisa = 'public/images/pago/visa.svg'
	let iconMastercard = 'public/images/pago/mastercard.svg'
	let iconAmex = 'public/images/pago/amex.svg'
	let iconNew = 'public/images/pago/new.svg'

	import { mapGetters, mapMutations, mapActions } from 'vuex'

	import 'swiper/css';
	import 'swiper/css/pagination';

	const emptyCard = {
		titular: '',
		number: '',
		cvv: '',
		// vigency: ''
		vigency_month: '',
		vigency_year: '',
	};
	
	export default {
        components:{uiInput, lockButton},
		data() {
			return {
				currentSlide: 0,
				updating: false,
				isPostcardProcessing: false,
				currentSwiper: null,
				currentSlide: null,
				isCreatingCard: false,
				card: _.clone(emptyCard),
				icons: {
					iconVisa,
					iconMastercard,
					iconNew,
					iconAmex
				}
			}
		},
		created(){
			this.updating = true
			this.paymentmehtodsUpdate().then(()=>{
				this.updating = false
			})
		},
		methods: {
			...mapMutations(['paymentmehtodsSetList', 'cartSetPaymentMethod']),
			...mapActions(['paymentmehtodsUpdate']),
			postCard(){
				this.isPostcardProcessing = true
				let data2send = {
					token: this.accountGetToken,
					titular: _.cloneDeep(this.card.titular),
					ultimos_tres: _.replace(this.creatingCardNumber, /[\D]*/ig, ''),
					tipo: 'visa',
					vigencia: this.parsedVigency
				}
				axios.post('paymentmethods/store', data2send).then(async (response)=>{
					this.isPostcardProcessing = false
					if (response.msg) {
						mobiscroll.snackbar({
							display: 'top',
							message: response.msg,
							color: response.class
						})
					}
					if(response.status){
						console.log('response.data', response.data)
						await this.paymentmehtodsUpdate()
						setTimeout(()=>{
							this.paymentmehtodsSetList(response.data)
							this.cartSetPaymentMethod(response.data[0])
							console.log('this.currentSwiper', this.currentSwiper)
							this.currentSwiper.update()
							this.card = _.clone(emptyCard)
						}, 150)
						// this.$router.go(-1)
					}
				}).catch(response => {
					this.isPostcardProcessing = false
					if (response.msg) {
						mobiscroll.snackbar({
							display: 'top',
							message: response.msg,
							color: response.class
						})
					}
				})
			},
			updateCardNumber($event){
				this.card.number = _.join(_.map(_.chunk(_.replace(this.card.number, /[ |\W|a-z]/ig, ''), 4), (four) => { return _.join(four, '') }), ' ')
			},
			isLastSlide(){
				this.isCreatingCard = true
			},
			isNotLastSlide(currentSlide){
				this.isCreatingCard = false
				this.currentSlide = currentSlide
			},
			reviewSlider(){
				if( this.currentSwiper.slides.length == (this.currentSwiper.activeIndex + 1) ){
					this.isLastSlide()
				} else {
					this.isNotLastSlide(this.currentSwiper.activeIndex)
				}
			},
			startcurrentSwiper() {

				this.currentSwiper = new Swiper('#CreditGallerySwiper', {
					modules: [Pagination],
					pagination: {
						el: ".swiper-pagination",
						dynamicBullets: true,
					},
				})

				this.currentSwiper.on('slideChange', () => {
					this.reviewSlider()
				})

				this.reviewSlider()
			},
			selectMethod(method){
				return () => {
					this.cartSetPaymentMethod(method)
					swal('', 'Método de pago seleccionado exitosamente', 'success')
				}
			}
		},
		computed: {
			...mapGetters(['accountGetToken', 'paymentmehtodsGetList', 'cartGetPaymentMethod']),
			activeCardViewer(){
				if(!this.paymentmehtodsGetList[this.currentSlide]){
					return {}
				}
				return {
					id: this.paymentmehtodsGetList[this.currentSlide].id,
					titular: this.paymentmehtodsGetList[this.currentSlide].titular,
					tipo: this.paymentmehtodsGetList[this.currentSlide].tipo,
					number: "**** **** **** *" + this.paymentmehtodsGetList[this.currentSlide].ultimos_tres,
					vigency:  moment(this.paymentmehtodsGetList[this.currentSlide].vigencia).format("MM/YY")
				}
			},
			parsedVigency(){
				return moment()
					.month(parseInt(this.card.vigency_month) )
					.date(1)
					.subtract(1, 'day')
					.year(parseInt(this.card.vigency_year))
					.format('YYYY-MM-DD')
			},
			creatingCardNumber(){
				let result =  _.join(_.map(_.chunk(_.map(_.chunk(_.replace(this.card.number, /[ |\W|a-z]/ig, '')), (ch, i) => { return  (i >= 13) ? ch[0] : '*'}), 4), (four) => {return _.join(four, '')}), ' ')

				if(!this.card.number)
					return 'Nueva tarjeta'

				else if(result == '')
					return 'Tarjeta inválida'

				else return result
			}
		},
		updated(){
			if(!this.updating && this.currentSwiper == null){
				this.startcurrentSwiper()
			} 
			if(!this.updating && !!this.currentSwiper){
				this.reviewSlider()
			}
			this.updateCardNumber()
		},
		mounted() {
			if(!this.updating)
				this.startcurrentSwiper()
		}
	}
</script>

<style lang="less" scoped>
    #PaymnetMethodsIndex {

		background: red;

        display: inline;


        .cards-list {
			text-align: center;
			
			#CreditGallerySwiper {
				// max-width: 610px;
				padding-top: 2.5em;
				padding-bottom: 1.2em;
				width: 100%;
				height: 100%;
				overflow: hidden;
				display: inline-block;

				.swiper-slide {
					text-align: center;
					font-size: 18px;
					
					/* Center slide text vertically */
					display: flex;
					justify-content: center;
					align-items: center;

					.logo {
						text-align: left;
						img {
							width: 90px;
						}
					}

				}

				.card {
					padding: 1em;
					background-color: white;
					border-radius: 0.5em;
					box-shadow: 0em 0em 1.2em rgba(0,0,0,0.2);
					position: relative;
					width: 610px;
					height: 320px;
					margin: 1em;
					margin-top: -1em;


					.titular {
						font-size: 1.5em;
						position: relative;
						top: 66px;
						color: var(--primary);
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						text-transform: uppercase;
					}


					.number {
						position: absolute;
						bottom: 0;
						width: 100%;
						right: 0em;
						font-size: 0.9rem;
						text-align: right;
						padding: 0.5em;
						color: #555;
					}
				}

				.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,  .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
					position: relative;
					bottom: 2em;
				}
			}
        }

        .card-form {
            LABEL {
                padding: 0em;
            }
            INPUT {
                border-radius: 0;
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 0.5em 0;
            }
            .button-holder {
                // &.create { BUTTON {background-color: #F35F13;}}
                &.selected { BUTTON {background-color: black; opacity: 0.5;}}
                &.optional { BUTTON {}}
            }
        }
    }
</style>
