/**
 * Concept Archetype
 *
 * @Version 1.0
 * @Author Daniel Lepe
 */

import _ from 'lodash'
import {notify, toastMSG} from '@@/utils.js'
import axios from 'axios';

const baseController = 'repa'

const baseIndex = baseController + '/index'

const archetype = [
    {
        label: '* Nombre',
        key: 'nombre',
        type: 'text'
    },
    {
        label: '* Correo electrónico',
        key: 'email',
        type: 'text'
    },
    {
        label: '* Teléfono',
        key: 'telefono',
        type: 'text'
    },
    {
        label: '* Contraseña',
        key: 'password',
        type: 'password'
    },
    {
        label: '* Confirmación de la contraseña',
        key: 'password_confirmation',
        type: 'password'
    }
];

const captions = [
    // {key: '_'},
]

let formFromArchetype = () => {
    let from = {}
    _.forEach(archetype, field => {
        from[field.key] = null
    })
    from.tos = false;
    return from
}

let automodalHandler = (conceptId) => {
    let str = baseController + '/' + 'define' + ((_.isNil(conceptId)) ? '' : ('/' + conceptId) );
    // console.log("isNil", _.isNil(conceptId))
    return str;
}

let signupAsRepa = async (dataForm) => {
    try {
        let response = await axios.post(automodalHandler(dataForm.id), dataForm)
        if(!_.isObjectLike){
            throw 'Error en la respuesta'
        }
        toastMSG(response.msg, response.class)
        return response.status
    } catch(e){
        notify(e)
    }
}

export {
    archetype,
    captions,
    baseIndex,
    automodalHandler,
    signupAsRepa,
    formFromArchetype
}
