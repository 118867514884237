// IMPORTS
import _ from 'lodash'
import mobiscroll from '@mobiscroll/javascript'
import axios from 'axios'
import moment from 'moment'
import Db from '../../dixie.js'

// DEFINITIONS
const initSummary = {
	subtotal:	0,
	shipping: 0,
	comisionRefap: 0.0,
	total: 0,
	qty: 0,
	sellersWithFreeShipping: {}
}

const state = {
	cart: [],
	isBusy: false,
	paymentMethod: {
		id: null
	},
	justCreatedData: null,
	commisionPercent: 0.0,
	envioFijo: 0.0,
	summary: _.clone(initSummary)
}

const getters = {
	cartIsBusy: (state) => { return state.isBusy },
	cartGetJustCreatedData: (state) => { return state.justCreatedData },
	cartSummary: (state) => {return state.summary },
	cartIsEmpty: (state) => {return (!state.cart.length)},
	cartGet: (state) => {return state.cart},
	cartGetPaymentMethod: (state) => { return state.paymentMethod}
}

const mutations = {
	cartSetBusy: (state) => { state.isBusy = true},
	cartSetUnBusy: (state) => { state.isBusy = false},
	cartSetFixedConfig: (state, payload) => {
		state.commisionPercent = parseFloat(payload.commisionPercent)	// ESTE ES PARA QUE CAMBIE state.summary.comisionRefap
		state.envioFijo = parseFloat(payload.fixedPrice) // ESTE SOLO SE SUMARÁ YA NO CAMBIA
	},
	cartSetJustCreatedData: (state, payload) => {
		state.justCreatedData = payload
	},
	cartSetPaymentMethod: (state, payload) => {
		if(state.paymentMethod.id == payload.id){
			state.paymentMethod = {id: null}
		} else {
			state.paymentMethod = payload
		}
	},
	cartMinusQtyToIndexItem: (state, payload) => {
		state.cart[payload].cantidad--
	},
	cartPlusQtyToIndexItem: (state, payload) => {
		state.cart[payload].cantidad++
	},
	cartSet: (state, payload) => {
		if(!!payload) state.cart = payload
	},
	cartAdd_: (state, payload) => {
		state.cart.push(payload)
	},
	cartRm_: (state, payload) => {
		state.cart.splice(payload, 1)
	},
	cartUpdate: (state) => {
		let isOnlyFreeShipping = true,
			summary = _.clone(initSummary),
			sellersWithFreeShipping = {}

		_.map(state.cart, (item) => {
			if(sellersWithFreeShipping[item.vendedores_id] === undefined) {
				sellersWithFreeShipping[item.vendedores_id] = true
			}
			if(!item.envio_gratis){ 
				isOnlyFreeShipping = false

				// AGREGA EL COSTDEL ENVÍO
				// if(sellersWithFreeShipping[item.vendedores_id]){
				// 	summary.shipping += parseFloat(state.envioFijo)
				// }
				sellersWithFreeShipping[item.vendedores_id] = false
			}
			summary.subtotal += (item.cantidad * item.precio)
			summary.qty += item.cantidad
		})

		// ASIGNACIÓN DE VENDEDORES CON ENVÍO GRATIS
		summary.sellersWithFreeShipping = sellersWithFreeShipping
		
		// CÁLCULO DE COMISIÓN DE REFAAPP®
		// summary.comisionRefap = (parseFloat(state.commisionPercent) * .01) * summary.subtotal

		// EL TOTAL CORRESPONDE AL SUBTOTAL, MAS EL FIJO DE ENVÍO (CUÁNDO NO SEAN TODOS LOS PRODUCTOS GRATUITOS) MAS LA COMISIÓN DE REFAAPP®
		summary.total = summary.subtotal + summary.shipping + summary.comisionRefap

		// ASIGNACIÓN AL STATE
		state.summary = summary
	},
	cartSetClean: (state) => {
		state.cart = []
	}
}

const actions = {
	cartAdd: ({commit, dispatch, getters}, payload) => {
		return new Promise( (success, fail) => {
			let preFetch = _.find(getters.cartGet, {productos_id: payload.productos_id})
			
			dispatch('catalogGetProductByID', payload.productos_id).then((product) => {
				console.log("product", product)
				payload.precio = product.precio
				payload.envio_gratis = (product.envio == 'Gratis')

				// LE PRODUCTO NO SE HA AGREGADO PREVIAMENTE AL CARRITO
				if(!preFetch){

					commit('cartAdd_', payload)
					dispatch('writeDixie')
					success('Producto agregado al carrito')

				} else {

					// ACTUALIZACIÓN DE CANTIDAD
					payload.cantidad += preFetch.cantidad

					// dispatch('catalogGetProductByID', payload.inventario_id).then((inv) => {
						let currentIndex = _.findIndex(getters.cartGet, {productos_id: payload.productos_id})

						if( payload.cantidad ){
							commit('cartRm_', currentIndex)
							commit('cartAdd_', payload)
							dispatch('writeDixie')
							success('Carrito actualizado')
						} else {
							fail('Existencias insuficientes')
						}
					// })

				}

			})
		})
	},
	cartRm: ({commit, dispatch}, payload) => {
		commit('cartRm_', payload)
		dispatch('writeDixie')
	},

	cartProcess: ({ getters, commit }, payload) => {
		return new Promise( (success, fail) => {
			
			commit('cartSetBusy')
			let data2send = {
				token: getters.accountGetToken,
				// paymentMethod: getters.cartGetPaymentMethod,
				paymentId: payload.paymentId,
				order: getters.cartGet,
				summary: getters.cartSummary,
				agenda: {},
				domicilioentrega: payload.domicilioentrega,
				propina_repa: payload.propina_repa,
				workingGPS: payload.workingGPS,
			}
			if(payload.agenda) {
				data2send.agenda.tipo_orden = _.clone(payload.agenda.tipo_orden)
				data2send.agenda.agenda_fecha = moment(payload.agenda.agenda_fecha).format('YYYY-MM-DD')
				data2send.agenda.agenda_hora_fin = moment(payload.agenda.agenda_hora_fin).second(0).format('HH:mm:ss')
				data2send.agenda.agenda_hora_inicio = moment(payload.agenda.agenda_hora_inicio).second(0).format('HH:mm:ss')
			}
			axios.post('orders/store', data2send).then((response) => {

				if (response.msg) {
					mobiscroll.snackbar({
						display: 'top',
						message: response.msg,
						color: response.class
					})
				}

				if(response.status){
					commit('cartSetJustCreatedData', response.data)
					success(response)
				} else {
					fail(response)	
				}
				commit('cartSetUnBusy')
			}).catch(()=>{
				mobiscroll.snackbar({
					display: 'top',
					message: 'Ha ocurrido un error de conexión, por favor verifica tu conexión.',
					color: 'warning'
				})
				commit('cartSetUnBusy')
			})
		})
	},

	// MANY THINGS TO DO, NOT RIGHT NOW.
	cartRecoverFromDixie: ({commit, getters}) => {
		return new Promise(( success ) => {
			Db.configs.where('key').equalsIgnoreCase('cart').first().then(cart => {
				success('Cart recovered from dixie', cart.value)
				commit('cartSet', JSON.parse(cart.value))
				commit('cartUpdate', JSON.parse(cart.value))
			}).catch(d => {
				success('No cart available')
			})
		})
	},
	cartItemPlus: ({ getters, commit, dispatch }, payload) => {
		dispatch('catalogGetProductByID', payload.productos_id).then((inv) => {
			let current = _.find(getters.cartGet, {productos_id: payload.productos_id})
			let currentIndex = _.findIndex(getters.cartGet, {productos_id: payload.productos_id})
			if(current.cantidad < inv.cantidad){
				commit('cartPlusQtyToIndexItem', currentIndex)
				dispatch('writeDixie')
			}

		})
	},
	cartItemMinus: ({ getters, commit, dispatch }, payload) => {
		let current = _.find(getters.cartGet, {productos_id: payload.productos_id})
		let currentIndex = _.findIndex(getters.cartGet, {productos_id: payload.productos_id})
		if(current.cantidad > 1){
			commit('cartMinusQtyToIndexItem', currentIndex)
			dispatch('writeDixie')
		}

	},
	cartClean: ({dispatch, commit}) => {
		commit('cartSetClean')
		commit('cartUpdate')
		dispatch('writeDixie')
	},
	writeDixie: ({commit, state}) => {
		commit('cartUpdate')
		Db.configs.where('key').equalsIgnoreCase('cart').delete().then(()=>{
			Db.configs.add({
				key: 'cart',
				value: JSON.stringify(state.cart)
			})
		})
	}
}

// EXPORTS
export default {
	state,
	getters,
	mutations,
	actions
};
