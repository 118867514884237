<template>
	<!-- <div> -->
		<!-- <code> {{catalogProducts}} </code> -->

		<section class="container oversized-container related-products-section" id="related-products">

			<div class="row">
				<div class="col-12 px-0 mb-3 col-s-title">
					<h2 class="title-s2">Productos relacionados</h2>
					<hr class="mt-2 mb-2" />
				</div>
			</div>

			<div class="row">
				<div class="col-12 px-0 col-products">
					<swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
						<swiper-slide v-for="(product, index) in products" :key="index">
							<product-item :productId="product.productos_id" />
						</swiper-slide>

						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</div>

		</section>
	<!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import productItem from './productItem.vue'
export default {
	components: { productItem },
    name: 'productosRelacionados',
    props: {
        productId: {
            type: [Number, String]
        }
    },
    data: () => {
        return {
            productsOption: {
				loop: true,
				slidesPerView: 5,
				spaceBetween: 30,

				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},

				breakpoints: {
					1399: {
						slidesPerView: 5,
					},
					1199: {
						slidesPerView: 4,
					},
					991: {
						spaceBetween: 30,
						slidesPerView: 3,
					},
					575: {
						spaceBetween: 20,
						slidesPerView: 2,
					},
					1: {
						slidesPerView: 1,
					},
				},
			},
        }
    },
    computed: {
        ...mapGetters(['catalogProducts']), 
        products(){
            return _.take(_.shuffle(_.filter(this.catalogProducts, item => {return (item.productos_id != this.productId)})), 10)
        }
    }
}
</script>