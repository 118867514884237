<template>
	<!-- 
		Lock-Button 
		Versin 1.0

		Corrige el error de propagación cuando un botón se ha defindo desde $parent como disabled.
		@author Daniel Lepe
		@date 12/Oct/2021
	 -->
	<div class="button-holder" :class="alt">
		<button @click="triggerCallback($event)" :class="currentClass" :disabled="isProcessing">
			<slot name="default" v-if="!isProcessing">{{ currentLabel }}</slot>
			<slot name="busy-label" v-else>{{ busyText }}</slot>
		</button>
	</div>
</template>

<script>
export default {
	props: ['isProcessing', 'callback', 'label', 'busyLabel', 'alt', 'danger', 'disabled'],
	computed: {
		activeText() {
			return (!!this.label) ? this.label : "Aplicar"
		},
		busyText() {
			return (!!this.busyLabel) ? this.busyLabel : "Procesando..."
		},
		currentClass() {
			return ((!this.isProcessing) ? "main-btn bg-primary" : "main-btn bg-disabled") + ' ' + ((this.disabled) ? "disabled" : "")
		},
		currentLabel() {
			return (!this.isProcessing) ? this.activeText : this.busyText
		}
	},
	methods: {
		triggerCallback(event) {
			if ((!this.disabled || this.disabled == 'false') && !this.isProcessing) {
				if (typeof this.callback == 'function') {
					this.callback(event)
				} else if (!this.isProcessing) {
					this.$emit('click', event)
				}
			} else if (this.isProcessing) {
				event.stopPropagation()
				console.log('Click disabled because is processing');
			} else {
				event.stopPropagation()
				console.log('Click disabled by lock-button disabled attribute!')
			}
		}
	}
}
</script>

<style lang="less">
.button-holder {

	padding: 1em 0.75em;
	display: block;
	margin: 1em 0;
	font-size: calc(var(--ui-font-size) * .7);

	button {

		margin: 0;
		display: block;
		width: 100%;
		padding: 0.8em 1.5em 1.0em 1.5em;
		text-transform: uppercase;
		font-weight: bold;
		color: var(--white);
		font-size: calc(var(--ui-font-size) * .7);
		background-color: var(--primary) !important;
		border: none;
		// border-radius: var(--ui-border-radius);
		border-radius: 0.5em;
		box-shadow: var(--ui-box-shadow);

		&.disabled {
			opacity: 0.5;
		}

		&:hover:not(.disabled),
		&:visited:not(.disabled),
		&:focus:not(.disabled),
		&:active:not(.disabled) {
			background-color: var(--primary-dark) !important;
			cursor: pointer;
		}

		@media screen and (min-width: 375px) {
			font-size: calc(var(--ui-font-size) * .9);
		}

	}

	&.secondary {
		button {
			background-color: var(--primary);
			color: var(--white);

			&:hover:not(.disabled),
			&:active:not(.disabled) {
				background-color: var(--primary-dark);
			}
		}
	}

	&.danger {
		button {
			background-color: var(--danger);
			color: var(--white);

			&:hover:not(.disabled),
			&:active:not(.disabled) {
				background-color: var(--danger-dark);
			}
		}
	}

	&.success {
		button {
			background-color: var(--primary);
			color: var(--white);

			&:hover:not(.disabled),
			&:active:not(.disabled) {
				background-color: var(--primary-dark);
			}
		}
	}

	/* app.css | http://localhost:8080/public/css/app.css */

	a.bg-primary:hover,
	a.bg-primary:focus,
	button.bg-primary:hover,
	button.bg-primary:focus {
		/* background-color: #0062cc !important; */
		background-color: #659d31 !important;
	}
}
</style>